import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import moment from 'moment';
import configApp from '../config';
import { useLocation } from 'react-router-dom';
import React from 'react';

dayjs.extend(customParseFormat);

export const convertDateQuery = (date) => {
  return dayjs(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
};

export const convertQueryDateTime = (date, format) => {
  return moment(date, 'DD/MM/YYYY').format(format);
};

export const convertDateAndZone = (date) => {
  return dayjs(date, 'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ssZ');
};

export const formatDate = (date) => {
  if (!date) {
    return '';
  }

  return moment(date).format('DD/MM/YYYY');
};

export const formatDateTime = (date, format = 'DD/MM/YYYY HH:mm:ss') => {
  if (!date) {
    return '';
  }

  return moment(date).format(format);
};

export const exchangeMoney = (value, exchange, type) => {
  if (!value || !exchange) return '';
  try {
    if (type === 'buyRate') {
      const num = Number(value.replaceAll(',', '')) * Number(exchange.replaceAll(',', ''));
      return Number(num.toFixed(2));
    }
    const num = Number(value.replaceAll(',', '')) / Number(exchange.replaceAll(',', ''));
    return Number(num.toFixed(2));
  } catch (err) {
    return '';
  }
};
// matchpms để check khớp permissions truyền vào là tập con của permissionList theo user
export const checkPermission = (user, modules, permissions, matchPms, departmentCode) => {
  const flatAclList = user?.currentRole?.role?.flatAclList;
  if (!flatAclList) {
    return false;
  }
  const permissionModules = flatAclList.filter((item) => modules.indexOf(item.moduleCode) >= 0);
  if (!permissionModules || permissionModules.length === 0) {
    return false;
  }
  let isAccess = true;
  if (matchPms) {
    let flatPms = [];
    permissionModules.forEach((item) => {
      flatPms = flatPms.concat(item.permissionList);
    });
    // const setFlatPms = [...new Set(flatPms)];
    permissions.forEach((p) => {
      if (!flatPms.includes(p)) isAccess = false;
    });
  } else {
    // Load menu đối với user cùng ROLE nhưng khác phòng
    if (departmentCode != null) {
      if (checkUserInDepartment(user, departmentCode)) {
        isAccess = permissionModules.find((item) => {
          return item.permissionList.find((permissionCode) => permissions.indexOf(permissionCode) >= 0);
        });
      } else {
        return false;
      }
    } else {
      isAccess = permissionModules.find((item) => {
        return item.permissionList.find((permissionCode) => permissions.indexOf(permissionCode) >= 0);
      });
    }
  }
  return !!isAccess;

};

export const checkUserInDepartment = (user, departmentCode) => {
  const highLevel = ['HO', 'DIVISION', 'CHANNEL'];
  if (user) {
    if (highLevel.includes(user?.currentRole?.department?.departmentType)){
      return true;
    } else {
      // Check department type in ['TEAM', 'CENTER', 'REGION'];
      let departmentInfo = recursiveDepartment(user?.currentRole?.department);
      if (departmentInfo && departmentInfo.departmentCode == departmentCode) {
        return true;
      }
    }
  }
  return false;
};

const recursiveDepartment = (department) => {
  if (department.departmentType === 'CHANNEL') {
    return department;
  }
  return recursiveDepartment(department.parent);
};

export const isToday = (date) => {
  if (!date) return false;
  try {
    const today = new Date();
    const compareDate = new Date(date);
    return compareDate.getDay() === today.getDay() && compareDate.getMonth() === today.getMonth() && compareDate.getFullYear() === today.getFullYear();
  } catch (err) {
    return false;
  }
};

export const getOffset = (element) => {
  if (typeof element === 'string') {
    element = document.querySelector(element);
  }

  if (!element) {
    return {};
  }

  let top = 0;
  let left = 0;
  let width = element.clientWidth;
  let height = element.clientHeight;

  do {
    top += element.offsetTop || 0;
    left += element.offsetLeft || 0;
    element = element.offsetParent;
  } while (element);

  return {
    top,
    left,
    right: left + width,
    bottom: top + height,
    width,
    height,
  };
};

export const mediaUrl = (url) => {
  return `${configApp.MEDIA_URI}/${url}`;
};

export const getQueryString = () => new URLSearchParams(document.location.search.substring(1));

export const getRecentRevenueMicro = (type) => {
  switch (type) {
    case 'L2B':
      return 'Dưới 2 tỉ';
    case 'G2B':
      return 'Từ 2 tỉ trở lên';
    default:
      return '';
  }
};

export const getCareSolutions = (type) => {
  switch (type) {
    case 'BUSINESS_ACC':
      return 'Mở tài khoản DN';
    case 'UNSECURED':
      return 'Vốn tín chấp';
    case 'SECURED':
      return 'Vốn thế chấp';
    case 'ECOMMERCE':
      return 'Giải pháp TMĐT';
    case 'CREDIT_CARD':
      return 'Thẻ tín dụng DN';
    case 'SAVED':
      return 'Gửi tiết kiệm';
    case 'INTERPAYMENT':
      return 'Tài trợ thương mại/Thanh toán quốc tế';
    case 'POS':
      return 'Tap to phone & POS';
    case 'DEBIT_CARD':
      return 'Thẻ ghi nợ';
    case 'ES':
      return 'Ecompay Standard';
    case 'EP':
      return 'Ecompay Platinum';
    default:
      return '';
  }
};

export const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

export const scrollToError = (selector = '.is-invalid', autoFocus = true) => {
  const eleError = document.querySelector(selector);
  if (eleError) {
    setTimeout(() => {
      eleError.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'end' });
      // if (autoFocus && typeof eleError.focus === 'function') eleError.focus();
    }, 100);
  }
};

export const isSMEDivision = (user) => user?.currentRole?.department?.departmentPath?.startsWith('/9999/1/');

export const isBIZDivision = (user) => user?.currentRole?.department?.departmentPath?.startsWith('/9999/10000/');

export const getMobileOS = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (!userAgent) return 'other';

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  }

  return 'other';
};

export const handleEnter = (handler) => (e) => {
  if (e.key === 'Enter') {
    e.preventDefault();
    handler(e);
  }
}
