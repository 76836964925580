import { getAllBanks, getAllBeneficiary, getAllCompanyType, getAllNationality, getAllProvincesV2, getIndustryTypeAll } from '../services/commonMSAService';

const PROVINCE_OPTIONS = 'PROVINCE_OPTIONS';
const INDUSTRY_TYPE_OPTIONS = 'INDUSTRY_TYPE_OPTIONS';
const BENEFICIARY_OPTIONS = 'BENEFICIARY_OPTIONS';
const COMPANY_TYPE_OPTIONS = 'COMPANY_TYPE_OPTIONS';
const BANKS_OPTIONS = 'BANKS_OPTIONS';
const NATIONALITY_OPTIONS = 'NATIONALITY_OPTIONS';

const initStatue = {
  provinceOptions: [],
  industryTypeOptions: [],
  beneficiaryOptions: [],
  companyTypeOptions: [],
  banksOptions: [],
  nationalityOptions: [],
};

export const _getAllProvinces = () => (dispatch) => {
  return getAllProvincesV2().then((data) => {
    if (!data.errorCode) {
      data.sort((a, b) => a.description.localeCompare(b.description));
      dispatch({ type: PROVINCE_OPTIONS, payload: data });
    }
    return data;
  });
};

export const _getALLIndustryType = () => (dispatch) => {
  return getIndustryTypeAll().then((data) => {
    if (data) {
      data.sort((a, b) => a.description.localeCompare(b.description));
      dispatch({ type: INDUSTRY_TYPE_OPTIONS, payload: data });
    }
    return data;
  });
};

export const _getALLBeneficiary = () => (dispatch) => {
  return getAllBeneficiary().then((data) => {
    if (data) {
      data.sort((a, b) => a.description.localeCompare(b.description));
      dispatch({ type: BENEFICIARY_OPTIONS, payload: data });
    }
    return data;
  });
};

export const _getALLCompanyType = () => (dispatch) => {
  return getAllCompanyType().then((data) => {
    if (data) {
      data.sort((a, b) => a.description.localeCompare(b.description));
      dispatch({ type: COMPANY_TYPE_OPTIONS, payload: data });
    }
    return data;
  });
};

export const _getALLBanks = () => (dispatch) => {
  return getAllBanks().then((data) => {
    if (data) {
      data.sort((a, b) => a.key.localeCompare(b.key));
      dispatch({ type: BANKS_OPTIONS, payload: data });
    }
    return data;
  });
};

export const _getALLNationality = () => (dispatch) => {
  return getAllNationality().then((data) => {
    if (data) {
      // data.sort((a, b) => a.key.localeCompare(b.key));
      dispatch({ type: NATIONALITY_OPTIONS, payload: data });
    }
    return data;
  });
};

const commonReducer = (state = initStatue, { type, payload }) => {
  switch (type) {
    case PROVINCE_OPTIONS:
      return {
        ...state,
        provinceOptions: payload,
      };
    case INDUSTRY_TYPE_OPTIONS:
      return {
        ...state,
        industryTypeOptions: payload,
      };
    case BENEFICIARY_OPTIONS:
      return {
        ...state,
        beneficiaryOptions: payload,
      };

    case COMPANY_TYPE_OPTIONS:
      return {
        ...state,
        companyTypeOptions: payload,
      };
    case BANKS_OPTIONS:
      return {
        ...state,
        banksOptions: payload,
      };
    case NATIONALITY_OPTIONS:
      return {
        ...state,
        nationalityOptions: payload,
      };
    default:
      return state;
  }
};
export default commonReducer;
