import React from 'react';
import { toast } from 'react-toastify';
import messageMapper from './codeToMessageMapper';

export const error = (code) => {
  let msg = code || 'Đã có lỗi xảy ra. Vui lòng thử lại sau!';
  if (messageMapper[code]) {
    msg = messageMapper[code];
  }
  toast.error(<><i className="fa fa-exclamation-circle"/><p>{msg}</p></>, {className: 'itc__toast', bodyClassName: 'itc__toast__body'});
};
export const success = (code) => {
  let msg = code || 'Thành công!';
  if (messageMapper[code]) {
    msg = messageMapper[code];
  }
  toast.success(<><i className="fa fa-check-circle"/><p>{msg}</p></>, {className: 'itc__toast', bodyClassName: 'itc__toast__body'});
};
export const warning = (code) => {
  let msg = code;
  if (messageMapper[code]) {
    msg = messageMapper[code];
  }
  toast.warn(<><i className="fa fa-warning"/><p>{msg}</p></>, {className: 'itc__toast', bodyClassName: 'itc__toast__body'});
};
export const info = (code) => {
  let msg = code;
  if (messageMapper[code]) {
    msg = messageMapper[code];
  }
  toast.info(<><i className="fa fa-info-circle"/><p>{msg}</p></>, {className: 'itc__toast', bodyClassName: 'itc__toast__body'});
};

export const customSuccessMsg = ({code, message}) => {
  let msg = message;
  if (messageMapper[code]) {
    msg = messageMapper[code];
  }
  toast.success(<><i className="fa fa-check-circle"/><p>{msg}</p></>, {className: 'itc__toast', bodyClassName: 'itc__toast__body'});
};
export const customErrorMsg = ({ code, message }) => {
  let msg = message;
  if (messageMapper[code]) {
    msg = messageMapper[code];
  }
  toast.error(<><i className="fa fa-exclamation-circle"/><p>{msg}</p></>, {className: 'itc__toast', bodyClassName: 'itc__toast__body'});
};

export const parseMessage = (code) => {
  return messageMapper[code] || code;
};
export default {
  error,
  success,
  warning,
  info,
  customSuccessMsg,
  customErrorMsg,
  parseMessage,
};
