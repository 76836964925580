import dayjs from 'dayjs';
import SingleDealServices from '../../SingleDeal/services/index';
import { commonPayload, contractEditInfoPayload, settlementPayload } from '../configs/payload';

export const TAB_CODE = {
  CUSINFO: 'customerInfo',
  DOCUMENT_LIST: 'documentList',
  HISTORY_LIST: 'historyList',
  TRAN_MONEY_INFO: 'CHUYEN_TIEN',
  PAY_ALL_INFO: 'TAT_TOAN',
  EARLY_WITHDRAWAL_INFO: 'RUT_MOT_PHAN',
  EDIT_CONTRACT: 'CHINH_SUA',
};

export const TAB = {
  [TAB_CODE.CUSINFO]: {
    key: 'cusInfo',
    name: 'Thông tin khách hàng',
    type: 'cusInfo',
    order: 1,
  },
  [TAB_CODE.TRAN_MONEY_INFO]: {
    key: 'tab2Iinfo',
    name: 'Thông tin Chuyển tiền',
    type: 'transMoney',
    urlCreate: 'transfer-money',
    urlUpdate: 'transfer-money-update',
    payload: (formData, formikValue, store) => commonPayload(formData, formikValue, store),
    warningAmountMsg: 'Số tiền chuyển đi lớn hơn số dư AZ',
    order: 2,
  },
  [TAB_CODE.PAY_ALL_INFO]: {
    key: 'tab2Iinfo',
    name: 'Thông tin Tất toán',
    type: 'payAll',
    urlCreate: 'create-settlement-withdrawal',
    urlUpdate: 'update-settlement-withdrawal',
    payload: (formData, formikValue, store) => settlementPayload(formData, formikValue, store),
    warningAmountMsg: 'Số tiền chuyển đi/mở mới lớn hơn số dư AZ',
    order: 2,
  },
  [TAB_CODE.EARLY_WITHDRAWAL_INFO]: {
    key: 'tab2Iinfo',
    name: 'Thông tin Rút trước hạn 1 phần',
    type: 'earlyWithDrawl',
    urlCreate: 'create-settlement-withdrawal',
    urlUpdate: 'update-settlement-withdrawal',
    payload: (formData, formikValue, store) => settlementPayload(formData, formikValue, store),
    warningAmountMsg: 'Số tiền chuyển đi/mở mới lớn hơn số tiền rút gốc 1 phần',
    order: 2,
  },
  [TAB_CODE.EDIT_CONTRACT]: {
    key: 'tab2Iinfo',
    name: 'Thông tin hợp đồng',
    type: 'editContract',
    urlCreate: 'create-settlement-withdrawal',
    urlUpdate: 'update-settlement-withdrawal',
    payload: (formData, formikValue, store) => contractEditInfoPayload(formData, formikValue, store),
    warningAmountMsg: '',
    order: 2,
  },
  [TAB_CODE.DOCUMENT_LIST]: {
    key: 'documentList',
    name: 'Danh sách tài liệu',
    type: 'documentList',
    order: 3,
  },
  [TAB_CODE.HISTORY_LIST]: {
    key: 'historyList',
    name: 'Lịch sử hồ sơ',
    type: 'historyList',
    order: 4,
  },
};

export const TABLE_COLUMN_HANDLE_CONTRACT = [

  {
    accessor: '0',
    Header: 'STT',
    disableSortBy: true,
  },
  {
    accessor: '1',
    Header: 'Số hợp đồng',
    disableSortBy: true,
  },
  {
    accessor: '2',
    Header: 'Book của HĐTG',
    disableSortBy: true,
  },
  {
    accessor: '3',
    Header: 'Số CIF',
    disableSortBy: true,
  },
  {
    accessor: '4',
    Header: 'Tên khách hàng',
    disableSortBy: true,
  },
  {
    accessor: '5',
    Header: 'Tên qũy con',
    disableSortBy: true,
  },
  {
    accessor: '6',
    Header: 'Số dư AZ',
    disableSortBy: true,
  },
  {
    accessor: '7',
    Header: 'Loại tiền',
    disableSortBy: true,
  },
  {
    accessor: '7',
    Header: 'Lãi suất',
    disableSortBy: true,
  },
  {
    accessor: '8',
    Header: 'Ngày phát sinh',
    disableSortBy: true,
  },
  {
    accessor: '9',
    Header: 'Ngày đến hạn',
    disableSortBy: true,
  },
  {
    accessor: '9',
    Header: 'Mã DAO',
    disableSortBy: true,
  },
  {
    accessor: '9',
    Header: 'Chỉ thị (type)',
    disableSortBy: true,
  },
  {
    accessor: '10',
    Header: 'User nhập',
    disableSortBy: true,
  },
  {
    accessor: '11',
    Header: 'Ngày xử lí',
    disableSortBy: true,
  },
  {
    accessor: '11',
    Header: 'Người duyệt',
    disableSortBy: true,
  },
  {
    accessor: '11',
    Header: 'Ngày duyệt',
    disableSortBy: true,
  },
  {
    accessor: '12',
    Header: 'Trạng thái',
    disableSortBy: true,
  },
];

export const TABLECOLUMN = [
  {
    accessor: 'accountNumber',
    Header: 'Số hợp đồng',
    disableSortBy: true,
  },
  {
    accessor: 'branchCode',
    Header: 'Book của HĐTG',
    disableSortBy: true,
  },
  {
    accessor: 'customerNumber',
    Header: 'Số CIF',
    disableSortBy: true,
  },
  {
    accessor: 'customerVnName',
    Header: 'Tên khách hàng',
    disableSortBy: true,
  },
  {
    accessor: 'subFundName',
    Header: 'Qũy con',
    disableSortBy: true,
  },
  {
    accessor: 'amount',
    Header: 'Số dư HĐTG',
    Cell: ({ row }) => SingleDealServices.formatPrincipalAmount(row?.values?.amount, row?.values?.accountCurrency) || '---',
    disableSortBy: true,
  },
  {
    accessor: 'accountCurrency',
    Header: 'Loại tiền',
    disableSortBy: true,
  },
  {
    accessor: 'rolloverDate',
    Header: 'Ngày phát sinh',
    Cell: ({ row }) =>
      row?.values?.rolloverDate ? dayjs(row?.values?.rolloverDate).format('DD/MM/YYYY',) : '',
    disableSortBy: true,
  },
  {
    accessor: 'maturityDate',
    Header: 'Ngày đến hạn',
    Cell: ({ row }) =>
      row?.values?.maturityDate ? dayjs(row?.values?.maturityDate).format('DD/MM/YYYY',) : '',
    disableSortBy: true,
  },
  {
    accessor: 'interestRate',
    Header: 'Lãi suất (%)',
    disableSortBy: true,
  },
  {
    accessor: 'lockedId',
    Header: 'Số BT phong tỏa',
    disableSortBy: true,
  },
  {
    accessor: 'interestPaymentMethodLabel',
    Header: 'Phương thức trả lãi',
    disableSortBy: true,
    styles: { textAlign: 'left' },
  },
  {
    accessor: 'rolloverMethodLabel',
    Header: 'TT gốc và TT kéo dài',
    disableSortBy: true,
    styles: { textAlign: 'left' },
  },
  {
    accessor: 'dao',
    Header: 'Mã DAO',
    disableSortBy: true,
  },
];

export const TABLE_COLUMN_FINAL_SETTLEMENT = [

  {
    accessor: '0',
    Header: 'ID',
    disableSortBy: true,
  },
  {
    accessor: '1',
    Header: 'Loại chỉ thị',
    disableSortBy: true,
  },
  {
    accessor: 'Loại tất toán/ rút trước hạn 1 phần',
    Header: 'Book của HĐTG',
    disableSortBy: true,
  },
  {
    accessor: '3',
    Header: 'User tạo',
    disableSortBy: true,
  },
  {
    accessor: '4',
    Header: 'Ngày tạo',
    disableSortBy: true,
  },
  {
    accessor: '5',
    Header: 'Trạng thái',
    disableSortBy: true,
  },
  {
    accessor: '6',
    Header: 'User tạo',
    disableSortBy: true,
  },
];

export const VP_BANK = {
  value: '0',
  id: '0',
  label: 'Ngân hàng TMCP Việt Nam Thịnh Vượng',
  bankName: 'Ngân hàng TMCP Việt Nam Thịnh Vượng',
  bankShortName: 'VPBank',
};

export const PAYMENT_ACCOUNTS = {
  DEFAULT: null,
  ORIGINAL: 'ORIGINAL_ACCOUNT',
  INTEREST: 'INTEREST_ACCOUNT',
};

export const STATUS_MAPPING = {
  NEW: 'Chưa gửi',
  CANCEL: 'Hủy',
  PUSH_TBO: 'Đang gửi',
  WAIT_TBO: 'Chờ TBO xử lý',
  WAIT_QLDVKD: 'Chờ QL ĐVKD duyệt',
  QLDVKD_RETURN: 'QL ĐVKD trả lại',
  TBO_RETURN: 'TBO trả lại',
  FINISH: 'Hoàn thành',
  ERR_DOWNLOAD_FILE: 'Lỗi file upload',
};

export const STATUS = {
  NEW: 'NEW',
  CANCEL: 'CANCEL',
  PUSH_TBO: 'PUSH_TBO',
  WAIT_TBO: 'WAIT_TBO',
  WAIT_QLDVKD: 'WAIT_QLDVKD',
  QLDVKD_RETURN: 'QLDVKD_RETURN',
  TBO_RETURN: 'TBO_RETURN',
  FINISH: 'FINISH',
  ERR_DOWNLOAD_FILE: 'ERR_DOWNLOAD_FILE',
};

export const STATUS_CAN_TRANFER = [
  STATUS.NEW,
  STATUS.QLDVKD_RETURN,
  STATUS.TBO_RETURN,
  STATUS.ERR_DOWNLOAD_FILE,
];

export const STATUS_CODE_LIST = [403,404,422]

export const STATUS_CODE_ERROR_DES = {
  403: 'Không có quyền truy cập',
  404: 'Không tìm thấy thông tin',
  422: 'Dữ liệu không đúng',

}

export const ERR_SYS_STATUS_CODE = ['500', '504', '503', '500 INTERNAL_SERVER_ERROR'];