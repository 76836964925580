let authMSAKey = 'authMSAToken';

let authMSA = {
  isLogin: () => !!localStorage.getItem(authMSAKey),
  clear() {
    localStorage.clear();
    sessionStorage.clear();
  },
  settoken(token) {
    localStorage.setItem(authMSAKey, token);
  },
  gettoken() {
    return localStorage.getItem(authMSAKey);
  },
};
export default authMSA;
