import React, { Component } from 'react';
import './plugin/yup';
import { Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import Loading from './components/loading';
import history from './history';
import { pdfjs } from 'react-pdf';
import 'flatpickr/dist/themes/material_green.css';
import { ToastContainer } from 'react-toastify';
import './App.scss';
import 'react-rater/lib/react-rater.css';
import NotificationPopup from './components/common/Dialog/NotificationPopup/NotificationPopup';
import WarningUseExternalInternetPopup from './components/common/Dialog/WarningUseExternalInternetPopup/WarningUseExternalInternetPopup';
import PermissionContext from './helpers/context';
import pdfjsWorker from 'react-pdf/node_modules/pdfjs-dist/build/pdf.worker.entry';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const ChangePass = React.lazy(() => import('./views/Pages/ChangePass'));
const ForgetPassword = React.lazy(() => import('./views/Pages/ForgetPassword'));
const RecoverPassword = React.lazy(() => import('./views/Pages/RecoverPassword'));
const DocumentContent = React.lazy(() => import('./views/Pages/DocumentContent'));
const PreviewPdfPage = React.lazy(() => import('./views/Pages/PreviewPdfPage/PreviewPdfPage'));

const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));
const InfoApplicantEKYC = React.lazy(() => import('./Modules/EKYC/views/Applicant-ekyc/InfoApplicantEKYC/InfoApplicantEKYC'));

class App extends Component {
  static childContextTypes = {
    store: PropTypes.object,
  };

  getChildContext() {
    return {
      store: this.props.store,
    };
  }
  render() {
    return (
      <Provider store={this.props.store}>
        <ToastContainer toastClassName="toast-container" />
        <NotificationPopup />
        <WarningUseExternalInternetPopup />
        <Router history={history}>
          <React.Suspense fallback={<Loading />}>
            <Switch>
              <Route
                path="/info-ekyc/:id"
                name="info-ekyc"
                render={(props) => (
                  <PermissionContext.Provider value={{ modules: ['SME_EKYC_APP', 'SME_EKYC_AML', 'SME_EKYC_APP_ADMIN'] }}>
                    <InfoApplicantEKYC {...props} />
                  </PermissionContext.Provider>
                )}
              />
              {/*<Route path="/new/mobile/:id" name="news-app" render={props => <NewsDetail {...props} />} />*/}
              <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
              <Route exact path="/change-password" name="Change Password" render={(props) => <ChangePass {...props} />} />
              <Route exact path="/forget-password" name="Forget Password" render={(props) => <ForgetPassword {...props} />} />
              <Route path="/recover-password" name="Forget Password" render={(props) => <RecoverPassword {...props} />} />
              <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
              <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
              <Route path="/document-content" name="DocumentContent" component={DocumentContent} />
              <Route path="/preview-pdf" name="PreviewPdfPage" component={PreviewPdfPage} />
              <Route path="/" name="Home" render={(props) => <DefaultLayout {...props} />} />
            </Switch>
          </React.Suspense>
        </Router>
      </Provider>
    );
  }
}

export default App;
