import { TAB_CODE } from '../constants/common';
import { convertContractInfoData } from '../helpers/index';

export const commonPayload = (formData, formikValue, store) => {
  return {
    id: formData?.id,
    sign: store?.sign,
    transferType: formikValue?.transferType,
    departmentPath: formikValue?.departmentPath,
    amountOrigin: formikValue?.amountOrigin ,
    amountInterest: formikValue?.amountInterest,
    note: formikValue?.note,
    editor: formikValue?.editor,
    actionType: formikValue?.actionType,
    accountHold: formikValue?.accountHold,
    dao: formikValue?.dao,
    daoName: formikValue?.daoName,
    userBook: formikValue?.userBook,
  };
};

export const settlementPayload = (formData, formikValue, store) => {
  var result = convertContractInfoData(formikValue?.contractAccountsInfo, formikValue?.principalSameInterest, formikValue?.interestPaymentMethod);
  let commonBody = commonPayload(formData, formikValue, store);

  let settlementBody = {
    processType: formikValue?.processType,
    position: formikValue?.position,
    representative: formikValue?.representative,
    settlementDate: formikValue?.actionType == TAB_CODE.PAY_ALL_INFO ? formikValue?.settlementDate : undefined,
    newAmount: parseFloat(formikValue?.newAmount),
    principalSameInterest: parseInt(formikValue?.principalSameInterest),
    contractAccountsInfo: result,
    withdrawalDate: formikValue?.actionType == TAB_CODE.EARLY_WITHDRAWAL_INFO ? formikValue?.withdrawalDate : undefined,
    withdrawalAmount: formikValue?.actionType == TAB_CODE.EARLY_WITHDRAWAL_INFO ? formikValue?.withdrawalAmount : undefined,
    // isChangePayment: formikValue?.actionType == TAB_CODE.EARLY_WITHDRAWAL_INFO ? formikValue?.isChangePayment : undefined,
  };

  return { ...commonBody, ...settlementBody };
};

export const contractEditInfoPayload = (formData, formikValue, store) => {
  var result = convertContractInfoData(formikValue?.contractAccountsInfo, formikValue?.principalSameInterest, formikValue?.interestPaymentMethod);
  let commonBody = commonPayload(formData, formikValue, store);

  let settlementBody = {
    position: formikValue?.position,
    representative: formikValue?.representative,
    settlementDate: formikValue?.settlementDate,
    principalSameInterest: parseInt(formikValue?.principalSameInterest),
    contractAccountsInfo: result,
    isWithdrawInterest: formikValue?.isWithdrawInterest,
    changeOther: formikValue?.changeOther,
    backDateInterestRate: formikValue?.backDateInterestRate,
    interestRate: formikValue?.interestRate,
    maturityDate: formikValue?.maturityDate,
    preSignCustomer: formikValue?.preSignCustomer.toString(),
    depositTerm: formikValue?.depositTerm,
  };

  return { ...commonBody, ...settlementBody };
};