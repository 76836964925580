import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import makeRootReducer from './reducers/index';
import ApiClient from './helpers/ApiClient';
import clientMidleware from './helpers/clientMidleware';

export default initialState => {
    let middleWare = applyMiddleware(clientMidleware(new ApiClient()), thunk);
    const enhancers = [middleWare];

    if (process.env.NODE_ENV === 'development') {
        const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

        if (typeof devToolsExtension === 'function') {
            enhancers.push(devToolsExtension());
        }
    }

    const composedEnhancers = compose(...enhancers);
    const store = createStore(makeRootReducer(), initialState, composedEnhancers);
    store.asyncReducers = {};
    store.injectReducer = (key, reducer) => {
        store.asyncReducers[key] = reducer;
        store.replaceReducer(makeRootReducer(store.asyncReducers));
        return store;
    };

    if (module.hot) {
        module.hot.accept('./reducers/index', () => {
            const reducers = require('./reducers/index').default;
            store.replaceReducer(reducers(store.asyncReducers));
        });
    }

    return store;
};
