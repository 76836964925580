import config from '../config';

const TOKEN_KEY = 'authtoken';
const SME_TOKEN_KEY = 'authtokensme';

const gettoken = () => {
    let cookieToken;
    const cookies = decodeURIComponent(document.cookie).split(';');
    const cookieKey = `${SME_TOKEN_KEY}=`;
    cookies.forEach((cok) => {
        if (cok.trim().indexOf(cookieKey) === 0) {
            cookieToken = cok.trim().substring(cookieKey.length);
        }
    })
    return cookieToken || localStorage.getItem(TOKEN_KEY);
}

const auth = {
    isLogin: () => !!localStorage.getItem(TOKEN_KEY),
    clear() {
        localStorage.clear();
        sessionStorage.clear();
        document.cookie = `${SME_TOKEN_KEY}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;${config.APP_DOMAIN === 'vpbank.com.vn' ? ' domain=.vpbank.com.vn' : ''}`;
    },
    settoken(token) {
        localStorage.setItem(TOKEN_KEY, token);
        const cake = `${SME_TOKEN_KEY}=${token}; max-age=31536000; path=/;`;
        document.cookie = `${cake}${config.APP_DOMAIN === 'vpbank.com.vn' ? ' domain=.vpbank.com.vn' : ''}`
    },
    gettoken() {
        return gettoken();
    },
};
export default auth;
