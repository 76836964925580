import * as Yup from 'yup';
import { ERROR_MESSAGE, MAX_MONEY_INPUT } from '../constant';

Yup.setLocale({
  mixed: {
    required: ERROR_MESSAGE.required,
  },
  string: {
    max: 'Tối đa ${max} ký tự',
    min: 'Tối thiểu ${min} ký tự',
  },
  number: {
    max: 'Tối đa ${max}',
    min: 'Tối thiểu ${min}',
    integer: 'Không cho phép nhập số thập phân',
  },
  array: {
    max: 'Tối đa ${max}',
    min: 'Tối thiểu ${min}',
  },
});

Yup.addMethod(Yup.string, 'stringAndNumber', function (error = ERROR_MESSAGE.inValid) {
  return this.test('test', error, (value) => {
    if (value) {
      return /^[&0-9A-Za-zÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểếỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ\- ]+$/i.test(value);
    }
    return true;
  });
});
Yup.addMethod(Yup.string, 'fullName', function (error) {
  return this.test('test', error, (value) => {
    if (value) {
      return /^[0-9A-Za-zÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểếỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ ]+$/i.test(value);
    }
    return true;
  });
});
Yup.addMethod(Yup.string, 'stringName', function (error, rex) {
  let chars = '&A-Za-z\\-ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂẾưăạảấầẩẫậắằẳẵặẹẻẽềềểếỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹý';
  if (rex) {
    chars = `^[${chars}${rex}]+$`;
  }
  const re = new RegExp(chars, 'i');
  return this.test('test', error, (value) => {
    if (value) {
      return re.test(value);
    }
    return true;
  });
});
Yup.addMethod(Yup.array, 'checkItemLcNo', function () {
  return this.test('test', 'Không cho phép ký tự đặc biệt, tối đa 30 ký tự, yêu cầu ký tự đầu bắt đầu bằng chữ T và ký tự sau bắt đầu bằng số', (value) => {
    if (value && value.length) {
      let isError = true;
      value.forEach((item) => {
        if (!/^T\d[a-zA-Z\d]+$/.test(item)) {
          isError = false;
        }
      });
      return isError;
    }
    return true;
  });
});
Yup.addMethod(Yup.array, 'checkItemMaxLength', function (max, error) {
  return this.test('test', error, (value) => {
    if (value && value.length) {
      let isError = true;
      value.forEach((item) => {
        if (item.length > max) {
          isError = false;
        }
      });
      return isError;
    }
    return true;
  });
});
Yup.addMethod(Yup.string, 'customName', function (error, rex) {
  let chars = 'A-Za-zÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂẾưăạảấầẩẫậắằẳẵặẹẻẽềềểếỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹý';
  if (rex) {
    chars = `^[${chars}${rex}]+$`;
  }
  const re = new RegExp(chars, 'i');
  return this.test('test', error, (value) => {
    if (value) {
      return re.test(value);
    }
    return true;
  });
});

Yup.addMethod(Yup.string, 'email', function (error) {
  return this.test('test', error, (value) => {
    if (value) {
      return /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i.test(value);
    }
    return true;
  });
});

Yup.addMethod(Yup.boolean, 'requiredTrue', function (error) {
  return this.test('test', error, (value) => {
    if (value) return true;
    return 'Trường này là bắt buộc';
  });
});

Yup.addMethod(Yup.string, 'companyName', function (error) {
  return this.test('test', error, (value) => {
    if (value) {
      return /^[&0-9A-Za-zÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểếỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ\-., ]+$/i.test(value);
    }
    return true;
  });
});
Yup.addMethod(Yup.string, 'tenDoanhNghiep', function (error) {
  return this.test('test', 'Tên doanh nghiệp không được chứa ký tự đặc biệt', (value) => {
    if (value) {
      return /^[&0-9A-Za-zÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểếỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ\-., ]+$/i.test(value);
    }
    return true;
  });
});
Yup.addMethod(Yup.string, 'noteOD', function (error) {
  return this.test('test', 'Không được chứa ký tự đặc biệt', (value) => {
    if (value) {
      return /^[&0-9A-Za-zÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểếỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ\-.,/ ]+$/i.test(value);
    }
    return true;
  });
});

Yup.addMethod(Yup.string, 'stringOnly', function (error = ERROR_MESSAGE.inValid) {
  return this.test('test', error, (value) => {
    if (value) {
      return /^[A-Za-zÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểếỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ ]+$/i.test(value);
    }
    return true;
  });
});

Yup.addMethod(Yup.string, 'stringCustom', function (configs = {
  acceptNumber: false,
  acceptSymbols: '',
}, error = ERROR_MESSAGE.inValid) {
  return this.test('validString', error, (value) => {
    let pattern = ' A-Za-zÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểếỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ';
    if (configs.acceptNumber) {
      pattern += '0-9';
    }
    if (configs.acceptSymbols) {
      pattern += configs.acceptSymbols;
    }
    const regex = new RegExp(`^[${pattern}]+$`, 'i');
    return regex.test(value);
  });
});

Yup.addMethod(Yup.array, 'totalPercentage', function (field) {
  return this.test('totalPercentage', 'Tổng tỷ trọng không vượt quá 100%', (rows) => {
    const total = rows.reduce((acc, row) => acc + Number(row[field]), 0);
    return total <= 100;
  });
});

Yup.addMethod(Yup.number, 'money', function () {
  return this.min(0).lessThan(MAX_MONEY_INPUT, ERROR_MESSAGE.maxMoney());
});

Yup.addMethod(Yup.bool, 'requireChecked', function (message) {
  return this.oneOf([true], message || ERROR_MESSAGE.requiredCheck());
});
