import message from 'helpers/message';
import ApiClient from 'helpers/ApiClient';
import configApp from 'config';
import SingleDealHelper from '../helper/index';
import SingleDealConstants from '../constants';
import SingleDealTypes from '../constants/types';
import { checkPermission } from '../../../../../helpers/utils';
import { handleErrorMessage } from '../../AlreadyExistDeals/helpers/index';

const client = new ApiClient(`${configApp.API_URL_MODULE_SAVE_MONEY}/book-deal`);

const { PAYMENT_ACCOUNTS, STATUS, PAGE_TYPE } = SingleDealConstants;

const SingleDealServices = {
  async fetchDirectory(categories) {
    try {
      const params = categories.reduce((acc, category, index) => {
        return acc + `${index === 0 ? '?' : '&'}category=${category}`;
      }, '');
      const response = await client.get(`/category/${params}`);
      return response.data || [];
    } catch (e) {
      message.error(e.message);
      throw e;
    }
  },
  getCurrencyProps(currency) {
    switch (currency) {
      case 'VND':
        return {
          thousandSeparator: true,
          decimalScale: 0,
        };
      case 'USD':
      case 'EUR':
        return {
          thousandSeparator: '.',
          decimalSeparator: ',',
          decimalScale: 2,
        };
      default:
        return {};
    }
  },
  async fetchVPBankPaymentAccounts(cif, customerVnName) {
    try {
      if (!cif) return [];
      const response = await client.get(`/accounts/${cif}`);
      return response?.data?.map((account) => {
        return {
          value: account.accountNumber,
          label: `${account.accountNumber} - ${SingleDealServices.formatBalance(account.balanceInfo?.workingBalance, account.accountCurrency)}`,
          accountName: customerVnName || '',
          accountCurrency: account.accountCurrency,
        };
      });
    } catch (e) {
      message.error(e.message);
      throw e;
    }
  },
  formatBalance(number, currency, currencyDisplay = true) {
    let amount = '';
    switch (currency) {
      case 'USD':
      case 'EUR':
        amount = SingleDealHelper.numberToCurrency(number, ',', '.', 2);
        break;
      case 'VND':
        amount = SingleDealHelper.numberToCurrency(number, '.', ',', 0);
        break;
      default:
        amount = number;
    }
    return currencyDisplay ? `${amount} ${currency}` : amount;
  },
  async fetchBanks() {
    try {
      const response = await client.get('/banks');
      let banks = response?.data?.bankList || [];
      banks = banks.map((bank) => ({ value: bank.id, label: bank.bankName, bankShortName: bank.shortName }));
      banks.unshift(SingleDealConstants.VP_BANK);
      return banks;
    } catch (e) {
      if (e?.statusCode == '504' || e?.statusCode == '500') {
        message.error('Hệ thống đang tạm thời gián đoạn, xin vui lòng thử lại');
      } else {
        message.error(e.message);
      }
      throw e;
    }
  },
  async fetchBranches(bankId) {
    try {
      if (bankId === SingleDealConstants.VP_BANK.value) return { branches: [] };
      const response = await client.get(`/branches/${bankId}`);

      const branches = response?.data?.cities.reduce((acc, city) => {
        const branchOptions = city.branchCorporates.map((item) => ({ value: item.id, label: item.name }));
        return [...acc, ...branchOptions];
      }, []);

      return { branches };
    } catch (e) {
      message.error(e.message);
      throw e;
    }
  },
  async fetchBankDirectory(query) {
    try {
      const response = await client.get('/contract/beneficiary/cif', { params: query });
      return response;
    } catch (e) {
      message.error(e.message);
      throw e;
    }
  },

  async fetchCommitmentTime(query) {
    const response = await client.get('/rate', { params: query, loadingIndicator: true });
    return response?.data || {};
  },

  async fetchGeneralInfo(cif, user) {
    try {
      const response = await client.get(`/general-info/customer/${cif}`, {
        loadingIndicator: true,
        additionalHeader: {
          roleId: user?.currentRole?.role?.roleId,
          departmentId: user?.currentRole?.department?.departmentId,
        },
      });
      return response?.data?.result;
    } catch (e) {
      message.error(handleErrorMessage(e));
      throw e;
    }
  },

  async fetchGeneralDocument(cif) {
    try {
      const response = await client.get(`/general-info/document/${cif}`);
      const debtVoucherList = response?.data?.list || [];
      return debtVoucherList.map((value) => ({
        contractNumber: value.transaction_ref,
        amount: value.amount,
        currency: value.currency,
        openDate: value.openDate,
        overdue: value.no_over_deal_date,
      }));
    } catch (e) {
      if ((e.statusCode = '404')) {
        message.error('Không tìm thấy tài liệu');
      } else {
        message.error(handleErrorMessage(e));
      }
      throw e;
    }
  },

  async checkValidationDao(dao) {
    try {
      const response = await client.get(`/general-info/customer/validate-dao/${dao}`);
      if (!response.errorCode) {
        return response;
      }
    } catch (err) {
      message.error(err.message);
      return null;
    }
  },
  async fetchContractDetail(contractId) {
    try {
      const response = await client.get(`/contract/${contractId}`, { loadingIndicator: true });
      return response?.data || {};
    } catch (e) {
      message.error(e.message);
      throw e;
    }
  },
  initContractState(data, pageType, user) {
    const defaultState = SingleDealHelper.initState();

    const state = {
      ...defaultState,

      // General info tab state
      cifNo: data.cifNo,
      customerVnName: data.customerVnName,
      customerGbName: data.customerGbName,
      subFundName: data.subFundName || '',
      customerSign: data.customerSign,
      isCollateral: String(data.isCollateral),
      voucherDebt: String(data.voucherDebt),
      preSignCustomer: String(data.preSignCustomer),
      legalId: data.legalId,
      dateOfBirth: data.dateOfBirth,
      buIssuedPlace: data.buIssuedPlace,
      nationality: data.nationality,
      buIssuedDate: data.buIssuedDate,
      representative: data.representative,
      position: data.position,
      email: data.email,
      related: String(data.related),
      cifBook: data.cifBook,
      userBook: data.userBook,
      dao: data.dao,
      daoEmail: data.daoEmail,
      daoName: data.daoName || '',
      debtVoucherList: data.debtVoucherList,
      identificationType: data.identificationType,
      identificationNumber: data.identificationNumber,
      identificationDateExpire: data.identificationDateExpire,
      numberDocumentAgreement: data.numberDocumentAgreement,

      // Contract info tab state
      currency: data.currency,
      interestPaymentMethod: data.interestPaymentMethod,
      depositTerm: data.depositTerm,
      principalAmount: data.principalAmount,
      debitInfo: data.debitInfo || '',
      sourceCitad: data.sourceCitad || '',
      azContractCode: data.azContractCode || '',
      openDate: data.openDate,
      maturityDate: data.maturityDate,
      commitDay: data.commitDay,
      commitMonth: data.commitMonth,
      interestRate: data.interestRate,
      ceilingRate: data.ceilingRate,
      paymentAccount: data.paymentAccount,
      principalSameInterest: String(data.principalSameInterest),
      contractFormId: data.depositForm?.contractFormId,
      contractFormList: [],
      quantity: 1,
      note: data.note,
      delayMaturityDate: data.delayMaturityDate,
      segment: data.segment,

      //data not display
      contractTemplateNum: data?.contractTemplateNum || null,
      signDate: data?.signDate || null,
    };

    const [account1 = {}, account2 = {}] = data.accountInfoList;
    const convertAccount = (account) => {
      return {
        type: account.type,
        benNumber: account.benNumber,
        benName: account.benName,
        bank: { value: account.bankId, label: account.bankName, bankShortName: account.bankShortName },
        branch: { value: account.branchId, label: account.branchName },
        otherBranchName: account.branchId === 0 ? account.branchName : '',
      };
    };

    const vpBankBeneficiary = {};
    if (data.debitInfo === 'TKTT' && data.paymentAccount) {
      vpBankBeneficiary.benNumber = data.paymentAccount;
      vpBankBeneficiary.benName = data.customerVnName;
    }

    if (state.principalSameInterest === '1') {
      state.accountInfoList = {
        0: SingleDealTypes.Account(convertAccount(account1)),
        1: SingleDealTypes.Account({ type: PAYMENT_ACCOUNTS.ORIGINAL, ...vpBankBeneficiary }),
        2: SingleDealTypes.Account({ type: PAYMENT_ACCOUNTS.INTEREST, ...vpBankBeneficiary }),
      };
    } else {
      state.accountInfoList = {
        0: SingleDealTypes.Account({ type: PAYMENT_ACCOUNTS.DEFAULT, ...vpBankBeneficiary }),
        1: SingleDealTypes.Account(convertAccount(account1)),
        2: SingleDealTypes.Account(convertAccount(account2)),
      };
    }

    if (pageType === PAGE_TYPE.EDIT_CONTRACT) {
      const hasUpdatePermission = checkPermission(user, ['KHDNL_DEAL_MANAGEMENT'], ['UPDATE']);

      state.editable =
        hasUpdatePermission &&
        (data.status === STATUS.NEW || data.status === STATUS.QLDVKD_RETURN || data.status === STATUS.TBO_RETURN || data.status === STATUS.ERR_DOWNLOAD_FILE);
      state.contractId = data.contractId;
      state.status = data.status;

      state.listContractDetail = [data];
      state.interestPaymentMethodType = data.interestPaymentMethodType;
    }

    return state;
  },
  formatPrincipalAmount(number, currency) {
    switch (currency) {
      case 'VND':
        return SingleDealHelper.numberToCurrency(number, '.', ',', 0);
      case 'USD':
      case 'EUR':
        return SingleDealHelper.numberToCurrency(number, ',', '.', 2);
      default:
        return number;
    }
  },
  async createNewDeal({ data }) {
    try {
      const deal = SingleDealHelper.convertStateToPayload(data);
      const response = await client.post('/contract/create', { data: deal, loadingIndicator: true });
      message.success('Tạo thành công');
      return response?.data || [];
    } catch (e) {
      message.error(e.message);
      throw e;
    }
  },
  async updateDeal(data) {
    try {
      const { contractId, dealId, ...rest } = data;
      const payload = SingleDealHelper.convertStateToPayload(rest);

      let response = null;
      if (contractId) {
        response = await client.put(`/contract/update/${contractId}`, {
          data: payload,
          loadingIndicator: true,
        });
      } else {
        response = await client.put(`/contract/deal/${dealId}`, {
          data: payload,
          loadingIndicator: true,
        });
      }
      message.success('Lưu thành công');
      return response.data;
    } catch (e) {
      message.error(e.message);
      throw e;
    }
  },

  async fetchHistoryContract(contractId) {
    try {
      const response = await client.get(`/contract/history/${contractId}`);
      if (response.data) {
        return response;
      }
    } catch (e) {
      message.error(e.message);
      throw e;
    }
  },

  async cancelContract(contractId) {
    try {
      const response = await client.put(`/contract/cancel/${contractId}`, { loadingIndicator: true });
      message.success('Hủy thành công');
      return response?.data;
    } catch (e) {
      message.error(e.message);
      throw e;
    }
  },

  async transferForApproval(listContractId) {
    try {
      const response = await client.post('/deposit/contract/submit', { data: listContractId, loadingIndicator: true });
      message.success('Chuyển duyệt thành công');
      return response?.data?.listContractDetail;
    } catch (e) {
      message.error(e.message);
      throw e;
    }
  },

  async returnDeal(contractIdList, reason) {
    try {
      const response = await client.post('/deposit/contract/manager/return', { data: { contractIdList, returnReason: reason }, loadingIndicator: true });
      message.success('Trả lại thành công');
      return response?.data?.listContractDetail || [];
    } catch (e) {
      message.error(e.message);
      throw e;
    }
  },

  async approveDeal(contractIdList) {
    try {
      const response = await client.post('/deposit/contract/manager/approve', { data: { contractIdList } });
      message.success('Phê duyệt thành công');
      return response?.data?.listContractDetail || [];
    } catch (e) {
      message.error(e.message);
      throw e;
    }
  },
};

export default SingleDealServices;
