import styles from './WarningUseExternalInternetPopup.module.scss';
import React from 'react';
import { Modal, ModalBody, Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import Auth from '../../../../helpers/auth';
import { toggleWarningUseExternalInternetPopup } from '../../../../reducers/setting';
import iconDefault from '../../../../assets/img/icon/shape.svg';

const WarningUseExternalInternetPopup = () => {
  const { open, content, isLockAll } = useSelector((state) => {
    return state?.setting?.warningUseExternalInternetPopup || {};
  });
  const dispatch = useDispatch();
  const toggle = () => {
    dispatch(toggleWarningUseExternalInternetPopup({ open: !open, content: content }));
  };
  const close = () => {
    dispatch(toggleWarningUseExternalInternetPopup({ open: false }));
  };
  const logout = () => {
    Auth.clear();
    window.location.href = '/digitalgate/login';
  };
  return (
    <Modal backdrop="static" isOpen={open} toggle={toggle} centered={true} className={styles['popup-w']} zIndex={999999}>
      <ModalBody className={styles['popup-content']}>
        <div className={styles.close} onClick={isLockAll ? logout : close} aria-hidden="true">
          <i className="fa fa-times" />
        </div>
        <img className={styles['icon-header']} src={iconDefault} alt="shape" />
        <div className={styles.title}>Cảnh báo</div>

        {isLockAll ? (
          <div className={styles.content}>
            Bạn đang đăng nhập với kết nối không phải từ mạng nội bộ của VPBank. Tài khoản của bạn sẽ tạm thời bị khóa với mọi phân quyền. Vui lòng đăng nhập
            lại với mạng nội bộ của VPBank hoặc liên hệ bộ phận chăm sóc khách hàng!
          </div>
        ) : (
          <div className={styles.content}>
            <span>
              Bạn đang đăng nhập với kết nối không phải từ mạng nội bộ của VPBank. Tài khoản của bạn với phân quyền{' '}
              <span className={styles.role}>{content}</span> sẽ tạm thời bị khóa. Vui lòng đăng nhập lại với mạng nội bộ của VPBank, chuyển quyền hoặc liên hệ
              bộ phận chăm sóc khách hàng!
            </span>
          </div>
        )}

        {isLockAll ? (
          <Button className={styles['button-confirm']} type="button" color="success" onClick={logout}>
            <span className={styles.content}>Đăng xuất</span>
          </Button>
        ) : (
          <Button className={styles['button-confirm']} type="button" color="success" onClick={close}>
            <span className={styles.content}>Đã hiểu</span>
          </Button>
        )}
      </ModalBody>
    </Modal>
  );
};

export default WarningUseExternalInternetPopup;
