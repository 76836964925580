export const USER_CHANGE_INFO = 'USER_CHANGE_INFO';

export const user_change_info = (userInfo) => {
  return {
    type: USER_CHANGE_INFO,
    payload: userInfo,
  };
};
const initStatue = {};
const userReducer = (state = initStatue, { type, payload }) => {
  switch (type) {
    case USER_CHANGE_INFO: {
      return {
        ...state,
        ...payload,
      };
    }
    default:
      return state;
  }
};

export default userReducer;
