export const CHANGE_APP_TITLE = 'CHANGE_APP_TITLE';
export const CHANGE_SETTING = 'CHANGE_SETTING';
export const NOTIFICATION_POPUP = 'NOTIFICATION_POPUP';
export const WARNING_USE_EXTERNAL_INTERNET_POPUP = 'WARNING_USE_EXTERNAL_INTERNET_POPUP';

export const changeAppTitle = title => {
    return {
        type: CHANGE_APP_TITLE,
        payload: title,
    };
};
export const changeSetting = setting => {
    return {
        type: CHANGE_SETTING,
        payload: setting,
}
    ;
};
export const toggleNotificationPopup = (payload) => {
    return {type: NOTIFICATION_POPUP, payload}
};
export const toggleWarningUseExternalInternetPopup = (payload) => {
    return { type: WARNING_USE_EXTERNAL_INTERNET_POPUP, payload };
  };  
const initStatue = {  notificationPopup: {open: false}, warningUseExternalInternetPopup: { open: false }};
const settingReducer = (state = initStatue, {type, payload}) => {
    switch (type) {
        case CHANGE_SETTING:
            return {
                ...state,
                ...payload,
            };
        case CHANGE_APP_TITLE:
            return {
                ...state,
                appTitle: payload,
            };
        case NOTIFICATION_POPUP:
            return {...state, notificationPopup: payload};
        case WARNING_USE_EXTERNAL_INTERNET_POPUP:
            return { ...state, warningUseExternalInternetPopup: payload };
        default:
            return state;
    }
};

export default settingReducer;
