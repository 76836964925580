import styles from './NotificationPopup.module.scss'
import React from "react";
import {Modal, ModalBody} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {toggleNotificationPopup} from "../../../../reducers/setting";
import iconDefault from "../../../../assets/img/icon/shape.svg"


export default function NotificationPopup() {
    const {open, content, isSuccess} = useSelector(state => {
        return state?.setting?.notificationPopup ||{}
    });
    const dispatch = useDispatch();
    const toggle = () => {
        dispatch(toggleNotificationPopup({open: !open, content: content}))
    };
    const close = () => {
        dispatch(toggleNotificationPopup({open: false}))
    };
    return (
        <Modal isOpen={open} toggle={toggle} centered={true} className={styles['popup-w']} zIndex={999999}>
            <ModalBody className={styles['popup-content']}>
                <div className={styles.close} onClick={close} aria-hidden="true">
                    <i className="fa fa-times"/>
                </div>
                <img src={iconDefault} alt="shape"/>
                <div className={styles.title}>Thông báo</div>
                <div className={styles.content}>{content}
                </div>
            </ModalBody>
        </Modal>
    )
}
