import { formatDate } from 'helpers/utils';
import React from 'react';
import dayjs from 'dayjs';

const SingleDealConstants = {
  INDEX_TABS: {
    GENERAL: 'general',
    CONTRACT_INFO: 'contract',
    DOCUMENT_LIST: 'document',
    HISTORY: 'history',
  },

  ORDER_TAB: {
    general: 1,
    contract: 2,
    document: 3,
  },

  CURRENCY: [
    { value: 'VND', label: 'VND' },
    { value: 'USD', label: 'USD' },
    { value: 'EUR', label: 'EUR' },
  ],

  PHUONG_THUC_TRA_LAI: [
    { value: '', label: 'Tiền gửi CKH trả lãi trước' },
    { value: '', label: 'Tiền gửi CKH lĩnh lãi cuối kỳ' },
    { value: '', label: 'Tiền gửi CKH lĩnh lãi định kỳ hàng tháng' },
    { value: '', label: 'Tiền gửi CKH lĩnh lãi định kỳ hàng quý' },
    { value: '', label: 'Tiền gửi CKH lĩnh lãi định kỳ hàng năm' },
    { value: '', label: 'Tiền gửi An Khang cam kết 1 tháng' },
    { value: '', label: 'Tiền gửi An Khang cam kết 2 tháng' },
    { value: '', label: 'Tiền gửi An Khang cam kết 3 tháng' },
  ],

  THANH_TOAN_GOC_VA_THOA_THUAN_KEO_DAI_THOI_HAN_TIEN_GUI: [
    { value: '', label: 'Tái tục gốc ' },
    { value: '', label: 'Tái tục gốc lãi' },
    { value: '', label: 'Không tái tục' },
  ],

  THONG_TIN_TAI_KHOAN_GHI_NO: [
    { value: '', label: 'Tài khoản thanh toán' },
    { value: '', label: 'Treo Citad' },
    { value: '', label: 'Mã hợp đồng AZ đến hạn' },
  ],
  KHACH_HANG_KY: [{ value: 'HARD_SIGN', label: 'Ký cứng' }],
  COLUMNS_DANH_BA_THU_HUONG: [
    {
      accessor: (_, rowIndex) => rowIndex + 1,
      Header: 'STT',
    },
    {
      accessor: 'benNumber',
      Header: 'Số tài khoản',
    },
    {
      accessor: 'benName',
      Header: 'Tên người thụ hưởng',
    },
    {
      accessor: 'bankName',
      Header: 'Ngân hàng',
    },
    {
      accessor: 'branchName',
      Header: 'Chi nhánh hưởng',
    },
  ],

  VOUCHER_STATUS_COLUMNS: [
    {
      Header: 'STT',
      accessor: (_, rowIndex) => rowIndex + 1,
    },
    {
      Header: 'Số hợp đồng',
      accessor: 'contractNumber',
    },
    {
      Header: 'Số tiền',
      accessor: 'amount',
    },
    {
      Header: 'Loại tiền',
      accessor: 'currency',
    },
    {
      Header: 'Ngày phát sinh',
      // accessor: 'openDate',
      accessor: (value) => formatDate(value.openDate),
    },
    {
      Header: 'Số ngày quá hạn (ngày)',
      accessor: 'overdue',
      Cell: ({ value }) => {
        return <div style={{ width: 150 }}>{value}</div>;
      },
    },
  ],
  DANH_SACH_TIEN_GUI_AN_KHANG: ['TIEN_GUI_AN_KHANG_CAM_KET_MOT_THANG', 'TIEN_GUI_AN_KHANG_CAM_KET_HAI_THANG', 'TIEN_GUI_AN_KHANG_CAM_KET_BA_THANG'],
  VP_BANK: { value: 0, label: 'Ngân hàng TMCP Việt Nam Thịnh Vượng', bankShortName: 'VPBank' },

  HISTORY_COLUMNS: [
    {
      accessor: 'createdBy',
      Header: 'Người thực hiện',
    },
    {
      accessor: 'description',
      Header: 'Hành động',
    },
    {
      accessor: 'updatedDate',
      Header: 'Thời gian',
      Cell: ({ row }) => dayjs(row.values.updatedDate, 'YYYY/MM/DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss'),
    },
  ],
  PAYMENT_ACCOUNTS: {
    DEFAULT: null,
    ORIGINAL: 'ORIGINAL_ACCOUNT',
    INTEREST: 'INTEREST_ACCOUNT',
  },
  STATUS: {
    NEW: 'NEW',
    CANCEL: 'CANCEL',
    PUSH_TBO: 'PUSH_TBO',
    WAIT_TBO: 'WAIT_TBO',
    WAIT_QLDVKD: 'WAIT_QLDVKD',
    QLDVKD_RETURN: 'QLDVKD_RETURN',
    TBO_RETURN: 'TBO_RETURN',
    FINISH: 'FINISH',
    ERR_DOWNLOAD_FILE: 'ERR_DOWNLOAD_FILE',
  },
  STATUS_MAPPING: {
    NEW: 'Chưa gửi',
    CANCEL: 'Hủy',
    PUSH_TBO: 'Đang gửi',
    WAIT_TBO: 'Chờ TBO xử lý',
    WAIT_QLDVKD: 'Chờ QL ĐVKD duyệt',
    QLDVKD_RETURN: 'QL ĐVKD trả lại',
    TBO_RETURN: 'TBO trả lại',
    FINISH: 'Hoàn thành',
    ERR_DOWNLOAD_FILE: 'Lỗi file upload',
  },
  PAGE_TYPE: {
    CREATE_DEAL: 'CREATE_DEAL',
    EDIT: 'EDIT_DEAL_AND_CONTRACT',
    EDIT_DEAL: 'EDIT_DEAL',
    EDIT_CONTRACT: 'EDIT_CONTRACT',
  },
  PRODUCT: {
    TIEN_GUI: 'HD_TIEN_GUI',
  },
  FORM_NOT_FOUND: { code: 'FORM_NOT_FOUND', message: 'Mẫu biểu chưa tồn tại. Vui lòng liên hệ với TBO' },
};

export default SingleDealConstants;
