import {getAllProvinces} from "../services";

const PROVINCE_OPTIONS = 'PROVINCE_OPTIONS';
const SET_STATUS = 'SET_STATUS';
const INITIALIZE_DATA = 'INITIALIZE_DATA';
const SELECT_EDIT_TAB = 'SELECT_EDIT_TAB';
const SET_APPLICANT_BIL = 'SET_APPLICANT_BIL';
const SET_TYPE_SUBMIT = 'SET_TYPE_SUBMIT';

const initStatue = {
    provinceOptions: [],
    status: '',
    initializeData: {},
    selectedEditTab: '1',
    typeSubmit:'approve'
};
export const _getAllProvinces = () => (dispatch) => {
    return getAllProvinces().then((data) => {
        if (!data.errorCode) {
            data.sort((a, b) => a.description.localeCompare(b.description));
            dispatch({type: PROVINCE_OPTIONS, payload: data});
        }
        return data;
    })
};
export const changeSelectedEditTab = (tab) => {
    return {type: SELECT_EDIT_TAB, payload: tab}
};
export const setStatusBIL = (data) => {
    return {type: SET_STATUS, payload: data}
};
export const initializeData = (data) => {
    return {type: INITIALIZE_DATA, payload: data}
};
export const setTypeSubmit = (data) => {
    return {type: SET_TYPE_SUBMIT, payload: data}
};
export const setApplicantBil = (data) => {
    if (!data) {
        return {type: SET_APPLICANT_BIL, payload: data};
    }
    const applicant = {...data};
    applicant.headquarterWardId = applicant.headquarterWard?.wardId;
    applicant.headquarterDistrictId = applicant.headquarterDistrict?.districtId;
    applicant.headquarterProvinceId = applicant.headquarterProvince?.provinceId;
    applicant.permanentWardId = applicant.permanentWard?.wardId;
    applicant.permanentDistrictId = applicant.permanentDistrict?.districtId;
    applicant.permanentProvinceId = applicant.permanentProvince?.provinceId;
    applicant.legalPermanentWardId = applicant.legalPermanentWard?.wardId;
    applicant.legalPermanentDistrictId = applicant.legalPermanentDistrict?.districtId;
    applicant.legalPermanentProvinceId = applicant.legalPermanentProvince?.provinceId;
    applicant.legalCurrentWardId = applicant.legalCurrentWard?.wardId;
    applicant.legalCurrentDistrictId = applicant.legalCurrentDistrict?.districtId;
    applicant.legalCurrentProvinceId = applicant.legalCurrentProvince?.provinceId;
    applicant.sameHeadquarterAddress = applicant?.sameHeadquarterAddress === false ? '0' : '1';
    applicant.sameCurrentAddress = applicant?.sameCurrentAddress === false ? '0' : '1';
    applicant.legalSamePermanentAddress = applicant?.legalSamePermanentAddress === false ? '0' : '1';
    Object.keys(applicant).forEach(key => {
        if (applicant[key] === null) {
            delete applicant[key];
        }
    });
    return {type: SET_APPLICANT_BIL, payload: applicant}
};
const bilReducer = (state = initStatue, {type, payload}) => {
    switch (type) {
        case SELECT_EDIT_TAB:
            return {
                ...state,
                selectedEditTab: payload
            };
        case PROVINCE_OPTIONS:
            return {
                ...state,
                provinceOptions: payload,
            };
        case SET_STATUS:
            return {
                ...state,
                status: payload,
            };
        case INITIALIZE_DATA:
            return {...state, initializeData: payload};
        case SET_APPLICANT_BIL:
            return {...state, applicantBil: payload};
        case SET_TYPE_SUBMIT:
            return {...state, typeSubmit: payload};
        default:
            return state;
    }
};
export default bilReducer
