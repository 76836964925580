import configApp from 'config';
import ClientInterceptor from 'helpers/MSAService/clientInterceptor';

const clientToMSA = new ClientInterceptor(`${configApp.BIZ_GATEWAY_MSA_API_URL}/corporate-catalogs/v1/`, '');

export const getAllProvincesV2 = async () => {
  return clientToMSA.get('catalog/province/all' );
};

export const getAllDistrictByProvincesV2 = async (provinceId) => {
  return clientToMSA.get(`catalog/district/all?provinceId=${provinceId}`);
};

export const getAllWardByDistrictV2 = async (districtId) => {
  return clientToMSA.get(`catalog/ward/all?districtId=${districtId}`);
};

export const getIndustryTypeAll = async () => {
  try {
    const res = await clientToMSA.get('catalog/industry-type/all');
    return res && res.length ? res : [];
  } catch (error) {
    return [];
  }
};

export const getIndustryByTypeId = async (params) => {
  try {
    const res = await clientToMSA.get(`catalog/industry/all?industryTypeId=${params.industryTypeId}`);
    return res && res.length ? res : [];
  } catch (error) {
    return [];
  }
};

export const getIndustryLv3ByTypeCode = async (params) => {
  try {
    const res = await clientToMSA.get(`catalog/industry-level3/all?industryTypeCode=${params.industryTypeCode}`);
    return res && res.length ? res : [];
  } catch (error) {
    return [];
  }
};

export const getIndustryByLv3Code= async (params) => {
  try {
    const res = await clientToMSA.get(`catalog/industry/all?code=${params.code}`);
    return res && res.length ? res : [];
  } catch (error) {
    return [];
  }
};

export const getIndustryTypeByCode= async (params) => {
  try {
    const res = await clientToMSA.get(`catalog/industry-type/all?code=${params.code}`);
    return res && res.length ? res : [];
  } catch (error) {
    return [];
  }
};

export const getAllCompanyType = async () => {
  try {
    const res = await clientToMSA.get('catalog/company-type/all');
    return res && res.length ? res : [];
  } catch (error) {
    return [];
  }
};

export const getAllNationality = async () => {
  try {
    const res = await clientToMSA.get('catalog/nationality/all');
    return res && res.length ? res : [];
  } catch (error) {
    return [];
  }
};

export const getAllBeneficiary = async () => {
  try {
    const res = await clientToMSA.get('catalog/beneficiary/all');
    return res && res.length ? res : [];
  } catch (error) {
    return [];
  }
};

export const getAllBanks = async () => {
  try {
    const res = await clientToMSA.get('catalog/dis-branch/banks');
    return res && res.length ? res : [];
  } catch (error) {
    return [];
  }
};