export const initDataTemplate = {
  gnTotalFoundingNeedPayment: '',
  companyName: '',
  placeOfIssue: '',
  dateOfIssue: '',
  headquarterAddress: '',
  headquarterProvinceId: '',
  headquarterDistrictId: '',
  headquarterWardId: '',
  permanentAddress: '',
  permanentProvinceId: '',
  permanentDistrictId: '',
  permanentWardId: '',
  legalFullName: '',
  legalPosition: '',
  legalPositionDetail: '',
  legalIssuedPlace: '',
  legalIssuedDate: '',
  legalEmail: '',
  legalPermanentProvinceId: '',
  legalPermanentDistrictId: '',
  legalPermanentWardId: '',
  industryTypeId: '',
  industryId: '',
  companyStatus: '',
  legalPermanentAddress: '',
  totalApprovedCreditLimit: '',
  collateralTotalUnsecuredLimit: '',
  collateralSecuredCreditBalance: '',
  collateralUnsecuredCreditBalance: '',

  // noGroup2Overdue12m: '',
  // noBadDebtWithin3y: '',
  // noGroup2OverdueAmount: '',
  // okEquityGrowthRate: '',
  // equityGrowthRate: '',
  // equityLastYear: '',
  // equity2YearAgo: '',
  // okShortTermSolvency: '',
  // shortTermAssets: '',
  // shortTermDebt: '',

  // okTotalCreBalVsRelOrgs: '',
  // okDirectTotalCreNoTsdb: '',
  // okRelateTotalCreNoTsdb: '',
  // okRevenueTransferToVpbank: '',
  // okNotInVpbBusCovidField: '',
  // okNotChangeCompanyType: '',
  // okNotChangeIndustry: '',
  // okContributingMember50: '',
  // okLegalNationalInformation: '',
  // okNotInAmcList: '',
  // okCreditGrantingNeed: '',
  // okDebtPaymentAbility: '',

  // okProductConditions: '',
  purposeOfLoans: [],
  purposeOfLoanDetail: '',
  // : '',
  // okProvidedDocuments: '',
  // okAppropriateLoanNeed: '',
  llnEquityCapital: '',
  // appropriatedCapital: '',
  llnLoanFromOtherCredit: '',
  // llnTotalFoundingNeed: Yup.number().required(ERROR_MESSAGE.required).max(999999999999, ERROR_MESSAGE.max('999,999,999,999')),
  // loanFromVpbank: '',
  // gnTotalFoundingNeed: Yup.number().required(ERROR_MESSAGE.required).max(1500000000, ERROR_MESSAGE.max('1500,000,000')),
  // gnTotalFoundingNeed:
  gnTypes: [],
  gnTypeDetail: '',
  businessPlan: [],
  // businessPlan: Yup.string().required(1, ERROR_MESSAGE.required),
  businessPlanDetail: '',
  // lcTotalFoundingNeed: Yup.number().required(ERROR_MESSAGE.required).max(1500000000, ERROR_MESSAGE.max('1500,000,000')),
  // creditLimit: Yup.number().required(ERROR_MESSAGE.required).max(1500000000, ERROR_MESSAGE.max('1500,000,000')),
  debtRepaymentSources: [],
  debtRepaymentSourceDetail: '',
  okCommitMinTotalAmount: '',
  okCommitNoImpactEnvSociety: '',
  /**
   * kế hoạch kinh doanh
   * */
  revenueGoodAndService: '',
  costOfGoodSold: '',
  // tax: Yup.number().required(ERROR_MESSAGE.required).max(999999999999, ERROR_MESSAGE.max('999,999,999,999')),
  // accruedExpense: Yup.number().required(ERROR_MESSAGE.required).max(999999999999, ERROR_MESSAGE.max('999,999,999,999')),
  // financialExpense: Yup.number().required(ERROR_MESSAGE.required).max(999999999999, ERROR_MESSAGE.max('999,999,999,999')),

  /**
   * Đề xuất cấp tín dụng
   * */
  // availableCreditLimitInit: Yup.number().required(ERROR_MESSAGE.required).max(3000000000, ERROR_MESSAGE.max('3,000,000,000')),
  // totalCustomerProposalCreditLimit: Yup.number().required(ERROR_MESSAGE.required).max(1500000000, ERROR_MESSAGE.max('1,500,000,000')),
  // okPurposeOfForeignLoan: '',
  // hasForeignExchangeNeed: '',
  creditLimitTime: 12,
  // llnLimit: Yup.number().required(ERROR_MESSAGE.required).max(1500000000, ERROR_MESSAGE.max('1500,000,000')),
  llnMaxTerm: '',
  // llnDisbursementMethod: '',
  llnPrinRepayMethod: '',
  llnPeriodMonth: 1,
  // gnLimit: Yup.number().required(ERROR_MESSAGE.required),
  // gnType: '',
  gnMaxTerm: '',
  // gnDepositRatio: Yup.number().required(ERROR_MESSAGE.required).min(0, ERROR_MESSAGE.min('0')).max(100, ERROR_MESSAGE.max('100')),
  // lcLimit: Yup.number().required(ERROR_MESSAGE.required),
  lcTypes: [],
  // lcType: '',
  lcMaxTerm: '',
  // lcDepositRatio: Yup.number().required(ERROR_MESSAGE.required).min(0, ERROR_MESSAGE.min('0')).max(100, ERROR_MESSAGE.max('100')),
  // okCommitMinTotalAmount: '',
  // okCommitNoImpactEnvSociety: '',
  // okInitialApprovalSolution: '',
  // otherPropose: '',
  contractNumberInit: '',
  signDate: '',
  /**
   * Sale cam kết
   * */
  // saleConfirmed: Yup.boolean().required(ERROR_MESSAGE.required)
  //     .oneOf([true], ERROR_MESSAGE.required)
  lcTotalFoundingNeedSalePropose: '',
  gnTotalFoundingNeedSalePropose: '',
  llnLoanFromVpbankSalePropose: '',
  totalCustomerProposalCreditLimit: '',
  totalSaleProposalCreditLimit: '',
  llnPurposeOfLoans: '',
  llnPurposeOfLoanDetail: '',
  llnLoanPeriod: '',
  llnDebtRepaymentSources: '',
  llnDebtRepaymentSourceDetail: '',
};

export const CREDIT_METHOD_DEMAND = [
  { label: 'Hạn mức vay vốn', value: '0' },
  { label: 'Hạn mức bảo lãnh', value: '1' },
  { label: 'Hạn mức L/C', value: '2' },
];

export const GUARANTEE_TYPE = [
  { label: 'Bảo lãnh dự thầu', value: '0' },
  { label: 'Bảo lãnh thực hiện hợp đồng', value: '1' },
  { label: 'Bảo lãnh hoàn tạm ứng', value: '2' },
  { label: 'Bảo lãnh bảo hành', value: '3' },
  { label: 'Bảo lãnh thanh toán', value: '4' },
];

export const LC_TYPE = [
  { label: 'L/C trả ngay', value: '0' },
  { label: 'L/C trả chậm', value: '1' },
  { label: 'L/C trả chậm có điều kiện trả ngay', value: '2' },
  { label: 'Khác', value: '3' },
];

export const LOAN_PURPOSE = [
  {
    label: 'Thanh toán các chi phí hợp pháp phục vụ hoạt động sản xuất kinh doanh phù hợp với quy định của pháp luật',
    value: '0',
  },
  {
    label: 'Thanh toán các chi phí hợp pháp phục vụ hoạt động sản xuất kinh doanh của Tổ chức mà KH làm chủ sở hữu phù hợp với quy định của pháp luật',
    value: '1',
  },
  { label: 'Phát hành L/C bảo lãnh', value: '2' },
  { label: 'Mục đích vay vốn khác', value: '3' },
];

export const RETURN_SOURCE = [
  { label: 'Từ hoạt động sản xuất kinh doanh', value: '1' },
  { label: 'Nguồn thu hợp pháp khác', value: '0' },
];

export const BUSINESS_METHOD = [
  { label: 'Bổ sung vốn kinh doanh', value: '1' },
  { label: 'Mở rộng sản xuất kinh doanh', value: '2' },
  { label: 'Khác', value: '0' },
];

export const CREDIT_PURPOSE = [
  {
    label: 'Thanh toán các chi phí hợp pháp phục vụ hoạt động sản xuất kinh doanh phù hợp với quy định của pháp luật',
    value: '0',
  },
  {
    label: 'Thanh toán các chi phí hợp pháp phục vụ hoạt động sản xuất kinh doanh của Tổ chức mà KH làm chủ sở hữu phù hợp với quy định của pháp luật',
    value: '1',
  },
  { label: 'Mua sắm TSCĐ', value: '2' },
  { label: 'Đầu tư, xây dựng và sửa chữa nhà xưởng, văn phòng', value: '3' },
  { label: 'Phát hành L/C, bảo lãnh, thẻ tín dụng phục vụ hoạt động kinh doanh', value: '4' },
  { label: 'Trong trường hợp KH xác định được mục đích sử dụng vốn cụ thể', value: '5' },
];

const nf = new Intl.NumberFormat();

export const COLUMNS = [
  {
    Header: '#ID',
    accessor: 'dataSourceId',
  },
  {
    Header: 'Cif',
    accessor: 'cifCode',
  },
  {
    Header: 'Tên công ty',
    accessor: 'companyName',
    id: 'companyName',
  },
  {
    Header: 'Mã ĐKKD',
    accessor: 'businessNumber',
  },
  {
    Header: 'Loại hình doanh nghiệp',
    accessor: 'sectorName',
  },
  {
    Header: 'Đơn vị kinh doanh',
    accessor: 'departmentName',
  },
  {
    Header: 'Điện thoại ĐDPL',
    accessor: 'legalMobile',
  },
  {
    Header: 'Hạn mức phê duyệt',
    accessor: 'availableCreditLimitRenewal',
    Cell: ({ value }) => {
      return value ? nf.format(value) : value;
    },
  },
];

export const hasForeignExchangeNeedOpt = [
  { label: 'Có', value: '1' },
  { label: 'Không', value: '0' },
];
export const hasForeignExchangeNeedOpt2 = [
  { label: 'VND', value: '0' },
  { label: 'VND hoặc ngoại tệ tương đương', value: '1' },
];
export const currencyOptions = [
  { label: 'VNĐ/Ngoại tệ', value: '0' },
  { label: 'Ngoại tệ', value: '1' },
];

export const monthOpt = [
  { label: '1 Tháng', value: 1 },
  { label: '2 Tháng', value: 2 },
  { label: '3 Tháng', value: 3 },
  { label: '4 Tháng', value: 4 },
  { label: '5 Tháng', value: 5 },
  { label: '6 Tháng', value: 6 },
  { label: '7 Tháng', value: 7 },
  { label: '8 Tháng', value: 8 },
  { label: '9 Tháng', value: 9 },
  { label: '10 Tháng', value: 10 },
  { label: '11 Tháng', value: 11 },
  { label: '12 Tháng', value: 12 },
];

export const monthOpt24 = [
  { label: '1 Tháng', value: 1 },
  { label: '2 Tháng', value: 2 },
  { label: '3 Tháng', value: 3 },
  { label: '4 Tháng', value: 4 },
  { label: '5 Tháng', value: 5 },
  { label: '6 Tháng', value: 6 },
  { label: '7 Tháng', value: 7 },
  { label: '8 Tháng', value: 8 },
  { label: '9 Tháng', value: 9 },
  { label: '10 Tháng', value: 10 },
  { label: '11 Tháng', value: 11 },
  { label: '12 Tháng', value: 12 },
  { label: '13 Tháng', value: 13 },
  { label: '14 Tháng', value: 14 },
  { label: '15 Tháng', value: 15 },
  { label: '16 Tháng', value: 16 },
  { label: '17 Tháng', value: 17 },
  { label: '18 Tháng', value: 18 },
  { label: '19 Tháng', value: 19 },
  { label: '20 Tháng', value: 20 },
  { label: '21 Tháng', value: 21 },
  { label: '22 Tháng', value: 22 },
  { label: '23 Tháng', value: 23 },
  { label: '24 Tháng', value: 24 },
];

export const disbursementMethodOpt = [
  { label: 'Tiền mặt', value: 'CASH' },
  { label: 'Chuyển khoản vào TK thanh toán của KH', value: 'TRANSFER_CUSTOMER' },
  { label: 'Chuyển khoản cho bên thụ hưởng', value: 'TRANSFER_BENEFICIARY' },
];

export const llnPurposeOfLoan = [
  {
    label: 'Thanh toán các chi phí hợp pháp phục vụ hoạt động sản xuất kinh doanh phù hợp với quy định của pháp luật',
    value: 'LEGAL',
  },
  { label: 'Cho vay để thanh toán bảo lãnh thanh toán', value: 'GUARANTEE' },
  { label: 'Cho vay để thanh toán L/C', value: 'LC' },
  { label: 'Mục đích sử dụng vốn khác', value: 'OTHER' },
];

export const prinRepayMethodOpt = [
  { label: 'Cuối kỳ', value: 'CLOSING' },
  { label: 'Định kỳ', value: 'PERIOD' },
];

export const llnDebtRepaymentSourcesOpt = [
  { label: 'Từ hoạt động sản xuất kinh doanh', value: 'INNER' },
  { label: 'Nguồn thu hợp pháp khác', value: 'OTHER' },
];

export const bilGuaranteeTypeOpt = [
  { label: 'Bảo lãnh dự thầu', value: 'BID' },
  { label: 'Bảo lãnh thực hiện hợp đồng', value: 'CONTRACT_PERFORMANCE' },
  { label: 'Bảo lãnh hoàn tạm ứng', value: 'ADVANCE_REFUND' },
  { label: 'Bảo lãnh bảo hành', value: 'WARRANTY' },
  { label: 'Bảo lãnh thanh toán', value: 'PAYMENT' },
  { label: 'Khác', value: 'OTHER' },
];

export const bilLCTYpeOpt = [
  { label: 'L/C trả ngay', value: 'PAYMENT_NOW' },
  { label: 'L/C trả chậm', value: 'DEFERRED_PAYMENT' },
  { label: 'L/C trả chậm có điều khoản trả ngay', value: 'DEFERRED_PAYMENT_WITH_PAYMENT_TERM' },
  // {label: 'Khác', value: 'OTHER'},
];

export const purposeOfLoanTypeOpt = [
  { label: 'Phát hành L/C, bảo lãnh', value: 'GUARANTEE_AND_LC' },
  {
    label: 'Thanh toán các chi phí hợp pháp phục vụ hoạt động sản xuất kinh doanh phù hợp với quy định của pháp luật',
    value: 'LEGAL',
  },
  // {label: 'Thanh toán các chi phí hợp pháp phục vụ hoạt động sản xuất kinh doanh của Tổ chức mà KH làm chủ sở hữu phù hợp với quy định của pháp luật', value: 'OWNER_LEGAL'},
  { label: 'Khác', value: 'OTHER' },
];

export const debtRepaymentSourceOpt = [
  { label: 'Từ hoạt động sản xuất kinh doanh', value: 'INNER' },
  { label: 'Nguồn thu hợp pháp khác', value: 'OTHER' },
];

export const businessPlanOpt = [
  { label: 'Bổ sung vốn kinh doanh', value: 'ADD_CAPITAL' },
  { label: 'Mở rộng sản xuất kinh doanh', value: 'EXTEND_CAPITAL' },
  { label: 'Phương án khác', value: 'OTHER' },
];

export const OPTIONS_IS_CHANGE = [
  { label: 'Có', value: '1' },
  { label: 'Không', value: '0' },
];

export const FOUNDING_NEED = {
  TYPE_DETAIL: 'Bảo lãnh khác',
  NOTICE: '(* Ngoại trừ bảo lãnh vay vốn, bảo lãnh thanh toán thuế và bảo lãnh trong các giao dịch bán, cho thuê mua nhà ở hình thành trong tương lai)',
};

export const STATUS_BIL = [
  {
    label: 'llnStatus',
    value: [
      'llnTotalFoundingNeed',
      'llnEquityCapital',
      'llnAppropriatedCapital',
      'llnLoanFromOtherCredit',
      'llnLoanFromVpbank',
      'llnLoanFromVpbankSalePropose',
      'llnMaxTerm',
      'llnPurposeOfLoans',
      'llnPurposeOfLoanDetail',
      'llnLoanPeriod',
      'llnDisbursementMethod',
      'llnCash',
      'llnPrinRepayMethod',
      'llnPeriodMonth',
      'llnDebtRepaymentSources',
      'llnDebtRepaymentSourceDetail',
      'llnIntRepayMethod',
    ],
  },
  {
    label: 'gnStatus',
    value: ['gnTotalFoundingNeed', 'gnTypes', 'gnTypeDetail', 'gnTotalFoundingNeedSalePropose', 'gnType', 'gnTypeDetail', 'gnMaxTerm', 'gnDepositRatio'],
  },
  {
    label: 'lcStatus',
    value: ['lcTotalFoundingNeed', 'lcTypes', 'lcTypeDetail', 'lcTotalFoundingNeedSalePropose', 'lcType', 'lcTypeDetail', 'lcMaxTerm', 'lcDepositRatio'],
  },
];
