import message from '../helpers/message';
import ApiClient, { API_HOST, download } from '../helpers/ApiClient';
import auth from 'helpers/auth';
import CONFIG from 'config';
import { v4 as uuidv4 } from 'uuid';
import { modifyBody } from '../Modules/BIL/util/helpers';
import { RECEIPTION_STATUS_OPTIONS, STATUS_OPTIONS } from 'Modules/SPOS/constants/index';

const client = new ApiClient();
const bookDealClient = new ApiClient(`${CONFIG.API_URL_MODULE_SAVE_MONEY}/book-deal`);

export const fetchDirectory = async (categories) => {
  try {
    const params = categories.reduce((acc, category, index) => {
      return acc + `${index === 0 ? '?' : '&'}category=${category}`;
    }, '');
    const response = await bookDealClient.get(`/category/${params}`);
    return response.data || [];
  } catch (e) {
    message.error(e.message);
    throw e;
  }
};

export const getEKYCApplicant = async (query) => {
  try {
    const response = await client.get('/ekyc/applicant', { params: query });
    if (!response.errorCode) {
      return response;
    }
  } catch (err) {
    message.error(err.message);
  }

  return null;
};
export const getEKYCApplicantById = async (id) => {
  try {
    const response = await client.get(`/ekyc/applicant/${id}`);
    if (!response.errorCode) {
      return response;
    }
  } catch (err) {
    message.error(err.message);
  }

  return null;
};
export const approveEKYCApplicant = async (id) => {
  try {
    const response = await client.post(`/ekyc/applicant/${id}/approve`);
    if (!response.errorCode) {
      return response;
    }
  } catch (err) {
    message.error(err.message);
  }

  return null;
};

export const getUserName = async (madao) => {
  try {
    const response = await client.post(`/ekyc/applicant/${madao}/approve`);
    if (!response.errorCode) {
      return response;
    }
  } catch (err) {
    message.error(err.message);
  }

  return null;
};

export const rejectEKYCApplicant = async (id, reason) => {
  try {
    const response = await client.post(`/ekyc/applicant/${id}/reject`, { data: reason });
    if (!response.errorCode) {
      return response;
    }
  } catch (err) {
    message.error(err.message);
  }

  return null;
};

export const DVKHEKYCApplicant = async (id, reason) => {
  try {
    const response = await client.post(`/ekyc/applicant/${id}/update-reason`, { data: reason });
    client.post(`/ekyc/applicant/${id}/send-email-dvkd`);
    if (!response.errorCode) {
      message.success('Gửi xác thực thành công');
      return response;
    }
  } catch (err) {
    message.error(err.message);
  }
  return null;
};

export const DVKDXacThuc = async (id, OBJNote, dvkdVerify) => {
  try {
    const response1 = await client.post(`/ekyc/applicant/${id}/update-dvkd-verify`, { data: dvkdVerify });
    const response = await client.post(`/ekyc/applicant/${id}/update-note`, { data: OBJNote });
    if (!response.errorCode || !response1.errorCode) {
      message.success('Xác thực thành công');
      return response;
    }
  } catch (err) {
    message.error(err.message);
  }
  return null;
};

export const getAllProvinces = async () => {
  return client.get('/province/all');
};
export const getAllDistrictByProvinces = async (provinceId) => {
  return client.get(`/district/all?provinceId=${provinceId}`);
};
export const getAllWardByDistrict = async (districtId) => {
  return client.get(`/ward/all?districtId=${districtId}`);
};
export const getODApplicant = async (query) => {
  try {
    const response = await client.get('/od/applicant', { params: query });
    if (!response.errorCode) {
      return response;
    }
  } catch (err) {
    message.error(err.message);
  }

  return null;
};

export const getODApplicantV2 = async (query) => {
  try {
    const response = await client.get('/od/applicant/filter', { params: query });
    if (!response.errorCode) {
      return response;
    }
  } catch (err) {
    message.error(err.message);
  }

  return null;
};

export const exportFileOd = async (query) => {
  try {
    download('/od/applicant/export', 'danh sach ho so OD.xls', query);
  } catch (err) {
    message.error(err.message);
  }
  return null;
};
export const exportFileGNHM = async (query) => {
  try {
    download('/dis/applicant/export', 'danh sach ho so.xls', query, null, 'GET', false, false, {}, true);
  } catch (err) {
    message.error(err.message);
  }
  return null;
};
export const exportFileEKYC = async (query) => {
  try {
    download('/ekyc/applicant/export', 'danh sach ho so EKYC.xls', query);
  } catch (err) {
    message.error(err.message);
  }
  return null;
};
export const getODApplicantById = async (id) => {
  try {
    const response = await client.get(`/od/applicant/${id}`);
    if (!response.errorCode) {
      return response;
    }
  } catch (err) {
    message.error(err.message);
  }

  return null;
};

export const saveODApplicantById = async (id, data) => {
  try {
    const response = await client.post(`/od/applicant/${id}`, { data: data });
    if (!response.errorCode) {
      message.success('Lưu thành công');
      return response;
    }
  } catch (err) {
    message.error(err.message);
  }

  return null;
};
export const getAllPosition = async () => {
  return client.get('/category/position/all');
};
export const updateStatusOD = async (values, status, data) => {
  if (values) {
    delete values.relatedOrganizationGroupList;
    values.relatedOrganizationList.forEach((list) => {
      if (!list.businessNumber) {
        list.businessNumber = null;
      }
      if (!list.documentNumber) {
        list.documentNumber = null;
      }
    });
  }
  try {
    const postData = { data: data };
    if (status === 'submit' || status === 'send-for-appraisal') {
      postData.data = values;
    }
    if (status === 'sale-reject') {
      postData.data = values;
      postData.data.reason = data?.reason;
    }

    const response = await client.post(`/od/applicant/${values.applicantId}/${status}`, postData);
    if (response.errorCode) return message.error(response.message);
    return response;
  } catch (err) {
    message.error(err.message);
  }

  return null;
};

export const downloadOD = (code, fileName) => {
  try {
    fetch(`${API_HOST}/od/document/download/${code}`, {
      headers: {
        Authorization: `Bearer ${auth.gettoken()}`,
      },
    })
      .then((resp) => {
        if (resp.status === 200) {
          return resp.blob();
        }
        return null;
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `${fileName}.pdf`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => message.error('Lỗi tải xuống'));
  } catch (err) {
    message.error(err.message);
  }
};
export const uploadOdFile = async (data) => {
  try {
    await client.post('/od/datasource/upload', { data: data });
    message.success('Lưu thành công');
  } catch (err) {
    message.error(err.message);
  }

  return null;
};
export const getAllDataSource = async (query) => {
  try {
    const response = await client.get('/od/datasource', { params: query });
    if (!response.errorCode) {
      return response;
    }
  } catch (err) {
    message.error(err.message);
  }

  return null;
};
export const uploadGuideline = async (fileName, file) => {
  try {
    let data = new FormData();
    data.append('file', file);
    data.append('displayName', fileName);
    const response = await client.post('/od/guideline', { data: data });
    if (!response.errorCode) {
      message.success('Thêm mới văn bản thành công');
      return response;
    }
  } catch (err) {
    message.error(err.message);
  }

  return null;
};
export const getAllGuideline = async () => {
  try {
    const response = client.get('/od/guideline/all');
    if (!response.errorCode) {
      return response;
    }
  } catch (err) {
    message.error(err.message);
  }
};
export const deleteGuideline = async (id) => {
  try {
    await client.del(`/od/guideline/${id}`);
    message.success('Xóa thành công');
  } catch (err) {
    message.error(err.message);
  }

  return null;
};
export const getHistoryOd = async (id) => {
  try {
    const response = client.get(`/od/applicant/${id}/history`);
    if (!response.errorCode) {
      return response;
    }
  } catch (err) {
    message.error(err.message);
  }
};
export const uploadOdFileAML = async (type, file) => {
  try {
    const data = new FormData();
    data.append('multipartFile', file);
    data.append('amlType', type);
    await client.post('/ekyc/aml/import', { data: data });
    message.success('Lưu thành công');
  } catch (err) {
    message.error(err.message);
  }

  return null;
};
export const getSystemLog = async (value) => {
  try {
    const response = client.post('/administration/show-log', { data: { charNum: value } });
    if (!response.errorCode) {
      return response;
    }
  } catch (err) {
    message.error(err.message);
  }
};

export const getGNHMApplicant = async (query) => {
  try {
    const response = await client.get('/dis/applicant', { params: query });
    if (!response.errorCode) {
      return response;
    }
  } catch (err) {
    message.error(err.message);
  }

  return null;
};
export const getGNHMApplicantV2 = async (query) => {
  try {
    const response = await client.get('/dis/applicant/filter', { params: query });
    if (!response.errorCode) {
      return response;
    }
  } catch (err) {
    message.error(err.message);
  }

  return null;
};
export const getAutoCarApplicant = async (query) => {
  try {
    const response = await client.get('/auto-applicant', { params: query });
    if (!response.errorCode) {
      return response;
    }
  } catch (err) {
    message.error(err.message);
  }

  return null;
};

export const getAutoCarApplicantV2 = async (query) => {
  try {
    const response = await client.get('/auto-applicant/filter', { params: query });
    if (!response.errorCode) {
      return response;
    }
  } catch (err) {
    message.error(err.message);
  }

  return null;
};

export const getGNHMApplicantById = async (id) => {
  try {
    const response = await client.get(`/dis/applicant/${id}`);
    if (!response.errorCode) {
      // response.status = 'COMPLETE';
      return response;
    }
  } catch (err) {
    message.error(err.message);
  }

  return null;
};

export const getGNHMT24Accounts = async (cif) => {
  try {
    const response = await client.get(`/dis/applicant/${cif}/get-t24-accounts`);
    if (response && !response.errorCode) {
      return response;
    }
  } catch (err) {
    message.error(err.message);
  }
  return null;
};

export const uploadFile = (query, file, url, onProgress) => {
  /*eslint-disable no-undef*/
  console.log('1');
  const r = new Resumable({
    target: url,
    maxChunkRetries: 5,
    totalSizeParameterName: 'fileSize',
    identifierParameterName: 'code',
    chunkNumberParameterName: 'segment',
    fileNameParameterName: 'fileName',
    chunkSizeParameterName: 'segmentSize',
    forceChunkSize: true,
    generateUniqueIdentifier: () => uuidv4(),
    query: { ...query },
    headers: { Authorization: `Bearer ${auth.gettoken()}` },
    testMethod: 'POST',
  });
  r.addFile(file);
  console.log('2');
  r.upload();
  console.log('3');
  return new Promise((resolve, reject) => {
    r.on('fileSuccess', (fileUp, message) => {
      console.log('4');
      if (message) {
        console.log('5');
        resolve(JSON.parse(message));
      }
    });
    r.on('error', (message) => {
      console.log('6');
      reject(message);
    });
    r.on('progress', () => {
      console.log('7');
      onProgress(r.progress());
    });
  });
  // eslint-disable-next-line no-undef
};

export const uploadFileVMO = (query, file, url, onProgress) => {
  /*eslint-disable no-undef*/
  console.log('1');
  const r = new Resumable({
    target: url,
    chunkSize: 15 * 1024 * 1024,
    maxChunkRetries: 5,
    totalSizeParameterName: 'fileSize',
    identifierParameterName: 'code',
    chunkNumberParameterName: 'segment',
    fileNameParameterName: 'fileName',
    chunkSizeParameterName: 'segmentSize',
    forceChunkSize: true,
    generateUniqueIdentifier: () => uuidv4(),
    query: { ...query },
    headers: { Authorization: `Bearer ${auth.gettoken()}` },
    testMethod: 'POST',
    fileParameterName: 'multipartFile',
  });
  r.addFile(file);
  console.log('2');
  r.upload();
  console.log('3');
  return new Promise((resolve, reject) => {
    r.on('fileSuccess', (fileUp, message) => {
      console.log('4');
      if (message) {
        console.log('5');
        resolve(JSON.parse(message));
      }
    });
    r.on('error', (message) => {
      console.log('6');
      reject(message);
    });
    r.on('progress', () => {
      console.log('7');
      onProgress(r.progress());
    });
  });
  // eslint-disable-next-line no-undef
};

export const uploadFileDis = (id, query, file, onProgress) => {
  return uploadFile(query, file, `${CONFIG.API}/api/dis/document/beneficiary/${id}/upload`, onProgress);
};
export const uploadFileApplicantDis = (id, query, file, onProgress) => {
  return uploadFile(query, file, `${CONFIG.API}/api/dis/document/${id}/upload-document-applicant`, onProgress);
};
export const deleteDocumentDis = async (documentId, disApplicantId) => {
  try {
    const response = await client.del(`/dis/document/beneficiary/${documentId}`, {
      data: {
        disApplicantId,
        documentId,
      },
    });
    if (!response?.errorCode) {
      return true;
    }
  } catch (err) {
    message.error(err.message);
    return null;
  }

  return null;
};
export const deleteDocumentDisApplicant = async (documentId, disApplicantId) => {
  try {
    const response = await client.del('/dis/document/document-applicant/', { data: { disApplicantId, documentId } });
    if (!response?.errorCode) {
      return true;
    }
  } catch (err) {
    message.error(err.message);
    return null;
  }

  return null;
};
export const deleteBeneficiaryId = async (beneficiaryId, disApplicantId) => {
  try {
    return await client.del('/dis/applicant/delete-beneficiary-id', {
      data: {
        disApplicantId,
        beneficiaryId,
      },
    });
  } catch (err) {
    message.error(err.message);
    return null;
  }
};
export const deleteBeneficiaryByGroupCode = async (groupCode, disApplicantId) => {
  try {
    return await client.del('/dis/applicant/delete-beneficiary-by-gr-code', {
      data: {
        disApplicantId,
        groupCode,
      },
    });
  } catch (err) {
    message.error(err.message);
    return null;
  }
};
export const deleteBeneficiaryByPurpose = async (purpose, disApplicantId) => {
  try {
    const response = client.del('/dis/applicant/delete-beneficiary-by-purpose', {
      data: { disApplicantId, purpose },
    });
    if (!response?.errorCode) {
      return response;
    }
  } catch (err) {
    message.error(err.message);
    return null;
  }
};
export const getGNHMBank = async (id) => {
  try {
    const response = await client.get('/dis-branch/banks');
    if (!response.errorCode) {
      return response;
    }
  } catch (err) {
    message.error(err.message);
  }
  return null;
};
export const getGNHMBranches = async (query) => {
  try {
    const response = await client.get('/dis-branch/branches', { params: query });
    if (!response.errorCode) {
      return response;
    }
  } catch (err) {
    message.error(err.message);
  }
  return null;
};
export const getGNHMCities = async (bankName) => {
  try {
    const response = await client.get('/dis-branch/cities', { params: { bankName } });
    if (!response.errorCode) {
      return response;
    }
  } catch (err) {
    message.error(err.message);
  }
  return null;
};
export const updateBeneficiary = async (id, data) => {
  try {
    const response = await client.post(`/dis/applicant/${id}/update-beneficiary`, { data: data });
    if (!response?.errorCode) {
      return response;
    }
  } catch (err) {
    message.error(err.message);
  }
};
export const updateMultiBeneficiary = async (id, data) => {
  try {
    const response = await client.post(`/dis/applicant/${id}/update-multi-beneficiary`, { data: data, loadingIndicator: true });
    if (!response?.errorCode) {
      return response;
    }
  } catch (err) {
    message.error(err.message);
  }
};
export const updateGnhmApplicant = async (id, data) => {
  try {
    data.disProposal.approveType = 'ONLINE';
    const response = await client.post(`/dis/applicant/${id}/update`, { data: data, loadingIndicator: true });
    if (!response?.errorCode) {
      return response;
    }
  } catch (err) {
    message.error(err.message);
  }
};
export const completeGnhmApplicant = async (id, data, errorFXFO) => {
  try {
    const response = await client.post(`/dis/applicant/${id}/complete`, { data: data, loadingIndicator: true });
    if (!response?.errorCode) {
      return response;
    }
  } catch (err) {
    if (err.message === 'invalid_ft_fxfo') {
      errorFXFO();
    } else if (err.errorCode === 'INTEREST_INVALID') {
      return err;
    } else {
      message.error(err.message);
    }
  }
};
export const rejectGnhmApplicant = async (id, data) => {
  try {
    const response = await client.post(`/dis/applicant/${id}/sale-reject`, { data, loadingIndicator: true });
    if (!response?.errorCode) {
      return response;
    }
  } catch (err) {
    message.error(err.message);
  }
};
export const getGNHMHistory = async (id) => {
  try {
    const response = client.get(`/dis/applicant/${id}/history`);
    if (!response.errorCode) {
      return response;
    }
  } catch (err) {
    message.error(err.message);
  }
};
export const changeCutOffTime = async (id, data) => {
  try {
    const response = await client.post(`/dis/applicant/${id}/change-cut-off-time`, { data: data });
    if (!response?.errorCode) {
      return response;
    }
  } catch (err) {
    message.error(err.message);
  }
};
export const setOfflineFlow = async (id) => {
  try {
    const response = await client.post(`/dis/applicant/${id}/offline-flow`);
    if (!response?.errorCode) {
      return response;
    }
  } catch (err) {
    message.error(err.message);
  }
};
export const updateDataSource = async (id, data) => {
  try {
    const response = await client.put(`/od/datasource/${id}/update-sale-info`, { data: data });
    if (response && !response.errorCode) {
      return response;
    }
  } catch (err) {
    message.error(err.message);
  }
};
export const updateOdApplicantSource = async (id) => {
  try {
    const response = await client.put(`/od/applicant/${id}/update-sale-info`);
    if (response && !response.errorCode) {
      return response;
    }
  } catch (err) {
    message.error(err.message);
  }
};
export const getListBranch = async () => {
  try {
    const response = client.get('/branch/all');
    if (!response.errorCode) {
      return response;
    }
  } catch (err) {
    message.error(err.message);
  }
};
export const getAutoCarApplicantById = async (id) => {
  try {
    const response = await client.get(`/auto-applicant/${id}`);
    if (!response.errorCode) {
      return response;
    }
  } catch (err) {
    message.error(err.message);
  }
};
export const exportFileUploadOd = async (query) => {
  try {
    download('/od/datasource/export', 'Nhóm KH có giao dịch tín dụng.xls', query);
  } catch (err) {
    message.error(err.message);
  }
  return null;
};

export const exportFileApproveCustomer = async (query) => {
  try {
    download('/od/datasource/export', 'Danh sách KH phê duyệt.xls', query);
  } catch (err) {
    message.error(err.message);
  }
  return null;
};

export const saveAutoApplicantById = async (id, data) => {
  try {
    const response = await client.post(`/auto-applicant/${id}/update-customer-info-sale`, { data: data, loadingIndicator: true });
    if (!response.errorCode) {
      message.success('Lưu thành công');
      return response;
    }
  } catch (err) {
    message.error(err.message);
  }

  return null;
};
export const getAllInterestRate = async () => {
  try {
    const response = await client.get('/auto-interestRate/all?status=1');
    if (!response.errorCode) {
      return response;
    }
  } catch (err) {
    message.error(err.message);
  }
};

export const getAllCompanyType = async () => {
  try {
    const response = await client.get('/company-type/all');
    if (!response.errorCode) {
      return response;
    }
  } catch (err) {
    message.error(err.message);
  }
};
export const uploadAutoDocument = async (id, docType, files) => {
  try {
    let data = new FormData();
    data.append('docType', docType);
    data.append('id', id);
    files.forEach((f) => data.append('multipartFiles', f));
    const response = await client.post('/auto/document/multi-document', { data, loadingIndicator: true });
    if (response) {
      return response;
    }
  } catch (err) {
    message.error(err.message);
    throw err;
  }
};
export const getExpiredInfo = async (id) => {
  try {
    const response = await client.post(`/dis/applicant/${id}/check-overdue`);
    if (response && response.errorCode) {
      message.error(response.errorCode);
    }
    return response;
  } catch (err) {
    message.error(err.message);
  }
};

export const getRelativeInfo = async (id) => {
  try {
    return await client.get(`/dis/applicant/${id}/relative-customer`);
  } catch (err) {
    message.error(err.message);
    return null;
  }
};

export const getLimitT24 = async (id) => {
  try {
    return await client.get(`/dis/applicant/${id}/limit`);
  } catch (err) {
    message.error(err.message);
    return {};
  }
};

export const getTotalAmountOffering = async (businessNumber, id, limitId) => {
  try {
    return await client.get('/dis/applicant/total-amount-offering', {
      params: {
        businessNumber,
        id,
        limitId: encodeURIComponent(limitId),
      },
    });
  } catch (err) {
    message.error(err.message);
    return null;
  }
};

export const updateLimit = async (id) => {
  try {
    return await client.post(`/dis/applicant/${id}/update-limit`);
  } catch (err) {
    message.error(err.message);
    return null;
  }
};
export const updateODLimitThroughCPC = async (id) => {
  try {
    const response = await client.post(`/od/applicant/${id}/change-stt-cpc`);
    if (response.errorCode) return message.error(response.message);
    return response;
  } catch (err) {
    // console.log(err)
    message.error(err.message);
  }
};

export const removeOtherDocument = async (data) => {
  try {
    const response = await client.del('/od/document/delete-other-doc', { data: data });
    if (!response?.errorCode) {
      return true;
    }
  } catch (err) {
    message.error(err.message);
    return false;
  }
};

export const removeAfterApporvalDocument = async (data) => {
  try {
    const response = await client.del('/od/document/delete-add-after-approval-doc', { data: data });
    if (!response?.errorCode) {
      return true;
    }
  } catch (err) {
    message.error(err.message);
    return false;
  }
};
export const removeCSOConfirmCollectFee = async (data) => {
  try {
    const response = await client.del('/od/document/delete-cso-confirm-collect-fee', { data: data });
    if (!response?.errorCode) {
      return true;
    }
  } catch (err) {
    message.error(err.message);
    return false;
  }
};
export const getExchangeRate = async (ccyCode) => {
  try {
    const response = await client.get('/dis/applicant/rate', { params: { ccyCode } });
    if (response && response.errorCode) {
      return null;
    }
    return response;
  } catch (err) {
    message.error(err.message);
    return null;
  }
};

export const getInterestRate = async (data) => {
  try {
    const response = await client.get('/dis/applicant/interest-rate', { params: data });
    if (response && response.errorCode) {
      message.error(response.errorCode);
      return null;
    }
    return response;
  } catch (err) {
    message.error(err.message);
    return null;
  }
};

export const getLcInfo = async (data, id) => {
  try {
    const response = await client.post(`/dis/applicant/${id}/lc-info`, { data });
    if (response && response.errorCode) {
      return null;
    }
    return response;
  } catch (err) {
    return err;
  }
};

export const getKSSV = async (id) => {
  try {
    const response = await client.post(`/dis/applicant/${id}/kssv`);
    if (response && response.errorCode) {
      message.error(response.errorCode);
      return null;
    }
    return response;
  } catch (err) {
    message.error(err.message);
    return null;
  }
};

export const updateKSSV = async (id) => {
  try {
    const response = await client.post(`/dis/applicant/${id}/update-kssv`);
    if (response && response.errorCode) {
      message.error(response.errorCode);
      return null;
    }
    return response;
  } catch (err) {
    message.error(err.message);
    return null;
  }
};

export const getCicInfo = async (id) => {
  try {
    const response = await client.post(`/dis/applicant/${id}/check-cic`);
    if (response && response.errorCode) {
      message.error(response.errorCode);
      return null;
    }
    return response;
  } catch (err) {
    message.error(err.message);
    return null;
  }
};
export const getAllBranch = async () => {
  try {
    const response = await client.get('/branch/all?status=1');
    if (response && !response.errorCode) {
      return response;
    }
  } catch (err) {
    message.error(err.message);
  }
};

export const addRelatedCustomer = async (id, data) => {
  try {
    const response = await client.post(`/od/applicant/${id}/cus-relation`, { data });
    if (response && response.errorCode) {
      message.error(response.errorCode);
      return null;
    }
    return response;
  } catch (err) {
    message.error(err.message);
    return null;
  }
};

export const deleteRelatedCustomer = async (data) => {
  try {
    const response = await client.del('/od/applicant/cus-relation', { params: data });
    if (response && response.errorCode) {
      message.error(response.errorCode);
      return null;
    }
    return response;
  } catch (err) {
    message.error(err.message);
    return null;
  }
};

export const addBeneficiaryGr = async (id, data) => {
  try {
    const response = await client.post(`/od/applicant/${id}/beneficiary-gr`, { data });
    if (response && response.errorCode) {
      message.error(response.errorCode);
      return null;
    }
    return response;
  } catch (err) {
    message.error(err.message);
    return null;
  }
};

export const deleteBeneficiaryGr = async (data) => {
  try {
    const response = await client.del('/od/applicant/beneficiary-gr', { params: data });
    if (response && response.errorCode) {
      message.error(response.errorCode);
      return null;
    }
    return response;
  } catch (err) {
    message.error(err.message);
    return null;
  }
};

export const addCustomerGr = async (id, data) => {
  try {
    const response = await client.post(`/od/applicant/${id}/customer-gr`, { data });
    if (response && response.errorCode) {
      message.error(response.errorCode);
      return null;
    }
    return response;
  } catch (err) {
    message.error(err.message);
    return null;
  }
};

export const deleteCustomerGr = async (data) => {
  try {
    const response = await client.del('/od/applicant/customer-gr', { params: data });
    if (response && response.errorCode) {
      message.error(response.errorCode);
      return null;
    }
    return response;
  } catch (err) {
    message.error(err.message);
    return null;
  }
};

export const signOffline = async (id) => {
  try {
    const response = await client.post(`/od/applicant/${id}/sign-offline`);
    if (response && response.errorCode) {
      message.error(response.errorCode);
      return null;
    }
    return response;
  } catch (err) {
    message.error(err.message);
    return null;
  }
};
export const collectFeeOffline = async (id) => {
  try {
    const response = await client.post(`/od/applicant/${id}/collect-fee-offline`);
    if (response && response.errorCode) {
      message.error(response.errorCode);
      return null;
    }
    return response;
  } catch (err) {
    message.error(err.message);
    return null;
  }
};

// BIL
export const getBILApplicant = async (query) => {
  try {
    const response = await client.get('/bil/applicant', { params: query });
    if (!response.errorCode) {
      return response;
    }
  } catch (err) {
    message.error(err.message);
  }

  return null;
};

export const getBILApplicantV2 = async (query) => {
  try {
    const response = await client.get('/bil/applicant/filter', { params: query });
    if (!response.errorCode) {
      return response;
    }
  } catch (err) {
    message.error(err.message);
  }

  return null;
};

export const exportFileBil = async (query) => {
  try {
    download('/bil/applicant/export', 'danh sach ho so BIL.xls', query);
  } catch (err) {
    message.error(err.message);
  }
  return null;
};

export const getBILApplicantById = async (id) => {
  try {
    const response = await client.get(`/bil/applicant/${id}`);
    if (!response.errorCode) {
      return response;
    }
  } catch (err) {
    message.error(err.message);
  }

  return null;
};

export const getBilDataSource = async (query) => {
  try {
    const response = await client.get('/bil/datasource', { params: query });
    if (!response.errorCode) {
      return response;
    }
  } catch (err) {
    message.error(err.message);
  }

  return null;
};

export const updateBilDataSource = async (id, data) => {
  try {
    const response = await client.put(`/bil/datasource/${id}/update-sale-info`, { data: data, loadingIndicator: true });
    if (response && !response.errorCode) {
      message.success('Cập nhật thành công');
      return response;
    }
  } catch (err) {
    message.error(err.message);
  }
};

export const uploadBilFile = async (data) => {
  try {
    await client.post('/bil/datasource/upload', { data: data });
    message.success('Lưu thành công');
  } catch (err) {
    message.error(err.message);
  }

  return null;
};

export const exportFileUploadBil = async (query) => {
  try {
    download('/bil/datasource/export', 'DS phê duyệt trước BIL.xlsx', query);
  } catch (err) {
    message.error(err.message);
  }
  return null;
};

export const addRelatedCustomerBIL = async (id, data) => {
  try {
    const response = await client.post(`/bil/applicant/${id}/cus-relation`, { data });
    if (response && response.errorCode) {
      message.error(response.errorCode);
      return null;
    }
    return response;
  } catch (err) {
    message.error(err.message);
    return null;
  }
};

export const deleteRelatedCustomerBIL = async (data) => {
  try {
    const response = await client.del('/bil/applicant/cus-relation', { params: data });
    if (response && response.errorCode) {
      message.error(response.errorCode);
      return null;
    }
    return response;
  } catch (err) {
    message.error(err.message);
    return null;
  }
};

export const addBeneficiaryGrBIL = async (id, data) => {
  try {
    const response = await client.post(`/bil/applicant/${id}/beneficiary-gr`, { data });
    if (response && response.errorCode) {
      message.error(response.errorCode);
      return null;
    }
    return response;
  } catch (err) {
    message.error(err.message);
    return null;
  }
};

export const deleteBeneficiaryGrBIL = async (data) => {
  try {
    const response = await client.del('/bil/applicant/beneficiary-gr', { params: data });
    if (response && response.errorCode) {
      message.error(response.errorCode);
      return null;
    }
    return response;
  } catch (err) {
    message.error(err.message);
    return null;
  }
};

export const addCustomerGrBIL = async (id, data) => {
  try {
    const response = await client.post(`/bil/applicant/${id}/customer-gr`, { data });
    if (response && response.errorCode) {
      message.error(response.errorCode);
      return null;
    }
    return response;
  } catch (err) {
    message.error(err.message);
    return null;
  }
};

export const deleteCustomerGrBIL = async (data) => {
  try {
    const response = await client.del('/bil/applicant/customer-gr', { params: data });
    if (response && response.errorCode) {
      message.error(response.errorCode);
      return null;
    }
    return response;
  } catch (err) {
    message.error(err.message);
    return null;
  }
};

export const updateStatusBIL = async (values, status, data) => {
  if (values) {
    delete values.relatedOrganizationGroupList;
    values.relatedOrganizationList.forEach((list) => {
      if (!list.businessNumber) {
        list.businessNumber = null;
      }
      if (!list.documentNumber) {
        list.documentNumber = null;
      }
    });
  }
  try {
    const postData = { data: modifyBody(data), loadingIndicator: true };
    if (['appraise', 'submit', 'submit-appraisal'].includes(status)) {
      postData.data = modifyBody(values);
    }
    if (status === 'sale-reject') {
      postData.data = modifyBody(values);
      postData.data.reason = data.reason;
    }
    const response = await client.post(`/bil/applicant/${values.applicantId}/${status}`, postData);
    if (response.errorCode) return message.error(response.message);
    return response;
  } catch (err) {
    message.error(err.message);
  }

  return null;
};

export const signOfflineBIL = async (id) => {
  try {
    const response = await client.post(`/bil/applicant/${id}/sign-offline`);
    if (response && response.errorCode) {
      message.error(response.errorCode);
      return null;
    }
    return response;
  } catch (err) {
    message.error(err.message);
    return null;
  }
};

export const saveBILApplicantById = async (id, data) => {
  try {
    const response = await client.post(`/bil/applicant/${id}`, { data: modifyBody(data), loadingIndicator: true });
    if (!response.errorCode) {
      message.success('Lưu thành công');
      return response;
    }
  } catch (err) {
    message.error(err.message);
  }

  return null;
};

export const getHistoryBil = async (id) => {
  try {
    const response = client.get(`/bil/applicant/${id}/history`);
    if (!response.errorCode) {
      return response;
    }
  } catch (err) {
    message.error(err.message);
  }
};

export const removeOtherDocumentBil = async (data) => {
  try {
    const response = await client.del('/bil/document/delete-other-doc', { data: data });
    if (!response?.errorCode) {
      return true;
    }
  } catch (err) {
    message.error(err.message);
    return false;
  }
};

export const updateBILLimitThroughCPC = async (id) => {
  try {
    const response = await client.post(`/bil/applicant/${id}/change-stt-cpc`);
    if (response.errorCode) return message.error(response.message);
    return response;
  } catch (err) {
    // console.log(err)
    message.error(err.message);
  }
};

export const retrySignVPBankBIL = async (id) => {
  try {
    const response = await client.post(`/bil/applicant/${id}/retry-sign-vpbank`);
    if (response.errorCode) return message.error(response.message);
    return response;
  } catch (err) {
    message.error(err.message);
    return null;
  }
};

export const retrySignVPBank = async (id) => {
  try {
    const response = await client.post(`/od/applicant/${id}/retry-sign-vpbank`);
    if (response && response.errorCode) {
      message.error(response.errorCode);
      return null;
    }
    return response;
  } catch (err) {
    message.error(err.message);
    return null;
  }
};

export const retrySignVPBankDis = async (id) => {
  try {
    const response = await client.post(`/dis/applicant/${id}/retry-sign-vpbank`);
    if (response && response.errorCode) {
      message.error(response.errorCode);
      return null;
    }
    return response;
  } catch (err) {
    message.error(err.message);
    return null;
  }
};

export const retryPushSignedFile = async (id) => {
  try {
    const response = await client.post(`/dis/applicant/${id}/retry-push-signed-file`);
    if (response && response.errorCode) {
      message.error(response.errorCode);
      return null;
    }
    return response;
  } catch (err) {
    message.error(err.message);
    return null;
  }
};

export const retryDownloadECMFile = async (id) => {
  try {
    const response = await client.post(`/dis/applicant/${id}/retry-download-ecm-file`);
    if (response && response.errorCode) {
      message.error(response.errorCode);
      return null;
    }
    return response;
  } catch (err) {
    message.error(err.message);
    return null;
  }
};

export const getAllNational = async () => {
  return client.get('/category/nationality/all');
};

export const getAllBeneficiaryTypeId = async () => {
  return client.get('/beneficiary-type/all');
};

export const getAllBranchId = async (params) => {
  return client.get('/org/department?departmentType=CENTER', { params });
};

export const getAllAutoDeduction = async (cif) => {
  return client.get(`/ccsme/applicant/${cif}/get-t24-accounts`);
};

export const getAllAutoDeductionByBusinessNumber = async (businessNumber) => {
  try {
    const response = client.post(`/ccsme/applicant/get-t24-accounts-by-dkkd`, {
      data: { dkkd: businessNumber },
    });
    if (!response.errorCode) {
      return response;
    }
  } catch (err) {
    message.error(err.message);
  }
};

export const retryOpenLimit = async (id) => {
  try {
    const response = await client.post(`/bil/applicant/${id}/retry-open-limit`);
    if (response && !response.errorCode) {
      return response;
    }
  } catch (err) {
    message.error(err.message);
  }
};

export const getXMLApplicantDocs = async (applicantId) => {
  try {
    const response = await client.get('/dis/document/xml-applicant-document', { params: { applicantId } });
    if (response && !response.errorCode) {
      return response;
    }
  } catch (err) {
    message.error(err.message);
  }
};

export const exportXMLApplicantDocs = async (invoices) => {
  try {
    await download('/dis/document/export/xml-applicant-document', 'Danh-sach-hoa-don.xls', { invoices }, null, 'POST', false, false, {
      'Content-Type': 'application/json',
    });
  } catch (err) {
    message.error(err.message);
  }
};

export const putXMLApplicantDocs = async (documentCode, note) => {
  try {
    await client.put('/dis/document/xml-applicant-document', { data: { documentCode, note } });
    message.success('Lưu thành công');
  } catch (err) {
    message.error(err.message);
  }
};

export const getAllIndustryType = async () => {
  try {
    const response = await client.get('/industry-type/all');
    if (response && !response.errorCode) {
      return response;
    }
  } catch (err) {
    message.error(err.message);
  }
};

export const getMicroloanApplicant = async (query) => {
  try {
    const response = await client.get('/microloan/applicant', { params: query });
    if (!response.errorCode) {
      return response;
    }
  } catch (err) {
    message.error(err.message);
  }

  return null;
};
export const getMicroloanApplicantV2 = async (query) => {
  try {
    const response = await client.get('/microloan/applicant/filter', { params: query });
    if (!response.errorCode) {
      return response;
    }
  } catch (err) {
    message.error(err.message);
  }

  return null;
};

export const getIndustryDetail = async (industryTypeId) => {
  try {
    const response = await client.get('/industry/all', { params: { industryTypeId } });

    if (response && !response.errorCode) {
      return response;
    }
  } catch (err) {
    message.error(err.message);
  }
};

export const getSPOSApplicant = async (query) => {
  let statusArr = [];
  RECEIPTION_STATUS_OPTIONS.forEach((element) => {
    statusArr.push(element.value);
  });
  if (!query || !query.status) {
    query.status = statusArr;
  }
  try {
    const response = await client.get('/spos/applicant', { params: query });
    if (!response.errorCode) {
      return response;
    }
  } catch (err) {
    message.error(err.message);
  }

  return null;
};
export const getSPOSAlert = async (query) => {
  try {
    const response = await client.get('/spos/visa/alert', { params: query });
    if (!response.errorCode) {
      return response;
    }
  } catch (err) {
    message.error(err.message);
  }

  return null;
};
export const getSPOSReceivedApplicant = async (currentRole, inputDaoCode, query) => {
  let submitQuery = { ...query };
  if (currentRole === 'SALE') {
    submitQuery = { ...query, daoCode: inputDaoCode };
  }

  let statusArr = [];
  STATUS_OPTIONS.forEach((element) => {
    statusArr.push(element.value);
  });
  if (!submitQuery || !submitQuery.status) {
    submitQuery.status = statusArr;
  }
  try {
    const response = await client.get('/spos/applicant', { params: submitQuery });
    if (!response.errorCode) {
      return response;
    }
  } catch (err) {
    message.error(err.message);
  }

  return null;
};

export const getNeobizNotification = async (query) => {
  try {
    const response = await client.get('/notification-b2b', { params: query });
    if (!response.errorCode) {
      return response;
    }
  } catch (err) {
    message.error(err.message);
  }

  return null;
};

export const logout = async () => {
  try {
    const response = await client.post('/auth/logout');
    if (response && !response.errorCode) {
      return response;
    }
  } catch (err) {
    message.error(err.message);
  }
};
