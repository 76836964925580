export const STATUS_OPTIONS = [
  { label: 'Active', value: 'ACTIVE' },
  { label: 'Inactive', value: 'INACTIVE' },
];

export const ALL_OPTION = { label: 'Tất cả', value: ' ' };
export const ALL_OPTION_1 = { label: 'Tất cả', value: 'ALL' };

export const STATUS_VIEW = {
  ACTIVE: { color: '#008037', label: 'Active' },
  INACTIVE: { color: 'gray', label: 'Inactive' },
};

export const TYPE_OPTIONS = [
  { label: 'Phong tỏa', value: 'PHONG_TOA' },
  { label: 'Giải tỏa', value: 'GIAI_TOA' },
];

export const TEMPLATE_OPTIONS = [
  { label: 'Thông thường', value: 'NORMAL' },
  { label: 'Ngoại lệ', value: 'EXCEPTION' },
];

export const QuyCon_OPTIONS = [
  { label: 'Có', value: 1 },
  { label: 'Không', value: 0 },
];
