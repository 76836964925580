import SingleDealConstants from '../constants';
import SingleDealTypes from '../constants/types';

const { PAYMENT_ACCOUNTS, PAGE_TYPE } = SingleDealConstants;

const SingleDealHelper = {
  numberToCurrency(number, decimalSeparator = '.', thousandsSeparator = ',', nDecimalDigits = 0, suffix) {
    if (isNaN(number)) return number;
    const fixed = Number(number).toFixed(nDecimalDigits); //limit/add decimal digits
    const parts = new RegExp('^(-?\\d{1,3})((?:\\d{3})+)(\\.(\\d{' + nDecimalDigits + '}))?$').exec(fixed); //separate begin [$1], middle [$2] and decimal digits [$4]

    let result = '';
    if (parts) {
      //number >= 1000 || number <= -1000
      result += parts[1] + parts[2].replace(/\d{3}/g, thousandsSeparator + '$&') + (parts[4] ? decimalSeparator + parts[4] : '');
    } else {
      result += fixed.replace('.', decimalSeparator);
    }
    if (suffix) result += ` ${suffix}`;

    return result;
  },
  convertStateToPayload(state) {
    const payload = {
      ...state,
      subFundName: state.subFundName || null,
    };

    const convertAccount = (account) => {
      return {
        benName: account.benName,
        benNumber: account.benNumber,
        bankId: account.bank?.value,
        bankName: account.bank?.label,
        bankShortName: account.bank?.bankShortName,
        branchId: account.branch?.value,
        branchName: account.branch?.value === 0 ? account.otherBranchName : account.branch?.label,
        type: account.type,
      };
    };

    if (state.principalSameInterest === '1') {
      const sharedAccount = state.accountInfoList[0];

      payload.accountInfoList = [convertAccount(sharedAccount)];
    } else {
      const originalAccount = state.accountInfoList[1];
      const interestAccount = state.accountInfoList[2];

      payload.accountInfoList = [convertAccount(originalAccount), convertAccount(interestAccount)];
    }

    return payload;
  },
  getPageType(contractId = '', dealId = '') {
    if (contractId && dealId) {
      return PAGE_TYPE.EDIT;
    } else if (contractId) {
      return PAGE_TYPE.EDIT_CONTRACT;
    } else if (dealId) {
      return PAGE_TYPE.EDIT_DEAL;
    } else {
      return PAGE_TYPE.CREATE_DEAL;
    }
  },
  initState(initValues = {}) {
    return {
      // Trạng thái hồ sơ
      status: '',

      // Extra
      editable: true,
      contractId: '',
      dealId: '',
      holidays: new Set(),
      isValidDao: true,

      // General tab
      cifNo: '',
      customerVnName: '',
      customerGbName: '',
      subFundName: '',
      customerSign: '',
      isCollateral: '',
      voucherDebt: '',
      preSignCustomer: '',
      legalId: '',
      dateOfBirth: '',
      buIssuedPlace: '',
      nationality: '',
      buIssuedDate: '',
      representative: '',
      position: '',
      email: '',
      userBook: '',
      related: '',
      dao: '',
      daoEmail: '',
      daoName: '',
      debtVoucherList: [],
      // identificationType:'',
      // identificationNumber:'',
      // identificationDateExpire:'',
      // numberDocumentAgreement:'',

      // Contract tab
      currency: 'VND',
      interestPaymentMethod: '',
      depositTerm: '',
      principalAmount: '',
      interestRate: '',
      debitInfo: '',
      openDate: '',
      maturityDate: '',
      paymentAccount: '',
      principalSameInterest: '1',
      accountInfoList: {
        0: SingleDealTypes.Account({ type: PAYMENT_ACCOUNTS.DEFAULT, benName: initValues.customerVnName || '' }),
        1: SingleDealTypes.Account({ type: PAYMENT_ACCOUNTS.ORIGINAL, benName: initValues.customerVnName || '' }),
        2: SingleDealTypes.Account({ type: PAYMENT_ACCOUNTS.INTEREST, benName: initValues.customerVnName || '' }),
      },
      contractFormId: '',
      contractFormList: [],
      quantity: 1,
      note: '',

      // Document tab
      listContractDetail: [],
    };
  },
};

export default SingleDealHelper;
