import styles from './Loading.module.scss';
import React, { useState } from 'react';
import classnames from 'classnames';
import Lottie from 'react-lottie';
import vpbAnimated from '../../assets/animation/vpbloading_new.json';
import clsx from 'clsx';

export default function Loading({ inline, overlay }) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: vpbAnimated,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid',
    },
  };

  return (
    <div className={clsx(styles.container, inline && styles.inline, overlay && styles.overlay)}>
      <div className={styles.loaderWrapper}>
        <Lottie options={defaultOptions}
          height={90}
          width={90}
          // eventListeners={[
          //   {
          //     eventName: 'complete',
          //     callback: () => {
          //       setDirection((prevState) => prevState === 1 ? -1 : 1);
          //     },
          //   },
          // ]}
          // direction={direction}
          speed={1}
        />
      </div>
    </div>
  );
}
