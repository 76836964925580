import superagent from 'superagent';
import { saveAs } from 'file-saver';
import queryString from 'query-string';
import auth from './authKey';
import authSME from '../auth';
import CONFIG from '../../config';
import message from '../message';
import { v4 as uuid } from 'uuid';
// import { getTokenMSA } from './middleware';

const methods = ['get', 'post', 'put', 'path', 'del'];
const ERROR_SERVER = [503, 500];

export const API_HOST = `${CONFIG.BIZ_GATEWAY_MSA_API_URL}`;

export const mediaUrl = (url) => `${CONFIG.API}/media/${url}`;

export const download = async (
  path,
  fileName,
  data,
  callback,
  method = 'GET',
  rawUrl = false,
  ignoreToken = false,
  headers = {},
  loadingIndicator = false,
) => {
  if (loadingIndicator) document.body.classList.add('loading-indicator');

  if (data && method === 'GET') {
    path = queryString.stringifyUrl({ url: rawUrl ? path : `${API_HOST}/${path}`, query: data });
  } else {
    path = rawUrl ? path : `${API_HOST}/${path}`;
  }

  return fetch(path, {
    method,
    headers: {
      ...(!ignoreToken && { Authorization: `Bearer ${auth.gettoken()}` }),
      ...headers,
    },
    ...(data && method === 'POST' && { body: JSON.stringify(data) }),
  })
    .then((response) => {
      if (response.status === 200) {
        return response.blob();
      }
      throw response;
    })
    .then((myBlob) => {
      saveAs(myBlob, fileName);
    })
    .catch((err) => {
      try {
        err
          .json()
          .then((json) => message.error(json.message ? json.message : 'Lỗi không xác định'));
      } catch (e) {
        message.error('Lỗi không xác định');
      }
    })
    .finally(() => {
      if (loadingIndicator) document.body.classList.remove('loading-indicator');
      callback && callback();
    });
};

export const downloadMedia = (path, fileName) => {
  const request = superagent.get(path);
  request.set('Authorization', `Bearer ${auth.gettoken()}`);
  request.responseType('blob').then((data) => {
    var a = document.createElement('a');
    var url = window.URL.createObjectURL(data.body);
    a.href = url;
    a.download = fileName || 'export.xls';
    a.click();
    window.URL.revokeObjectURL(url);
  });
};

export default class ClientInterceptor {
  constructor(host = API_HOST, hardMSAToken = 'f2419840-b514-351e-afe4-d4cdce889d54') {
    methods.forEach((method) => {
      this[method] = async (
        path,
        {
          params,
          data,
          // silent,
          includeHeader,
          loadingIndicator = false,
          additionalHeader,
          timeout,
        } = {},
        responseType = '',
      ) => {
        // check token MSA
        if (loadingIndicator) document.body.classList.add('loading-indicator');
        // let tokenMSA = await getTokenMSA();

        // if (!tokenMSA) {
        //   auth.clear();
        //   window.location.href = 'digitalgate/login?url=' + window.location.pathname;
        //   return;
        // }

        return new Promise((resolve, reject) => {
          // Initialization request
          const request = superagent[method](`${host}${path}`);

          if (timeout) {
            request.timeout(timeout);
          }

          // set X-Request-Id
          request.set('X-Request-Id', uuid());

          // Set Token to request
          if (authSME.gettoken()) {
            request.set('Authorization', `Bearer ${authSME.gettoken()}`);
          }

          if(hardMSAToken) {
            request.set('x-hardtoken', `Bearer ${hardMSAToken}`);
          }

          // Set Other config header to request
          if (additionalHeader) {
            request.set(Object.assign({}, additionalHeader));
          }

          // Set params to request
          if (params) {
            request.query(params);
          }

          // Set payload body to request
          if (data) {
            request.send(data);
          }

          // Set responseType
          if (responseType) {
            request.responseType(responseType);
          }

          request.end((error, { body, ...other } = {}) => {
            if (loadingIndicator) document.body.classList.remove('loading-indicator');

            if (error) {
              if (error.status === 401) {
                const iframeContainer = document.getElementById('iframeContainer');
                if (iframeContainer) iframeContainer.remove();
                const oldBot = document.getElementById('chatbot-script');
                if (oldBot) oldBot.remove();
                const oldFrame = document.getElementById('botplatform_iframe');
                if (oldFrame) oldFrame.parentNode.removeChild(oldFrame);
                auth.clear();
                window.location.href = 'digitalgate/login?url=' + window.location.pathname;
                return;
              }

              if (ERROR_SERVER.includes(error.status)) {
                reject({ statusCode: error.status, message: 'Hệ thống tạm thời gián đoạn. Vui lòng thử lại sau' });
                return;
              }

              reject(body || error);
            } else if (body && body.statusCode && body.statusCode !== '200' && body.statusCode !== 'OK') {
              reject(body);
            } else {
              includeHeader ? resolve({ body, ...other }) : resolve(body);
            }
          });
        });
      };
    });
  }

  empty() {}
}
