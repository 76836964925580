import infoIcon from 'assets/icons/info.svg';
import { formatMoney } from './helpers/commonFunctions';

export const MAX_MONEY_INPUT = 9999999999999;

export const USER_ROLE = {
  HO: 'HO',
  ADMIN: 'Quản trị hệ thống',
  EKYC_DVTK: 'DVTK của Ekyc',
  EKYC_DVTK247: 'DVKH/24-7 của Ekyc',
  EKYC_HO: 'HO của Ekyc',
  OD_HO: 'HO của OD',
  OD_SALE: 'Sale của SME',
  OD_CGPD: 'Chuyên gia phê duyệt OD',
  GNHM_HO: 'HO của GNHM',
  AUTO_HO: 'HO của Auto Loan',
  CSO: 'CSO',
  BMO: 'BMO',
};
export const CONFIG_HOMEPAGE = {
  ADMIN: '/dashboard',
  HO: '/dashboard',
  EKYC_DVTK: '/applicant-ekyc',
  EKYC_DVTK247: '/applicant-ekyc',
  EKYC_HO: '/applicant-ekyc',
  OD_HO: '/applicant-od',
  OD_SALE: '/applicant-od',
  OD_CGPD: '/applicant-od',
  GNHM_HO: '/applicant-gnhm',
  AUTO_HO: '/applicant-autoloan',
  CSO: '/applicant-gntc',
  BMO: '/news-list',
};

const getFileType = (type) => {
  if (type === 0) {
    return 'application/pdf, .zip, image/*, .msg';
  }
  if (type === 1) {
    return 'application/pdf, .zip, image/*, .msg';
  }
  if (type === 2) {
    return 'application/pdf, .zip, image/*, .msg';
  }
};

const getStringType = (type) => {
  if (type === 0) {
    return 'pdf hoặc zip';
  }
  if (type === 1) {
    return 'pdf hoặc zip';
  }
  if (type === 2) {
    return 'ảnh, pdf hoặc zip';
  }
};

export const GENDER = ['Nam', 'Nữ'];

export const STATUS = ['Không hoạt động', 'Hoạt động'];

export const MARITAL_STATUS = ['Độc thân', 'Đã kết hôn', 'Đã ly hôn'];

export const EMPLOYMENT_STATUS = ['Full time', 'Part time', 'Unemployment', 'Business owners', 'Retired'];

export const CAR_SEAT = {
  4: '4 Chỗ',
  5: '5 Chỗ',
  7: '7 Chỗ',
  9: '9 Chỗ',
  12: '12 Chỗ',
  16: '16 Chỗ',
};
export const CURRENCY = ['VND', 'USD'];

export const INCOME_PERSON = ['Người vay chính', 'Người đồng vay'];

export const APPLICANT_STATUS = ['Chờ hoàn thiện', 'Đã submit', 'Đã xác thực', 'Đã tạo tài khoản', 'Đã liên kết SME-CA'];

export const APP_PERMISSIONS = {
  ADMIN: ['ADMIN'],
  HO: ['HO'],
};

export const ACTIVITY_LOG_TYPE = {
  INITIAL: 'INITIAL',
  UPDATE: 'UPDATE',
  TERMINAL: 'TERMINAL',
};
export const BUSINESS_NUMBER_TYPE = ['Đăng ký kinh doanh', 'Quyết định thành lập'];

export const SEGMENT_LEVEL = ['MICRO', 'SMALL', 'UPPER'];

export const NO_YES = ['Không', 'Có'];

export const META_TYPE = [
  { value: 'TEXT', label: 'Text' },
  { value: 'IMAGE', label: 'Image' },
  { value: 'VIDEO', label: 'Video' },
];

export const LANDING_PAGE_SETTING = [
  { value: 'BANNER_HOMEPAGE:MAIN_TEXT', label: 'BANNER_HOMEPAGE: Text chính', type: 'TEXT' },
  { value: 'BANNER_HOMEPAGE:SUB_TEXT', label: 'BANNER_HOMEPAGE: Text nhỏ', type: 'TEXT' },
  { value: 'BANNER_HOMEPAGE:PROMOTE_TEXT', label: 'BANNER_HOMEPAGE: Text ưu đãi quà tặng', type: 'TEXT' },
  { value: 'BANNER_HOMEPAGE:IMAGE', label: 'BANNER_HOMEPAGE: Ảnh', type: 'IMAGE' },
  //popup config
  { value: 'POPUP:IMAGE', label: 'POPUP: Ảnh', type: 'IMAGE' },
  { value: 'POPUP:URL', label: 'POPUP: Url', type: 'TEXT' },
  // uu dai doanh nghiep
  { value: 'BUSINESS_PROMOTION:VIDEO', label: 'ƯU ĐÃI DOANH NGHIỆP: Video url', type: 'VIDEO' },
  { value: 'BUSINESS_PROMOTION:URL', label: 'ƯU ĐÃI DOANH NGHIỆP: Url', type: 'TEXT' },
  // metadata
  { value: 'SEO_META:TITLE', label: 'SEO_META: Title', type: 'TEXT' },
  { value: 'SEO_META:DESCRIPTION', label: 'SEO_META: Description', type: 'TEXT' },
  { value: 'SEO_META:KEYWORDS', label: 'SEO_META: keywords', type: 'TEXT' },
  //Google analystic
  { value: 'GOOGLE_ANALYTICS:URL', label: 'GOOGLE_ANALYTICS: Url', type: 'TEXT' },
  { value: 'GOOGLE_ANALYTICS:BODY', label: 'GOOGLE_ANALYTICS: Body', type: 'TEXT' },
  //Facebook pixel
  { value: 'FACEBOOK_PIXEL:URL', label: 'FACEBOOK_PIXEL: Url', type: 'TEXT' },
  { value: 'FACEBOOK_PIXEL:BODY', label: 'FACEBOOK_PIXEL: Body', type: 'TEXT' },
  //CONTENT
  { value: 'CONTENT_CONDITION', label: 'Điều kiện', type: 'TEXT' },
  { value: 'CONTENT_REWARD', label: 'Phương thức nhận thưởng', type: 'TEXT' },
];

export const SYNC_SMARTSME_STATUS = ['Chờ đồng bộ', 'Đã đồng bộ', 'Đồng bộ lỗi'];

export const OPTIONS_GROUP_TYPE = [
  {
    value: 0,
    label: 'Công ty mẹ hoặc Tổ chức tín dụng mẹ',
    title: '',
    type: 'PARENT',
  },
  {
    value: 1,
    label: 'Công ty con của KH',
    type: 'SUB',
  },
  {
    value: 2,
    label: 'Công ty có cùng công ty mẹ hoặc có cùng tổ chức tín dụng mẹ với KH',
    type: 'SUB',
  },
  {
    value: 3,
    label: 'Người quản lý, thành viên Ban kiểm soát của công ty mẹ hoặc của Tổ chức tín dụng mẹ của KH',
    title:
      'Người quản lý là Thành viên hợp danh, Chủ tịch Hội đồng thành viên, thành viên Hội đồng thành viên, Chủ tịch công ty, Chủ tịch Hội đồng quản trị, thành viên Hội đồng quản trị, Giám đốc hoặc Tổng giám đốc và cá nhân giữ chức danh quản lý khác có thẩm quyền nhân danh công ty ký kết giao dịch của công ty theo quy định tại Điều lệ của công ty mẹ hoặc tổ chức tín dụng mẹ',
    type: 'OTHER',
  },
  {
    value: 4,
    label: 'Cá nhân hoặc tổ chức có thẩm quyền bổ nhiệm người quản lý, thành viên Ban kiểm soát của công ty mẹ hoặc tổ chức tín dụng mẹ của KH',
    title:
      'Người quản lý là Thành viên hợp danh, Chủ tịch Hội đồng thành viên, thành viên Hội đồng thành viên, Chủ tịch công ty, Chủ tịch Hội đồng quản trị, thành viên Hội đồng quản trị, Giám đốc hoặc Tổng giám đốc và cá nhân giữ chức danh quản lý khác có thẩm quyền nhân danh công ty ký kết giao dịch của công ty theo quy định tại Điều lệ của công ty mẹ hoặc tổ chức tín dụng mẹ',
    type: 'PERSON',
  },
  {
    value: 5,
    label: 'Công ty/ tổ chức mà KH có quyền bổ nhiệm người quản lý, thành Ban kiểm soát của công ty mẹ của công ty/ tổ chức đó',
    type: 'SUB',
  },
  {
    value: 6,
    label: 'Người quản lý, thành viên ban kiểm soát của KH',
    title:
      'Người quản lý  là Chủ doanh nghiệp tư nhân, thành viên hợp danh, Chủ tịch Hội đồng thành viên, thành viên Hội đồng thành viên, Chủ tịch công ty, Chủ tịch Hội đồng quản trị, thành viên Hội đồng quản trị, Giám đốc hoặc Tổng giám đốc và cá nhân giữ chức danh quản lý khác có thẩm quyền nhân danh Khách hàng ký kết giao dịch của Khách hàng theo quy định tại Điều lệ của Khách hàng',
    type: 'OTHER',
  },
  {
    value: 7,
    label: 'Công ty, tổ chức có thẩm quyền bổ nhiệm người quản lý, thành viên ban kiểm soát của KH',
    title:
      'Người quản lý là Thành viên hợp danh, Chủ tịch Hội đồng thành viên, thành viên Hội đồng thành viên, Chủ tịch công ty, Chủ tịch Hội đồng quản trị, thành viên Hội đồng quản trị, Giám đốc hoặc Tổng giám đốc và cá nhân giữ chức danh quản lý khác có thẩm quyền nhân danh Khách hàng ký kết giao dịch của Khách hàng theo quy định tại Điều lệ của Khách hàng',
    type: 'SUB',
  },
  {
    value: 8,
    label: 'Công ty, tổ chức mà KH có quyền bổ nhiệm người quản lý , thành viên Ban kiểm soát',
    title:
      'Người quản lý là Thành viên hợp danh, Chủ tịch Hội đồng thành viên, thành viên Hội đồng thành viên, Chủ tịch công ty, Chủ tịch Hội đồng quản trị, thành viên Hội đồng quản trị, Giám đốc hoặc Tổng giám đốc và cá nhân giữ chức danh quản lý khác có thẩm quyền nhân danh công ty, tổ chức ký kết giao dịch của công ty, tổ chức theo quy định tại Điều lệ của công ty, tổ chức đó',
    type: 'SUB',
  },
  {
    value: 9,
    label:
      'Vợ, chồng, cha, mẹ, con, anh, chị, em của người quản lý, thành viên Ban kiểm soát, thành viên góp vốn hoặc cổ đông sở hữu từ 5% vốn điều lệ hoặc vốn cổ phần có quyền biểu quyết trở lên của Khách hàng',
    title:
      'Con là bao gồm cả con nuôi, con dâu (con rể), con riêng của vợ hoặc chồng. Cha mẹ là bao gồm cả bố chồng (bố vợ), mẹ chồng (mẹ vợ), bố dượng, mẹ kế. Anh, chị, em bao gồm cả anh, chị, em cùng mẹ khác cha hoặc cùng cha khác mẹ, anh rể, chị dâu, em dâu, em rể của người quản lý, thành viên Ban kiểm soát, thành viên góp vốn hoặc cổ đông sở hữu từ 5% vốn điều lệ hoặc vốn cổ phần có quyền biểu quyết trở lên của Khách hàng.',
    type: 'OTHER',
  },
  {
    value: 10,
    label: 'Tổ chức, cá nhân sở hữu từ 5% vốn điều lệ hoặc vốn cổ phần có quyền biểu quyết trở lên tại KH',
    type: 'PERSON',
  },
  {
    value: 11,
    label: 'Công ty, tổ chức mà KH sở hữu từ 5% vốn điều lệ hoặc vốn cổ phần có quyền biểu quyết trở lên',
    type: 'SUB',
  },
  {
    value: 12,
    label: 'Cá nhân được ủy quyền đại diện phần vốn góp, cổ phần cho KH',
    type: 'OTHER',
  },
];
export const FORM_REGISTRATION_NAME = 'RegistrationInfo';
export const FORM_UPLOAD_FILE = 'FORM_UPLOAD_FILE';
export const OD_COMPANY_TYPE = {
  1: 'Có tư cách pháp nhân',
  0: 'Doanh nghiệp tư nhân ',
};
//Mục đích sử dụng vốn
export const PURPOSE_OF_LOAN = [
  {
    value: 1,
    label: 'Thanh toán các chi phí hợp pháp phục vụ hoạt động sản xuất kinh doanh phù hợp với quy định của pháp luật',
  },
  {
    value: 2,
    label: 'Thanh toán các chi phí hợp pháp phục vụ hoạt động sản xuất kinh doanh của Tổ chức mà KH làm chủ sở hữu phù hợp với quy định của pháp luật',
  },
  { value: 0, label: 'Mục đích khác' },
];
//Nguồn trả nợ
export const DEBT_REPAYMENT_SOURCE = [
  { value: 0, label: 'Nguồn thu hợp pháp khác' },
  { value: 1, label: 'Từ hoạt động sản xuất kinh doanh' },
];

//Phương án, dự án thực hiện hoạt động kinh doanh
export const BUSINESS_PLAN = [
  { value: 0, label: 'Phương án khác' },
  { value: 1, label: 'Bổ sung vốn kinh doanh' },
  { value: 2, label: 'Mở rộng sản xuất kinh doanh' },
];
//Chức vụ
export const POSITION_OPTIONS = [
  { value: 1, label: 'Chủ tịch HĐQT' },
  { value: 2, label: 'Tổng giám đốc' },
  { value: 3, label: 'Giám đốc' },
  { value: 0, label: 'Khác' },
];

export const LIMIT_PERIOD_OPTIONS = [
  { value: 1, label: '1 Tháng' },
  { value: 2, label: '2 Tháng' },
  { value: 3, label: '3 Tháng' },
  { value: 4, label: '4 Tháng' },
  { value: 5, label: '5 Tháng' },
  { value: 6, label: '6 Tháng' },
  { value: 7, label: '7 Tháng' },
  { value: 8, label: '8 Tháng' },
  { value: 9, label: '9 Tháng' },
  { value: 10, label: '10 Tháng' },
  { value: 11, label: '11 Tháng' },
  { value: 12, label: '12 Tháng' },
];
export const OPTIONS_EVAL = [
  { value: true, label: 'Đạt' },
  { value: false, label: 'Không đạt' },
];
export const OPTIONS_EVAL_MEET = [
  { value: true, label: 'Thỏa mãn' },
  { value: false, label: 'Không thỏa mãn' },
];
export const OPTIONS_EVAL_NUMBER = [
  { value: 1, label: 'Đạt' },
  { value: 0, label: 'Không đạt' },
];
export const OPTIONS_YES_NO = [
  { value: '1', label: 'Có' },
  { value: '0', label: 'Không' },
];
export const OPTIONS_YES_NO_1 = [
  { value: 1, label: 'Có' },
  { value: 0, label: 'Không' },
];
export const OPTIONS_TRUE_FALSE = [
  { value: true, label: 'Có' },
  { value: false, label: 'Không' },
];
export const OPTIONS_YES_NO_2 = [
  { value: '1', label: 'Có' },
  { value: '0', label: 'Không' },
];
export const OPTIONS_RATE = [
  { value: '1', label: 'Tăng' },
  { value: '0', label: 'Giảm' },
];
export const ORG_OR_INDIVIDUAL = [
  { value: 'ORG', label: 'Tổ chức' },
  { value: 'INDIVIDUAL', label: 'Cá nhân' },
];
export const ENTERPRISE_STATUS = [
  { value: 1, label: 'Hoạt động' },
  { value: 0, label: 'Không hoạt động' },
];
export const DOCUMENT_OD_DATA = {
  dkkd: {
    // options: [1, 2, 5].map(n => <option value={n} key={n}>{OPTION_LABELS[n]}</option>),
    docType: 'BRC', // GIấy chứng nhận đăng ký doanh nghiệp/ DKKD
    title: 'Giấy ĐKKD/ Giấy chứng nhận ĐKDN',
    maxSize: 10485760,
    fileTyes: 'application/pdf',
    options: ['Không thay đổi', 'Thay đổi'],
    defaultOption: 0,
    stringType: 'pdf',
  },
  gcnmst: {
    // options: [1, 2, 5].map(n => <option value={n} key={n}>{OPTION_LABELS[n]}</option>),
    docType: 'CTR', // Giấy chứng nhận mã số thuế
    title: 'Giấy chứng nhận mã số thuế',
    maxSize: 10485760,
    fileTyes: 'application/pdf',
    options: ['Không thay đổi', 'Thay đổi', 'Không có'],
    defaultOption: 0,
    stringType: 'pdf',
  },
  qdtldn: {
    // options: [1, 2, 5].map(n => <option value={n} key={n}>{OPTION_LABELS[n]}</option>),
    docType: 'BSTD', //Quyết định thành lập doanh nghiệp
    title: 'Quyết định thành lập doanh nghiệp',
    maxSize: 10485760,
    fileTyes: 'application/pdf',
    options: ['Không thay đổi', 'Thay đổi', 'Không có'],
    defaultOption: 0,
    stringType: 'pdf',
  },
  dldn: {
    // options: [1, 2, 5].map(n => <option value={n} key={n}>{OPTION_LABELS[n]}</option>),
    docType: 'CAC', //Điều lệ hoạt động doanh nghiệp
    title: 'Điều lệ doanh nghiệp',
    maxSize: 15728640,
    fileTyes: 'application/pdf',
    options: ['Không thay đổi', 'Thay đổi', 'Không có'],
    defaultOption: 0,
    stringType: 'pdf',
  },
  cchn: {
    // options: [1, 2, 5].map(n => <option value={n} key={n}>{OPTION_LABELS[n]}</option>),
    docType: 'BPC', //Hồ sơ đối với ngành nghề kinh doanh có kiều kiện,
    title: 'Hồ sơ đối với ngành nghề kinh doanh có kiều kiện',
    multiple: true,
    maxSize: 10485760,
    fileTyes: 'application/pdf',
    icon: infoIcon,
    description:
      'Bao gồm Chứng chỉ hành nghề/ Giấy chứng nhận đủ điều kiện kinh doanh/Giấy phép con/ văn bản chứng minh đủ điều kiện kinh doanh khác đối với ngành nghề kinh doanh có điều kiện.',
    options: ['Không thay đổi', 'Thay đổi', 'Không có'],
    defaultOption: 0,
    stringType: 'pdf',
  },
  cmnd: {
    // options: [1, 2, 5].map(n => <option value={n} key={n}>{OPTION_LABELS[n]}</option>),
    docType: 'LID', //Giấy chứng minh nhân thân
    title: 'Chứng minh nhân thân đại diện theo pháp luật',
    maxSize: 15728640,
    multiple: true,
    fileTyes: 'image/*, application/pdf',
    icon: infoIcon,
    description: 'Chứng minh thư/Hộ chiếu/ CCCD hoặc các giấy tờ tương đương của Đại diện theo pháp luật/ Chủ sở hữu Doanh nghiệp tư nhân.',
    options: ['Không thay đổi', 'Thay đổi'],
    defaultOption: 0,
    stringType: 'pdf hoặc ảnh',
  },
  bbhhdtv: {
    // options: [1, 2, 5].map(n => <option value={n} key={n}>{OPTION_LABELS[n]}</option>),
    docType: 'MMMC', //Biên bản họp hội đồng thành viên
    title: 'Biên bản họp/ Quyết định của chủ sở hữu phê duyệt về việc nhận cấp tín dụng từ VPBank',
    maxSize: 10485760,
    fileTyes: 'application/pdf',
    icon: infoIcon,
    description:
      'Biên bản cuộc họp Hội đồng thành viên/ Hội đồng quản trị/Đại Hội đồng cổ đông/Quyết định của chủ sở hữu phê duyệt việc nhận cấp tín dụng từ VPBank.',
    options: [{ label: 'Không có', note: '(Do không thuộc các trường hợp phải cung cấp)' }, 'Cung cấp mới'],
    defaultOption: 0,
    stringType: 'pdf',
    note: 'Chọn không có trong trường hợp KH là Công ty TNHH 1 thành viên mà chủ sở hữu đồng thời là người đại diện theo Pháp luật',
    multiple: true,
  },
  dshdtv: {
    // options: [1, 2, 5].map(n => <option value={n} key={n}>{OPTION_LABELS[n]}</option>),
    docType: 'FML', //Danh sách thành viên Hội đồng quản trị hiện hữu
    title: 'Danh sách Thành viên',
    maxSize: 10485760,
    fileTyes: 'application/pdf',
    icon: infoIcon,
    description:
      'Danh sách Thành viên Hội đồng quản trị/ Thành viên Hội đồng thành viên hiện hữu. Không áp dụng đối với KH là chủ sở hữu của DNTN, Công ty TNHH 1 thành viên.',
    options: ['Không có', 'Cung cấp mới'],
    defaultOption: 0,
    stringType: 'pdf',
    multiple: true,
  },
  cic: {
    docType: 'CIC',
    title: 'Đăng tải bổ sung tài liệu CIC',
    maxSize: 10485760,
    fileTyes: 'application/pdf, .zip',
    // options: ['Không thay đổi', 'Cung cấp mới'],
    defaultOption: 1,
    stringType: 'pdf, zip',
    multiple: true,
  },
  // Bổ sung tài liệu từ MSA trả về sau thẩm định
  as: {
    docType: 'AS',
    title: 'Báo cáo thẩm định hệ thống:',
    maxSize: 10485760,
    fileTyes: 'application/pdf, .zip',
    stringType: 'pdf, zip',
    multiple: true,
  },
  am: {
    docType: 'AM',
    // title: 'Báo cáo thẩm định từ Trưởng phòng:',
    maxSize: 15728640,
    fileTyes: 'application/pdf, .zip, .doc, .docx',
    stringType: 'pdf, zip, word',
    multiple: true,
    options: ['Không đồng ý báo cáo thẩm định từ hệ thống', 'Đồng ý báo cáo thẩm định từ hệ thống'],
  },
  shk: {
    // options: [1, 2, 5].map(n => <option value={n} key={n}>{OPTION_LABELS[n]}</option>),
    docType: 'HHB', //Sổ hộ khẩu/sổ tạm trú/xác nhận tạm trú
    title: 'Sổ hộ khẩu hoặc sổ tạm trú hoặc xác nhận tạm trú của công an Phường của chủ sỡ hữu DNTN/HKD',
    multiple: true,
    maxSize: 15728640,
    fileTyes: 'image/*, application/pdf',
    options: ['Không thay đổi', 'Thay đổi'],
    defaultOption: 0,
    stringType: 'pdf hoặc ảnh',
  },
  dkkh: {
    // options: [1, 2, 5].map(n => <option value={n} key={n}>{OPTION_LABELS[n]}</option>),
    docType: 'CMS', //Đăng ký kết hôn/Xác nhận độc thân
    title: 'Đăng ký kết hôn hoặc xác nhận độc thân của chủ sỡ hữu DNTN/HKD',
    maxSize: 10485760,
    fileTyes: 'application/pdf',
    options: ['Không thay đổi', 'Thay đổi'],
    defaultOption: 0,
    stringType: 'pdf',
  },
  bctct: {
    // options: [1, 2, 5].map(n => <option value={n} key={n}>{OPTION_LABELS[n]}</option>),
    docType: 'RFS', //Báo cáo tài chính năm gần nhất
    title: 'Báo cáo tài chính năm gần nhất',
    maxSize: 15728640,
    fileTyes: 'application/pdf',
    options: ['Đã cung cấp', 'Chưa cung cấp'],
    stringType: 'pdf',
    defaultOption: 1,
  },
  OD_TKTN: {
    docType: 'OD_TKTN',
    title: 'Tờ khai thuế năm N',
    maxSize: 15728640,
    fileTyes: 'application/pdf',
    options: ['Đã cung cấp', 'Chưa cung cấp'],
    stringType: 'pdf',
    defaultOption: 1,
  },
  other: {
    docType: 'OTHER',
    title: 'Đăng tải bổ sung tài liệu khác',
    maxSize: 15728640,
    fileTyes: 'application/pdf',
    stringType: 'pdf',
    multiple: true,
  },
  aaad: {
    docType: 'AAAD',
    title: 'Đăng tải bổ sung tài liệu khác',
    maxSize: 15728640,
    fileTyes: 'application/pdf, .zip, image/*, .msg',
    stringType: 'pdf, ảnh, file zip hoặc email',
    multiple: true,
  },
  AED: {
    docType: 'AED',
    title: 'Cần cung cấp phê duyệt ngoại lệ giảm phí thiết lập tài khoản thấu chi trước khi trình duyệt',
    maxSize: 15728640,
    fileTyes: 'application/pdf, .zip, image/*, .msg',
    stringType: 'pdf, ảnh, file zip hoặc email',
    multiple: true,
  },
  CCCFO: {
    docType: 'CCCFO',
    title: 'Xác nhận của CSO về việc đã hạch toán phí',
    maxSize: 15728640,
    fileTyes: 'image/*, application/pdf, .zip',
    stringType: 'pdf, ảnh, hoặc zip',
    required: true,
    multiple: true,
  },
  OD_BENEFICIARY_GR: {
    docType: 'CIC',
    customerGroupType: 'OD_BENEFICIARY_GR',
    title: 'CIC thành viên góp vốn chính/TVGV cao nhất',
    maxSize: 10485760,
    fileTyes: 'application/pdf',
    options: ['Không thay đổi', 'Cung cấp mới'],
    defaultOption: 1,
    stringType: 'pdf',
  },
  OD_CUSTOMER_GROUP_GR: {
    docType: 'CIC',
    customerGroupType: 'OD_CUSTOMER_GROUP_GR',
    title: 'CIC nhóm KH được xem như một KH',
    maxSize: 10485760,
    fileTyes: 'application/pdf',
    options: ['Không thay đổi', 'Cung cấp mới'],
    defaultOption: 1,
    stringType: 'pdf',
  },
  OD_RELATION_CUSTOMER_GR: {
    docType: 'CIC',
    customerGroupType: 'OD_RELATION_CUSTOMER_GR',
    title: 'CIC Nhóm Khách hàng có quan hệ trực tiếp về sở hữu và/hoặc điều hành',
    maxSize: 10485760,
    fileTyes: 'application/pdf',
    options: ['Không thay đổi', 'Cung cấp mới'],
    defaultOption: 1,
    stringType: 'pdf',
  },
  KQTCTT: {
    docType: 'KQTCTT',
    title: 'Kết quả tra cứu thông tin  KH, TVGVC, nhóm KH được coi như một KH, người đại diện theo pháp luật theo danh sách do Khối QTRR cung cấp',
    maxSize: 10485760,
    fileTyes: 'image/*, application/pdf, .zip',
    // options: ['Không thay đổi', 'Cung cấp mới'],
    defaultOption: 1,
    stringType: 'ảnh, pdf hoặc zip',
    multiple: true,
  },
  HDBLCN: {
    docType: 'HDBLCN',
    title: 'Hợp đồng bảo lãnh cá nhân',
    maxSize: 10485760,
    fileTyes: 'application/pdf, .zip, image/*, .msg',
    options: [
      'KH có bảo lãnh cá nhân theo Quy định',
      'KH được phê duyệt ngoại lệ không áp dụng bảo lãnh cá nhân',
      'KH có bảo lãnh cá nhân theo quy định nhưng sẽ thu thập hồ sơ gốc sau',
      'KH thuộc trường hợp không yêu cầu bảo lãnh cá nhân',
    ],
    defaultOption: null,
    stringType: 'pdf, ảnh, file zip hoặc email',
    multiple: true,
    notice: 'Không bắt buộc đối với KH thuộc nhóm giao dịch tín dụng có hạn mức từ 500 triệu VND trở xuống',
    isRequiredOption: true,
    messageRequiredOption: 'Vui lòng chọn giá trị Hợp đồng bảo lãnh cá nhân để tiếp tục',
  },
  io12_out: {
    // options: [1, 2, 5].map(n => <option value={n} key={n}>{OPTION_LABELS[n]}</option>),
    docType: 'IO12_OUT', //Báo cáo tài chính năm gần nhất
    maxSize: 15728640,
    fileTyes: 'application/pdf',
    options: ['Đã cung cấp', 'Chưa cung cấp'],
    defaultOption: 1,
    stringType: 'pdf',
    title: 'Hóa đơn mua bán đầu ra với các Khách hàng chính trong vòng 12 tháng gần nhất và các hợp đồng đầu ra lớn dự kiến triển khai',
  },
  io12_in: {
    // options: [1, 2, 5].map(n => <option value={n} key={n}>{OPTION_LABELS[n]}</option>),
    docType: 'IO12_IN', //Báo cáo tài chính năm gần nhất
    maxSize: 15728640,
    fileTyes: 'application/pdf',
    options: ['Đã cung cấp', 'Chưa cung cấp'],
    defaultOption: 1,
    stringType: 'pdf',
    title: 'Hợp đồng/Hóa đơn đầu vào từ các đối tác chính trong vòng 12 tháng gần nhất',
  },
  V6M: {
    docType: 'V6M', //Báo cáo tài chính năm gần nhất
    maxSize: 15728640,
    fileTyes: 'application/pdf',
    // options: ['Đã cung cấp', 'Chưa cung cấp'],
    defaultOption: 1,
    stringType: 'pdf',
    multiple: true,
    title: 'Tờ khai VAT 6 tháng gần nhất',
  },
  OD_HSTD: {
    title: 'Hồ sơ thực địa',
    docType: 'OD_HSTD', //Báo cáo tài chính năm gần nhất
    maxSize: 15728640,
    fileTyes: 'image/*, application/pdf, .zip',
    options: ['Đã cung cấp', 'Chưa cung cấp'],
    defaultOption: 1,
    stringType: 'ảnh, pdf hoặc file zip',
    multiple: true,
  },
  LIDP: {
    title: 'CMND/Hộ chiếu của thành viên góp vốn là cá nhân',
    // options: [1, 2, 5].map(n => <option value={n} key={n}>{OPTION_LABELS[n]}</option>),
    docType: 'LIDP', //Giấy chứng minh nhân thân
    maxSize: 15728640,
    multiple: true,
    fileTyes: 'image/*, application/pdf',
    icon: infoIcon,
    description: 'CMND/Hộ chiếu của thành viên góp vốn là cá nhân',
    options: ['Không có', 'Cung cấp mới'],
    defaultOption: 1,
    stringType: 'pdf hoặc ảnh',
  },
  PCIC: {
    title: 'ĐKKD của thành viên góp vốn là Tổ chức',
    // options: [1, 2, 5].map(n => <option value={n} key={n}>{OPTION_LABELS[n]}</option>),
    docType: 'PCIC', //Giấy chứng minh nhân thân
    maxSize: 15728640,
    multiple: true,
    fileTyes: 'image/*, application/pdf',
    icon: infoIcon,
    description: 'ĐKKD của thành viên góp vốn là Tổ chức',
    options: ['Không có', 'Cung cấp mới'],
    defaultOption: 1,
    stringType: 'pdf hoặc ảnh',
  },
  PCD: {
    // options: [1, 2, 5].map(n => <option value={n} key={n}>{OPTION_LABELS[n]}</option>),
    docType: 'PCD', //Giấy chứng minh nhân thân
    maxSize: 15728640,
    multiple: true,
    fileTyes: 'image/*, application/pdf',
    icon: infoIcon,
    description: 'Sổ đăng ký cổ đông/ giấy chứng nhận góp vốn',
    options: ['Không có', 'Cung cấp'],
    defaultOption: 1,
    stringType: 'pdf hoặc ảnh',
    title: 'Sổ đăng ký cổ đông/ giấy chứng nhận góp vốn',
    note: 'Chọn không có trong trường hợp thông tin về thành viên góp vốn đã có và được cập nhật trên Giấy chứng nhận đăng ký DN (không áp dụng đối với công ty cổ phần)',
  },
  other_vpb: {
    docType: 'OTHER_VPB',
    title: 'Hồ sơ nội bộ của VPB',
    maxSize: 15728640,
    fileTyes: 'image/*, application/pdf, .zip',
    stringType: 'pdf, ảnh, hoặc zip',
    multiple: true,
  },
  cccdtvgvc: {
    docType: 'SID',
    title: 'Căn cước công dân/giấy tờ tương đương của thành viên góp vốn',
    maxSize: 15728640,
    fileTyes: 'application/pdf',
    icon: infoIcon,
    description:
      'KH cung cấp thông tin của thành viên góp vốn từ 25% vốn góp trở lên, trong trường hợp không có thành viên góp vốn từ 25% trở lên thì cung cấp thông tin của thành viên góp vốn cao nhất.',
    options: ['Không thay đổi', 'Thay đổi'],
    defaultOption: 0,
    stringType: 'pdf',
    note: 'KH cung cấp thông tin của thành viên góp vốn từ 25% vốn góp trở lên, trong trường hợp không có thành viên góp vốn từ 25% trở lên thì cung cấp thông tin của thành viên góp vốn cao nhất.',
    multiple: true,
  },
  soDangKy: {
    docType: 'SHR',
    title: 'Sổ đăng ký cổ đông/sổ đăng ký thành viên (áp dụng với công ty cổ phần, công ty TNHH 2 tv trở lên)/giấy chứng nhận phần vốn góp (các công ty khác)',
    maxSize: 15728640,
    fileTyes: 'application/pdf',
    icon: infoIcon,
    description:
      'Chọn không có trong trường hợp thông tin về thành viên góp vốn đã có và được cập nhật trên Giấy chứng nhận đăng ký DN (không áp dụng đối với công ty cổ phần)',
    options: ['Không có', 'Cung cấp mới'],
    defaultOption: 0,
    stringType: 'pdf',
    note: 'Chọn không có trong trường hợp thông tin về thành viên góp vốn đã có và được cập nhật trên Giấy chứng nhận đăng ký DN (không áp dụng đối với công ty cổ phần)',
    multiple: true,
  },
};

export const DOCUMENT_OD_DATA_NEW = {
  dkkd: {
    // options: [1, 2, 5].map(n => <option value={n} key={n}>{OPTION_LABELS[n]}</option>),
    docType: 'BRC', // GIấy chứng nhận đăng ký doanh nghiệp/ DKKD
    title: 'Giấy ĐKKD/ Giấy chứng nhận ĐKDN',
    maxSize: 10485760,
    fileTyes: 'application/pdf',
    // options: ['Không thay đổi', 'Thay đổi'],
    defaultOption: 1,
    stringType: 'pdf',
    required: true,
    multiple: true,
  },
  CCCFO: {
    docType: 'CCCFO',
    title: 'Xác nhận của CSO về việc đã hạch toán phí',
    maxSize: 15728640,
    fileTyes: 'image/*, application/pdf, .zip',
    stringType: 'pdf, ảnh, hoặc zip',
    required: true,
    multiple: true,
  },

  gcnmst: {
    // options: [1, 2, 5].map(n => <option value={n} key={n}>{OPTION_LABELS[n]}</option>),
    docType: 'CTR', // Giấy chứng nhận mã số thuế
    title: 'Giấy chứng nhận mã số thuế',
    maxSize: 10485760,
    fileTyes: 'application/pdf',
    options: ['Không có', 'Cung cấp'],
    defaultOption: 0,
    stringType: 'pdf',
    note: 'Chọn không có nếu Mã số thuế được cấp cùng với Giấy chứng nhận đăng ký doanh nghiệp',
  },
  qdtldn: {
    // options: [1, 2, 5].map(n => <option value={n} key={n}>{OPTION_LABELS[n]}</option>),
    docType: 'BSTD', //Quyết định thành lập doanh nghiệp
    title: 'Quyết định thành lập doanh nghiệp',
    maxSize: 10485760,
    fileTyes: 'application/pdf',
    options: ['Không có', 'Cung cấp'],
    defaultOption: 0,
    stringType: 'pdf',
    note: 'Chọn không có nếu KH thuộc trường hợp không có Quyết định thành lập theo quy định pháp luật',
  },
  dldn: {
    // options: [1, 2, 5].map(n => <option value={n} key={n}>{OPTION_LABELS[n]}</option>),
    docType: 'CAC', //Điều lệ hoạt động doanh nghiệp
    title: 'Điều lệ doanh nghiệp',
    maxSize: 15728640,
    fileTyes: 'application/pdf',
    // options: ['Không có', 'Cung cấp'],
    defaultOption: 1,
    stringType: 'pdf',
    multiple: true,
  },
  cchn: {
    // options: [1, 2, 5].map(n => <option value={n} key={n}>{OPTION_LABELS[n]}</option>),
    docType: 'BPC', //Hồ sơ đối với ngành nghề kinh doanh có kiều kiện,
    title: 'Hồ sơ đối với ngành nghề kinh doanh có kiều kiện',
    multiple: true,
    maxSize: 10485760,
    fileTyes: 'application/pdf',
    icon: infoIcon,
    description:
      'Bao gồm Chứng chỉ hành nghề/ Giấy chứng nhận đủ điều kiện kinh doanh/Giấy phép con/ văn bản chứng minh đủ điều kiện kinh doanh khác đối với ngành nghề kinh doanh có điều kiện.',
    options: ['Không có', 'Cung cấp'],
    defaultOption: 0,
    stringType: 'pdf',
    note: 'Chọn không có nếu KH không có ngành, nghề kinh doanh có điều kiện',
  },
  cmnd: {
    // options: [1, 2, 5].map(n => <option value={n} key={n}>{OPTION_LABELS[n]}</option>),
    docType: 'LID', //Giấy chứng minh nhân thân
    title: 'CMND/Hộ chiếu đại diện theo pháp luật',
    maxSize: 15728640,
    multiple: true,
    fileTyes: 'image/*, application/pdf',
    icon: infoIcon,
    description: 'Chứng minh thư/Hộ chiếu/ CCCD hoặc các giấy tờ tương đương của Đại diện theo pháp luật/ Chủ sở hữu Doanh nghiệp tư nhân.',
    // options: ['Không thay đổi', 'Thay đổi'],
    defaultOption: 1,
    stringType: 'pdf hoặc ảnh',
  },
  bbhhdtv: {
    // options: [1, 2, 5].map(n => <option value={n} key={n}>{OPTION_LABELS[n]}</option>),
    docType: 'MMMC', //Biên bản họp hội đồng thành viên
    title: 'Biên bản họp/ Quyết định của chủ sở hữu phê duyệt về việc nhận cấp tín dụng từ VPBank',
    maxSize: 10485760,
    fileTyes: 'application/pdf',
    icon: infoIcon,
    description:
      'Biên bản cuộc họp Hội đồng thành viên/ Hội đồng quản trị/Đại Hội đồng cổ đông/Quyết định của chủ sở hữu phê duyệt việc nhận cấp tín dụng từ VPBank.',
    options: [{ label: 'Không có', note: '(Do không thuộc các trường hợp phải cung cấp)' }, 'Cung cấp mới'],
    defaultOption: 0,
    stringType: 'pdf',
    note: 'Chọn không có trong trường hợp KH là Công ty TNHH 1 thành viên mà chủ sở hữu đồng thời là người đại diện theo Pháp luật',
    multiple: true,
  },
  dshdtv: {
    // options: [1, 2, 5].map(n => <option value={n} key={n}>{OPTION_LABELS[n]}</option>),
    docType: 'FML', //Danh sách thành viên Hội đồng quản trị hiện hữu
    title: 'Danh sách Thành viên',
    maxSize: 10485760,
    fileTyes: 'application/pdf',
    icon: infoIcon,
    description:
      'Danh sách Thành viên Hội đồng quản trị/ Thành viên Hội đồng thành viên hiện hữu. Không áp dụng đối với KH là chủ sở hữu của DNTN, Công ty TNHH 1 thành viên.',
    options: ['Không áp dụng', 'Cung cấp'],
    defaultOption: 0,
    stringType: 'pdf',
    note: 'Chọn không có trong trường hợp KH là Công ty TNHH 1 thành viên',
    multiple: true,
  },
  cic: {
    docType: 'CIC',
    title: 'Đăng tải bổ sung tài liệu CIC',
    maxSize: 10485760,
    fileTyes: 'application/pdf, .zip',
    // options: ['Không thay đổi', 'Cung cấp mới'],
    defaultOption: 1,
    stringType: 'pdf, zip',
    multiple: true,
  },
  // Bổ sung tài liệu từ MSA trả về sau thẩm định
  as: {
    docType: 'AS',
    title: 'Báo cáo thẩm định hệ thống:',
    maxSize: 10485760,
    fileTyes: 'application/pdf, .zip',
    stringType: 'pdf, zip',
    multiple: true,
  },
  am: {
    docType: 'AM',
    // title: 'Báo cáo thẩm định từ Trưởng phòng:',
    maxSize: 15728640,
    fileTyes: 'application/pdf, .zip, .doc, .docx',
    stringType: 'pdf, zip, word',
    multiple: true,
    options: ['Không đồng ý báo cáo thẩm định từ hệ thống', 'Đồng ý báo cáo thẩm định từ hệ thống'],
  },
  shk: {
    // options: [1, 2, 5].map(n => <option value={n} key={n}>{OPTION_LABELS[n]}</option>),
    docType: 'HHB', //Sổ hộ khẩu/sổ tạm trú/xác nhận tạm trú
    title: 'Sổ hộ khẩu/ sổ tạm trú/ xác nhận tạm trú',
    multiple: true,
    maxSize: 15728640,
    fileTyes: 'image/*, application/pdf',
    options: ['Không thay đổi', 'Thay đổi'],
    defaultOption: 0,
    stringType: 'pdf hoặc ảnh',
  },
  dkkh: {
    // options: [1, 2, 5].map(n => <option value={n} key={n}>{OPTION_LABELS[n]}</option>),
    docType: 'CMS', //Đăng ký kết hôn/Xác nhận độc thân
    title: 'Đăng ký kết hôn/ xác nhận độc thân',
    maxSize: 10485760,
    fileTyes: 'application/pdf',
    options: ['Không thay đổi', 'Thay đổi'],
    defaultOption: 0,
    stringType: 'pdf',
  },
  bctct: {
    // options: [1, 2, 5].map(n => <option value={n} key={n}>{OPTION_LABELS[n]}</option>),
    docType: 'RFS', //Báo cáo tài chính năm gần nhất
    title: 'Báo cáo tài chính năm gần nhất',
    maxSize: 15728640,
    fileTyes: 'application/pdf',
    options: ['Đã cung cấp', 'Chưa cung cấp'],
    stringType: 'pdf',
    defaultOption: 0,
  },
  OD_TKTN: {
    // options: [1, 2, 5].map(n => <option value={n} key={n}>{OPTION_LABELS[n]}</option>),
    docType: 'OD_TKTN', //Báo cáo tài chính năm gần nhất
    title: 'Tờ khai thuế năm N',
    maxSize: 15728640,
    fileTyes: 'application/pdf',
    options: ['Đã cung cấp', 'Chưa cung cấp'],
    stringType: 'pdf',
    defaultOption: 1,
  },
  other: {
    docType: 'OTHER',
    title: 'Đăng tải bổ sung tài liệu khác',
    maxSize: 15728640,
    fileTyes: 'application/pdf',
    stringType: 'pdf',
    multiple: true,
  },
  aaad: {
    docType: 'AAAD',
    title: 'Đăng tải bổ sung tài liệu khác',
    maxSize: 15728640,
    fileTyes: 'application/pdf, .zip, image/*, .msg',
    stringType: 'pdf, ảnh, file zip hoặc email',
    multiple: true,
  },
  AED: {
    docType: 'AED',
    title: 'Cần cung cấp phê duyệt ngoại lệ giảm phí thiết lập tài khoản thấu chi trước khi trình duyệt',
    maxSize: 15728640,
    fileTyes: 'application/pdf, .zip, image/*, .msg',
    stringType: 'pdf, ảnh, file zip hoặc email',
    multiple: true,
  },
  OD_BENEFICIARY_GR: {
    docType: 'CIC',
    customerGroupType: 'OD_BENEFICIARY_GR',
    title: 'CIC thành viên góp vốn chính/TVGV cao nhất',
    maxSize: 10485760,
    fileTyes: 'application/pdf',
    options: ['Không thay đổi', 'Cung cấp'],
    defaultOption: 0,
    stringType: 'pdf',
  },
  OD_CUSTOMER_GROUP_GR: {
    docType: 'CIC',
    customerGroupType: 'OD_CUSTOMER_GROUP_GR',
    title: 'CIC nhóm KH được xem như một KH',
    maxSize: 10485760,
    fileTyes: 'application/pdf',
    options: ['Không thay đổi', 'Cung cấp'],
    defaultOption: 1,
    stringType: 'pdf',
  },
  OD_RELATION_CUSTOMER_GR: {
    docType: 'CIC',
    customerGroupType: 'OD_RELATION_CUSTOMER_GR',
    title: 'CIC Nhóm Khách hàng có quan hệ trực tiếp về sở hữu và/hoặc điều hành',
    maxSize: 10485760,
    fileTyes: 'application/pdf',
    options: ['Không thay đổi', 'Cung cấp'],
    defaultOption: 1,
    stringType: 'pdf',
  },
  KQTCTT: {
    docType: 'KQTCTT',
    title: 'Kết quả tra cứu thông tin  KH, TVGVC, nhóm KH được coi như một KH, người đại diện theo pháp luật theo danh sách do Khối QTRR cung cấp',
    maxSize: 10485760,
    fileTyes: 'image/*, application/pdf, .zip',
    // options: ['Không thay đổi', 'Cung cấp mới'],
    defaultOption: 1,
    stringType: 'ảnh, pdf hoặc zip',
    multiple: true,
  },
  HDBLCN: {
    docType: 'HDBLCN',
    title: 'Hợp đồng bảo lãnh cá nhân',
    maxSize: 10485760,
    fileTyes: 'application/pdf, .zip, image/*, .msg',
    options: [
      'KH có bảo lãnh cá nhân theo Quy định',
      'KH được phê duyệt ngoại lệ không áp dụng bảo lãnh cá nhân',
      'KH có bảo lãnh cá nhân theo quy định nhưng sẽ thu thập hồ sơ gốc sau ',
      'KH thuộc trường hợp không yêu cầu bảo lãnh cá nhân',
    ],
    defaultOption: null,
    stringType: 'pdf, ảnh, file zip hoặc email',
    multiple: true,
    notice: 'Không bắt buộc đối với KH thuộc nhóm giao dịch tín dụng có hạn mức từ 500 triệu VND trở xuống',
    isRequiredOption: true,
    messageRequiredOption: 'Vui lòng chọn giá trị Hợp đồng bảo lãnh cá nhân để tiếp tục',
  },
  io12_out: {
    // options: [1, 2, 5].map(n => <option value={n} key={n}>{OPTION_LABELS[n]}</option>),
    docType: 'IO12_OUT', //Báo cáo tài chính năm gần nhất
    maxSize: 15728640,
    fileTyes: 'application/pdf',
    // options: ['Đã cung cấp', 'Chưa cung cấp'],
    defaultOption: 1,
    stringType: 'pdf',
    multiple: true,
    title: 'Hóa đơn mua bán đầu ra với các Khách hàng chính trong vòng 12 tháng gần nhất và các hợp đồng đầu ra lớn dự kiến triển khai',
  },
  io12_in: {
    // options: [1, 2, 5].map(n => <option value={n} key={n}>{OPTION_LABELS[n]}</option>),
    docType: 'IO12_IN', //Báo cáo tài chính năm gần nhất
    maxSize: 15728640,
    fileTyes: 'application/pdf',
    // options: ['Đã cung cấp', 'Chưa cung cấp'],
    defaultOption: 1,
    stringType: 'pdf',
    multiple: true,
    title: 'Hợp đồng/Hóa đơn đầu vào từ các đối tác chính trong vòng 12 tháng gần nhất',
  },
  V6M: {
    docType: 'V6M', //Báo cáo tài chính năm gần nhất
    maxSize: 15728640,
    fileTyes: 'application/pdf',
    // options: ['Đã cung cấp', 'Chưa cung cấp'],
    defaultOption: 1,
    stringType: 'pdf',
    multiple: true,
    title: 'Tờ khai VAT 6 tháng gần nhất',
  },
  OD_HSTD: {
    title: 'Hồ sơ thực địa',
    docType: 'OD_HSTD', //Báo cáo tài chính năm gần nhất
    maxSize: 15728640,
    fileTyes: 'image/*, application/pdf, .zip',
    // options: ['Đã cung cấp', 'Chưa cung cấp'],
    defaultOption: 1,
    stringType: 'ảnh, pdf hoặc file zip',
    multiple: true,
  },
  LIDP: {
    // options: [1, 2, 5].map(n => <option value={n} key={n}>{OPTION_LABELS[n]}</option>),
    docType: 'LIDP', //Giấy chứng minh nhân thân
    maxSize: 15728640,
    multiple: true,
    fileTyes: 'image/*, application/pdf',
    icon: infoIcon,
    description: 'CMND/Hộ chiếu của thành viên góp vốn là cá nhân',
    options: ['Không có', 'Cung cấp'],
    defaultOption: 0,
    stringType: 'pdf hoặc ảnh',
    note: 'KH chọn cung cấp nếu có các cá nhân góp vốn từ 25% trở lên; trong trường hợp không có thành viên góp từ 25% trở lên thì cung cấp thông tin của người góp vốn cao nhất',
  },
  PCIC: {
    // options: [1, 2, 5].map(n => <option value={n} key={n}>{OPTION_LABELS[n]}</option>),
    docType: 'PCIC', //Giấy chứng minh nhân thân
    maxSize: 15728640,
    multiple: true,
    fileTyes: 'image/*, application/pdf',
    icon: infoIcon,
    description: 'ĐKKD của thành viên góp vốn là Tổ chức',
    options: ['Không có', 'Cung cấp'],
    defaultOption: 0,
    stringType: 'pdf hoặc ảnh',
    note: 'KH chọn cung cấp nếu có các doanh nghiệp góp vốn từ 25% trở lên; trong trường hợp không có thành viên góp từ 25% trở lên thì cung cấp thông tin của tổ chức góp vốn cao nhất',
  },
  PCD: {
    // options: [1, 2, 5].map(n => <option value={n} key={n}>{OPTION_LABELS[n]}</option>),
    docType: 'PCD', //Giấy chứng minh nhân thân
    maxSize: 15728640,
    multiple: true,
    fileTyes: 'image/*, application/pdf',
    icon: infoIcon,
    description: 'Sổ đăng ký cổ đông/ giấy chứng nhận góp vốn',
    options: ['Không có', 'Cung cấp'],
    defaultOption: 0,
    stringType: 'pdf hoặc ảnh',
    title: 'Sổ đăng ký cổ đông/ giấy chứng nhận góp vốn',
    note: 'Chọn không có trong trường hợp thông tin về thành viên góp vốn đã có và được cập nhật trên Giấy chứng nhận đăng ký DN (không áp dụng đối với công ty cổ phần)',
  },
  other_vpb: {
    docType: 'OTHER_VPB',
    title: 'Hồ sơ nội bộ của VPB',
    maxSize: 15728640,
    fileTyes: 'image/*, application/pdf, .zip',
    stringType: 'pdf, ảnh, hoặc zip',
    multiple: true,
  },
};
export const productOptions = ['OD', 'EKYC', 'SMECA', 'AUTO'];

export const LEGAL_OPTIONS = [
  { value: true, label: 'Có tư cách pháp nhân' },
  { value: false, label: 'Không có tư cách pháp nhân' },
];

export const GNHM_CHANGE_INFO = [
  { value: 'OTHER', label: 'Thay đổi khác' },
  { value: 'MEMBER', label: 'Thay đổi thành viên góp vốn' },
  { value: 'INDUSTRY', label: 'Thay đổi ngành nghề kinh doanh chính' },
  { value: 'COMPANY_TYPE', label: 'Thay đổi loại hình doanh nghiệp' },
];

export const GNHM_CURRENCY = [
  { value: 'AUD', label: 'AUD' },
  { value: 'CAD', label: 'CAD' },
  { value: 'CHF', label: 'CHF' },
  { value: 'CNY', label: 'CNY' },
  { value: 'DKK', label: 'DKK' },
  { value: 'EUR', label: 'EUR' },
  { value: 'GBP', label: 'GBP' },
  { value: 'JPY', label: 'JPY' },
  { value: 'NZD', label: 'NZD' },
  { value: 'SEK', label: 'SEK' },
  { value: 'SGD', label: 'SGD' },
  { value: 'THB', label: 'THB' },
  { value: 'TWD', label: 'TWD' },
  { value: 'USD', label: 'USD' },
  { value: 'VND', label: 'VND' },
  { value: 'XAU', label: 'XAU' },
];

export const GNHM_REPAY_DATE = [...Array(31).keys()].map((value, index) => ({
  value: index + 1,
  label: index + 1,
}));

export const GNHM_REPAY_METHOD = [
  { value: 'CLOSING', label: 'Cuối kỳ' },
  { value: 'PERIOD', label: 'Định kỳ' },
];

export const GNHM_PAYMENT_METHOD = {
  DOMESTIC: 'Thanh toán trong nước',
  PAYROLL: 'Thanh toán lương đối với trường hợp đã ký HĐ dịch vụ trả lương qua TK',
  GOV_BUDGET: 'Thanh toán ngân sách nhà nước',
  INTERNATIONAL: 'Thanh toán quốc tế',
};

export const GNHM_PAYMENT_METHOD_OPTIONS = [
  { value: 'DOMESTIC', label: 'Thanh toán trong nước' },
  { value: 'PAYROLL', label: 'Thanh toán lương đối với trường hợp đã ký HĐ dịch vụ trả lương qua TK' },
  { value: 'GOV_BUDGET', label: 'Thanh toán ngân sách nhà nước' },
  { value: 'INTERNATIONAL', label: 'Thanh toán quốc tế' },
];

export const GNHM_DIS_CHECK_1 = [
  { value: '1', label: 'Đạt' },
  { value: '0', label: 'Không đạt' },
];

export const GNHM_DIS_CHECK_2 = [
  { value: 'OK', label: 'Đạt' },
  { value: 'NOK', label: 'Không đạt' },
  { value: 'NONE', label: 'Không phải thực hiện' },
];

export const GNHM_DIS_CHECK_3 = [
  { value: 'OK', label: 'Hoàn thiện' },
  { value: 'NOK', label: 'Chưa hoàn thiện' },
  { value: 'NONE', label: 'Không phải mua bảo hiểm' },
];
export const GNHM_DIS_CHECK_4 = [
  { value: '1', label: 'Phù hợp' },
  { value: '0', label: 'Không phù hợp' },
];
export const GNHM_INTEREST_RATE_MECHANISM = [
  { value: 'FIXED', label: 'Cố định' },
  { value: 'FLEXIBLE', label: 'Linh hoạt' },
];

export const GNHM_APPROVAL_SYSTEM = [
  { value: 'CLOS', label: 'CLOS' },
  { value: 'ALFRESCO', label: 'ALFRESCO' },
  { value: 'SMECONNECT', label: 'SMECONNECT' },
  { value: 'ELOS', label: 'ELOS' },
];

export const GNHM_INTEREST_RATE_PROGRAM = [
  { value: 'OTHER', label: 'Chương trình khác' },
  { value: 'VNDLSNT', label: 'Chương trình VND lãi suất ngoại tệ' },
  { value: 'NORMAL', label: 'Quy định Lãi suất cho vay thông thường' },
];

export const GNHM_APPLICABLE_PRODUCT = [
  { value: 'OTHER', label: 'Chương trình khác' },
  // {value: 'AUTOLOAN', label: 'Autoloan'},
  // {value: 'HTC', label: 'Chuỗi HTC'},
  // {value: 'HTCV', label: 'Chuỗi HTCV'},
  { value: 'LB', label: 'Luồng B' },
  { value: 'LMPT', label: 'Luồng M phân tán' },
  { value: 'LMTT', label: 'Luồng M tập trung' },
  { value: 'CP', label: 'CP' },
  { value: 'BF', label: 'BF' },
  { value: 'ABF', label: 'ABF' },
  { value: 'BIL48', label: 'Bil 48' },
  { value: 'BIL_OVER_ADV', label: 'Bil-Over Advance' },
  { value: 'GROW300', label: 'GROW300' },
  { value: 'MICRO_BIL', label: 'Micro Bil' },
  { value: 'BIL_TOP_UP', label: 'Bil Top up' },
  { value: 'BIL_EWS', label: 'BIL EWS' },
  { value: 'BIL_TC', label: 'Bil tái cấp' },
  { value: 'TTTG', label: 'Tài trợ trọn gói' },
  { value: 'DMT', label: 'Điện mặt trời' },
  { value: 'PDNOT', label: 'Phê duyệt nhanh ô tô' },
  { value: 'CPC_CAR', label: 'CPC Car' },
  { value: 'SME_MICRO_PLUS', label: 'Micro plus online' },
];

export const GNHM_PURPOSE_FORM = 'PurposeForm';

export const GNHM_DISBURSEMENT_METHOD = [
  { value: 'CASH', label: 'Bằng tiền mặt' },
  { value: 'TM_BORROWER', label: 'Chuyển khoản vào Tài khoản thanh toán của khách hàng' },
  { value: 'TM_BENEFICIARY', label: 'Chuyển khoản vào Tài khoản thanh toán của Bên thụ hưởng' },
];

export const GNHM_DISBURSEMENT_METHOD_BENEFICIARY = [{ value: 'TM_BENEFICIARY', label: 'Chuyển khoản vào Tài khoản thanh toán của Bên thụ hưởng' }];

export const GNHM_DISBURSEMENT_NEED_DOMESTIC = [
  { value: 'BEFORE_DELIVERY', label: 'Trước giao hàng', width: '50%' },
  { value: 'AFTER_DELIVERY', label: 'Sau giao hàng', width: '50%' },
  { value: 'SALARY', label: 'Thanh toán lương', width: '50%' },
  { value: 'NO_INVOICE', label: 'Mua hàng không hóa đơn', width: '50%' },
  { value: 'LIFE_INSURANCE', label: 'Thanh toán bảo hiểm nhân thọ', width: '50%' },
  { value: 'INSURANCE', label: 'Thanh toán BH xã hội', width: '50%' },
  { value: 'NON_LIFE_INSURANCE', label: 'Thanh toán bảo hiểm phi nhân thọ/bảo hiểm sức khỏe' },
];

export const GNHM_DISBURSEMENT_NEED_INTERNATIONAL = [
  { value: 'BEFORE_DELIVERY', label: 'Trước giao hàng', width: '50%' },
  { value: 'AFTER_DELIVERY', label: 'Sau giao hàng', width: '50%' },
  { value: 'SALE_TRANS_VPB', label: 'D/A, D/P, LC tại Vpbank', width: '50%' },
  { value: 'SALE_TRANS_OTHER', label: 'D/A, D/P, LC tại ngân hàng khác', width: '50%' },
];

export const ERROR_MESSAGE = {
  required: 'Không được để trống',
  email: 'Định dạng email không đúng',
  minLength: (num) => `Tối thiểu ${num} ký tự`,
  maxLength: (num) => `Tối đa ${num} ký tự`,
  length: (num) => `Phải có ${num} ký tự`,
  max: (num) => `Tối đa ${num}`,
  min: (num) => `Tối thiểu ${num}`,
  inValid: 'Không hợp lệ',
  greaterZezo: 'Số tiền phải lớn hơn 0',
  requiredCheck: (name = 'giá trị') => `Cần chọn một ${name}`,
  maxMoney: (max = MAX_MONEY_INPUT, currency = 'VNĐ') => `Số tiền phải nhỏ hơn hoặc bằng ${formatMoney(max, currency)}`,
  minMoney: (min = -MAX_MONEY_INPUT, currency = 'VNĐ') => `Số tiền phải lớn hơn hoặc bằng ${formatMoney(min, currency)}`,
  fileMin: ({ min }) => `Upload tối thiểu ${min} file`,
};
export const ERROR_MESSAGE2 = {
  required: 'Không được để trống',
  requiredCheck: 'Phải chọn tối thiểu 1 lựa chọn',
  // eslint-disable-next-line no-template-curly-in-string
  minLength: 'Tối thiểu ${min} ký tự',
  // eslint-disable-next-line no-template-curly-in-string
  maxLength: 'Tối đa ${max} ký tự',
  // eslint-disable-next-line no-template-curly-in-string
  length: 'Phải có ${max} ký tự',
  // eslint-disable-next-line no-template-curly-in-string
  max: 'Tối đa ${max}',
  // eslint-disable-next-line no-template-curly-in-string
  min: 'Tối thiểu ${min}',
  inValid: 'Không hợp lệ',
  greaterZezo: 'Số tiền phải lơn hơn 0',
  maxMoney: (max, currency = 'VNĐ') => `Số tiền phải nhỏ hơn ${formatMoney(max, currency)}`,
  minMoney: (min, currency = 'VNĐ') => `Số tiền phải lớn hơn ${formatMoney(min, currency)}`,
  minOrEqualMoney: ({ min }) => `Tối thiểu ${formatMoney(min)} VNĐ`,
  maxOrEqualMoney: ({ max }) => `Tối đa ${formatMoney(max)} VNĐ`,
  maxNumber: ({ max }) => `Tối đa ${formatMoney(max)}`,
};

export const LIMIT_DISBURSEMENT_TYPE = [
  { value: 'MON', label: 'Hạn mức từng lần', item: 'MON' },
  { value: 'HAN MUC', label: 'Hạn mức tuần hoàn', item: 'HAN MUC' },
];

export const LIMIT_DISBURSEMENT_METHOD = {
  'HAN MUC': {
    value: 'HAN MUC',
    label: 'Hạn mức tuần hoàn',
    item: 'HAN MUC',
    code: '0050000',
    subCode: '0058100',
  },
  MON: {
    value: 'MON',
    label: 'Hạn mức từng lần',
    item: 'MON',
    code: '0060000',
    subCode: '0066400',
  },
};

export const INTEREST_RATE_REFERENCE = [
  { value: 'BASE_INTEREST_SME', label: 'Lãi suất cơ sở - SME' },
  { value: 'CAPITAL_SALE', label: 'Lãi suất bán vốn' },
  { value: 'OTHER', label: 'Lãi suất khác' },
];

export const SPECIAL_LOAN_PURPOSE_OPTIONS = [
  { value: 'REAL_ESTATE_DIRECTORY', label: 'Danh mục bất động sản' },
  { value: 'CONSTRUCTION_IN_THE_ESTATE_SECTOR', label: 'Danh mục xây dựng lĩnh vực Bất động sản' },
  { value: 'WATER_TRANSPORT_DIRECTORY', label: 'Danh mục vận tải đường thủy' },
  { value: 'BUSINESS_STOCKS_INVESTMENT', label: 'Vay đầu tư, kinh doanh cổ phiếu' },
];

export const BASE_INTEREST_RATE_TYPE_OPTIONS = [
  { value: 'BASIC_INTEREST', label: 'Lãi suất cơ sở thông thường' },
  { value: 'SPECIAL_INTEREST', label: 'Lãi suất cơ sở đối với mục đích đặc biệt' },
];

export const NHUCAUTHANHTOAN = [
  { value: 'DADP', label: 'Thanh toán D/A, D/P tại VPBank' },
  { value: 'LC', label: 'Thanh toán L/C tại VPBank' },
];
export const RELATED_CUSTOMER_INFO = [
  { value: 0, label: 'Không' },
  { value: 1, label: 'Có' },
];

export const DOCUMENT_BIL_DATA = {
  // Bổ sung tài liệu từ MSA trả về sau thẩm định
  as: {
    docType: 'AS',
    title: 'Báo cáo thẩm định hệ thống:',
    maxSize: 10485760,
    fileTyes: 'application/pdf, .zip',
    stringType: 'pdf, zip',
  },
  am: {
    docType: 'AM',
    // title: 'Báo cáo thẩm định từ Trưởng phòng:',
    maxSize: 15728640,
    fileTyes: 'application/pdf, .zip, .doc, .docx',
    options: ['Không đồng ý báo cáo thẩm định từ hệ thống', 'Đồng ý báo cáo thẩm định từ hệ thống'],
    stringType: 'pdf, zip, word',
    multiple: true,
  },
  dkkd: {
    // options: [1, 2, 5].map(n => <option value={n} key={n}>{OPTION_LABELS[n]}</option>),
    docType: 'BRC', // GIấy chứng nhận đăng ký doanh nghiệp/ DKKD
    title: 'Giấy đăng ký kinh doanh/Giấy chứng nhận đăng ký DN',
    maxSize: 15728640,
    fileTyes: 'application/pdf',
    options: ['Không thay đổi', 'Thay đổi'],
    defaultOption: 0,
    stringType: 'pdf',
    multiple: true,
  },
  gcnmst: {
    // options: [1, 2, 5].map(n => <option value={n} key={n}>{OPTION_LABELS[n]}</option>),
    docType: 'CTR', // Giấy chứng nhận mã số thuế
    title: 'Giấy chứng nhận mã số thuế',
    maxSize: 15728640,
    fileTyes: 'application/pdf',
    options: [
      'Không thay đổi',
      'Thay đổi',
      {
        label: 'Không có',
        note: '(do Mã số thuế được cấp cùng với Giấy chứng nhận đăng ký doanh nghiệp)',
      },
    ],
    defaultOption: 0,
    stringType: 'pdf',
  },
  qdtldn: {
    // options: [1, 2, 5].map(n => <option value={n} key={n}>{OPTION_LABELS[n]}</option>),
    docType: 'BSTD', //Quyết định thành lập doanh nghiệp
    title: 'Quyết định thành lập doanh nghiệp',
    maxSize: 15728640,
    fileTyes: 'application/pdf',
    options: ['Không thay đổi', 'Thay đổi', 'Không có'],
    defaultOption: 0,
    stringType: 'pdf',
  },
  dldn: {
    // options: [1, 2, 5].map(n => <option value={n} key={n}>{OPTION_LABELS[n]}</option>),
    docType: 'CAC', //Điều lệ hoạt động doanh nghiệp
    title: 'Điều lệ doanh nghiệp',
    maxSize: 15728640,
    fileTyes: 'application/pdf',
    options: ['Không thay đổi', 'Thay đổi', 'Không có'],
    defaultOption: 0,
    stringType: 'pdf',
    multiple: true,
  },
  cchn: {
    // options: [1, 2, 5].map(n => <option value={n} key={n}>{OPTION_LABELS[n]}</option>),
    docType: 'BPC', //Hồ sơ đối với ngành nghề kinh doanh có kiều kiện,
    title:
      'Chứng chỉ hành nghề/ Giấy chứng nhận đủ điều kiện kinh doanh/Giấy phép con/ văn bản chứng minh đủ điều kiện kinh doanh khác đối với ngành nghề kinh doanh có điều kiện ',
    multiple: true,
    maxSize: 15728640,
    fileTyes: 'application/pdf',
    // icon: infoIcon,
    // description: 'Bao gồm Chứng chỉ hành nghề/ Giấy chứng nhận đủ điều kiện kinh doanh/Giấy phép con/ văn bản chứng minh đủ điều kiện kinh doanh khác đối với ngành nghề kinh doanh có điều kiện.',
    options: [
      'Không thay đổi',
      'Thay đổi',
      {
        label: 'Không có',
        note: 'Do Khách hàng không có ngành, nghề kinh doanh có điều kiện',
      },
    ],
    defaultOption: 0,
    stringType: 'pdf',
  },
  cmnd: {
    // options: [1, 2, 5].map(n => <option value={n} key={n}>{OPTION_LABELS[n]}</option>),
    docType: 'LID', //Giấy chứng minh nhân thân
    title: 'Chứng minh thư/Hộ chiếu/ CCCD hoặc các giấy tờ tương đương của Đại diện theo pháp luật',
    maxSize: 15728640,
    multiple: true,
    fileTyes: '.jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff, .heif, .heic, application/pdf',
    // icon: infoIcon,
    // description: 'Chứng minh thư/Hộ chiếu/ CCCD hoặc các giấy tờ tương đương của Đại diện theo pháp luật/ Chủ sở hữu Doanh nghiệp tư nhân.',
    options: ['Không thay đổi', 'Thay đổi'],
    defaultOption: 0,
    stringType: 'pdf, png, jpg, jpeg',
  },
  bbhhdtv: {
    // options: [1, 2, 5].map(n => <option value={n} key={n}>{OPTION_LABELS[n]}</option>),
    docType: 'MMMC', //Biên bản họp hội đồng thành viên
    title: 'Biên bản cuộc họp Hội đồng thành viên/ Hội đồng quản trị/Đại Hội đồng cổ đông/Quyết định của chủ sở hữu phê duyệt việc nhận cấp tín dụng từ VPBank',
    maxSize: 15728640,
    fileTyes: 'application/pdf',
    // icon: infoIcon,
    // description: 'Biên bản cuộc họp Hội đồng thành viên/ Hội đồng quản trị/Đại Hội đồng cổ đông/Quyết định của chủ sở hữu phê duyệt việc nhận cấp tín dụng từ VPBank.',
    options: [{ label: 'Không có', note: '(Do không thuộc các trường hợp phải cung cấp)' }, 'Cung cấp mới'],
    defaultOption: 0,
    stringType: 'pdf',
    // note: 'Chọn không có trong trường hợp KH là Công ty TNHH 1 thành viên mà chủ sở hữu đồng thời là người đại diện theo Pháp luật',
    multiple: true,
  },
  dshdtv: {
    // options: [1, 2, 5].map(n => <option value={n} key={n}>{OPTION_LABELS[n]}</option>),
    docType: 'FML', //Danh sách thành viên Hội đồng quản trị hiện hữu
    title: 'Danh sách Thành viên Hội đồng quản trị/ Thành viên Hội đồng thành viên hiện hữu ',
    maxSize: 15728640,
    fileTyes: 'application/pdf',
    // icon: infoIcon,
    // description: 'Danh sách Thành viên Hội đồng quản trị/ Thành viên Hội đồng thành viên hiện hữu. Không áp dụng đối với KH là chủ sở hữu của DNTN, Công ty TNHH 1 thành viên.',
    options: [
      {
        label: 'Không áp dụng',
        note: 'Do KH là chủ sở hữu của Doanh nghiệp tư nhân, Công ty TNHH 1 thành viên',
      },
      'Cung cấp mới',
    ],
    defaultOption: 0,
    stringType: 'pdf',
    multiple: true,
  },
  cic: {
    docType: 'CIC',
    title: 'CIC của KH, thành viên góp vốn chính, nhóm KH được xem như một KH',
    maxSize: 15728640,
    fileTyes: 'application/pdf',
    // options: ['Không thay đổi', 'Cung cấp mới'],
    defaultOption: 1,
    stringType: 'pdf',
    multiple: true,
  },
  shk: {
    // options: [1, 2, 5].map(n => <option value={n} key={n}>{OPTION_LABELS[n]}</option>),
    docType: 'HHB', //Sổ hộ khẩu/sổ tạm trú/xác nhận tạm trú
    title: 'Sổ hộ khẩu/ sổ tạm trú/ xác nhận tạm trú',
    multiple: true,
    maxSize: 15728640,
    fileTyes: '.jpeg, .png, .jpg, application/pdf',
    options: ['Không thay đổi', 'Thay đổi'],
    defaultOption: 0,
    stringType: 'pdf, png, jpg, jpeg',
  },
  dkkh: {
    // options: [1, 2, 5].map(n => <option value={n} key={n}>{OPTION_LABELS[n]}</option>),
    docType: 'CMS', //Đăng ký kết hôn/Xác nhận độc thân
    title: 'Đăng ký kết hôn/ xác nhận độc thân',
    maxSize: 10485760,
    fileTyes: 'application/pdf',
    options: ['Không thay đổi', 'Thay đổi'],
    defaultOption: 0,
    stringType: 'pdf',
  },
  bctct: {
    // options: [1, 2, 5].map(n => <option value={n} key={n}>{OPTION_LABELS[n]}</option>),
    docType: 'RFS', //Báo cáo tài chính năm gần nhất
    title: 'Báo cáo tài chính năm tài chính gần nhất',
    maxSize: 15728640,
    fileTyes: 'application/pdf',
    options: ['Đã cung cấp', 'Chưa cung cấp'],
    stringType: 'pdf',
    defaultOption: 1,
    multiple: true,
  },
  other: {
    docType: 'OTHER',
    title: 'Đăng tải bổ sung tài liệu khác',
    maxSize: 15728640,
    fileTyes: 'application/pdf',
    stringType: 'pdf',
    multiple: true,
  },
  KQTCTT: {
    docType: 'KQTCTT',
    title: 'Kết quả tra cứu thông tin KH, TVGVC, nhóm KH được coi như một KH, người đại diện theo pháp luật theo danh sách do Khối QTRR cung cấp trên SmartSME',
    maxSize: 15728640,
    fileTyes: '.jpeg, .png, .jpg, application/pdf, .zip',
    // options: ['Không thay đổi', 'Cung cấp mới'],
    defaultOption: 1,
    stringType: 'jpeg, jpg, png, pdf, zip',
    multiple: true,
  },
  HDBLCN: {
    docType: 'HDBLCN',
    title: 'Hợp đồng bảo lãnh cá nhân',
    maxSize: 10485760,
    fileTyes: 'application/pdf',
    options: [
      'KH có bảo lãnh cá nhân theo Quy định',
      'KH được phê duyệt ngoại lệ không áp dụng bảo lãnh cá nhân',
      'KH có bảo lãnh cá nhân theo quy định nhưng sẽ thu thập hồ sơ gốc sau',
    ],
    defaultOption: 1,
    stringType: 'pdf',
    multiple: true,
  },
  io12_out: {
    // options: [1, 2, 5].map(n => <option value={n} key={n}>{OPTION_LABELS[n]}</option>),
    docType: 'IO12_OUT', //Báo cáo tài chính năm gần nhất
    maxSize: 15728640,
    fileTyes: 'application/pdf',
    // options: ['Đã cung cấp', 'Chưa cung cấp'],
    defaultOption: 1,
    stringType: 'pdf',
    multiple: true,
    title:
      'Cung cấp tối thiểu 3 Hợp đồng/Hóa đơn mua bán đầu ra với các Khách hàng chính trong vòng 12 tháng gần nhất và các hợp đồng đầu ra lớn dự kiến triển khai nếu có',
  },
  io12_in: {
    // options: [1, 2, 5].map(n => <option value={n} key={n}>{OPTION_LABELS[n]}</option>),
    docType: 'IO12_IN', //Báo cáo tài chính năm gần nhất
    maxSize: 15728640,
    fileTyes: 'application/pdf',
    // options: ['Đã cung cấp', 'Chưa cung cấp'],
    defaultOption: 1,
    stringType: 'pdf',
    multiple: true,
    title: 'Cung cấp tối thiểu 3 Hợp đồng/Hóa đơn đầu vào từ các đối tác chính trong vòng 12 tháng gần nhất',
  },
  DVDR: {
    docType: 'DVDR',
    maxSize: 15728640,
    fileTyes: '.xls, .xlsx, .doc,  .docx',
    defaultOption: 1,
    stringType: ' excel, word',
    multiple: true,
    title: 'Thông tin các đối tác đầu vào, đầu ra chính',
  },
  V6M: {
    docType: 'V6M', //Báo cáo tài chính năm gần nhất
    maxSize: 15728640,
    fileTyes: 'application/pdf',
    // options: ['Đã cung cấp', 'Chưa cung cấp'],
    defaultOption: 1,
    stringType: 'pdf',
    multiple: true,
    title: 'Tờ khai VAT 6 tháng gần nhất/ Tờ khai VAT 12 tháng gần nhất',
  },
  OD_HSTD: {
    title: 'Kiểm tra thực địa',
    docType: 'OD_HSTD', //Báo cáo tài chính năm gần nhất
    maxSize: 15728640,
    fileTyes: '.jpeg, .png, .jpg, application/pdf, .zip',
    // options: ['Đã cung cấp', 'Chưa cung cấp'],
    defaultOption: 1,
    stringType: 'png, jpg, jpeg, pdf hoặc file zip',
    multiple: true,
  },
  LIDP: {
    title: 'CMND/Hộ chiếu của thành viên góp vốn là cá nhân',
    // options: [1, 2, 5].map(n => <option value={n} key={n}>{OPTION_LABELS[n]}</option>),
    docType: 'LIDP', //Giấy chứng minh nhân thân
    maxSize: 15728640,
    multiple: true,
    fileTyes: '.jpeg, .png, .jpg, application/pdf',
    icon: infoIcon,
    description: 'CMND/Hộ chiếu của thành viên góp vốn là cá nhân',
    options: ['Không có', 'Cung cấp mới'],
    defaultOption: 1,
    stringType: 'pdf, png, jpg, jpeg',
  },
  PCIC: {
    title: 'ĐKKD của thành viên góp vốn là Tổ chức',
    // options: [1, 2, 5].map(n => <option value={n} key={n}>{OPTION_LABELS[n]}</option>),
    docType: 'PCIC', //Giấy chứng minh nhân thân
    maxSize: 15728640,
    multiple: true,
    fileTyes: '.jpeg, .png, .jpg, application/pdf',
    icon: infoIcon,
    description: 'ĐKKD của thành viên góp vốn là Tổ chức',
    options: ['Không có', 'Cung cấp mới'],
    defaultOption: 1,
    stringType: 'pdf, png, jpg, jpeg',
  },
  PCD: {
    // options: [1, 2, 5].map(n => <option value={n} key={n}>{OPTION_LABELS[n]}</option>),
    docType: 'PCD', //Giấy chứng minh nhân thân
    maxSize: 15728640,
    multiple: true,
    fileTyes: '.jpeg, .png, .jpg, application/pdf',
    icon: infoIcon,
    description: 'Sổ đăng ký cổ đông/ giấy chứng nhận góp vốn',
    options: ['Không có', 'Cung cấp'],
    defaultOption: 1,
    stringType: 'pdf, png, jpg, jpeg',
    title: 'Sổ đăng ký cổ đông/ giấy chứng nhận góp vốn',
    note: 'Chọn không có trong trường hợp thông tin về thành viên góp vốn đã có và được cập nhật trên Giấy chứng nhận đăng ký DN (không áp dụng đối với công ty cổ phần)',
  },
  other_vpb: {
    docType: 'OTHER_VPB',
    title: 'Hồ sơ nội bộ của VPB',
    maxSize: 15728640,
    fileTyes: '.jpeg, .png, .jpg, application/pdf, .xls, .xlsx, .doc, .docx, .zip',
    stringType: 'pdf, excel, word, jpeg, png, jpg, zip',
    multiple: true,
  },
  EPL: {
    docType: 'EPL',
    title: 'Đánh giá phương án sử dụng vốn khả thi, nhu cầu vốn và khả năng trả nợ',
    maxSize: 15728640,
    fileTyes: 'application/pdf, .xls, .xlsx',
    stringType: 'pdf, excel',
    multiple: true,
    defaultOption: 1,
  },
  hdblcn: {
    docType: 'HDBLCN',
    title: '',
    maxSize: 10485760,
    fileTyes: '.jpeg, .png, .jpg, application/pdf, .msg, .doc, .docx, .zip',
    multiple: true,
    options: [
      {
        label: 'KH có bảo lãnh cá nhân theo Quy định',
      },
      { label: 'KH được phê duyệt ngoại lệ không áp dụng bảo lãnh cá nhân' },
    ],
    defaultOption: 1,
    stringType: 'png, jpg, jpeg, pdf,email hoặc file zip',
  },
  PAD: {
    docType: 'PAD',
    maxSize: 15728640,
    fileTyes: 'application/pdf, .png, .jpeg, .jpg, .zip, .msg',
    stringType: 'pdf, ảnh, file zip hoặc email',
    multiple: true,
    defaultOption: 1,
  },
};

export const OWNERSHIP_STATUS = [
  { value: 'OWNER', label: 'Của khách hàng' },
  { value: 'HIRE', label: 'Đi thuê' },
  { value: 'OTHER', label: 'Khác' },
];

export const BENEFICIARY_TYPE = [
  { label: 'Pháp nhân', value: 'BUSINESS' },
  { label: 'Cá nhân hoặc Hộ kinh doanh', value: 'PERSON' },
];

export const PAYMENT_METHODS = [
  { label: 'Trả ngay', value: 'PAY_ON_TIME' },
  { label: 'Trả gối đầu', value: 'PILLOW' },
  { label: 'Tiền mặt', value: 'CASH' },
  { label: 'Chuyển khoản', value: 'TRANSFER' },
  { label: 'Khác', value: 'OTHER' },
];

export const DISTRIBUTION_METHODS = [
  { label: 'Cung cấp dịch vụ', value: 'SERVICE_PROVIDER' },
  { label: 'Bán lẻ', value: 'RETAIL' },
  { label: 'Bán buôn', value: 'WHOLESALE' },
  { label: 'Khác', value: 'OTHER' },
];

export const CREDIT_CURRENCY = [
  { label: 'VND', value: 0 },
  { label: 'USD', value: 1 },
];

export const CREDIT_PURPOSES = [
  { label: 'Mua sắm TSCĐ', value: 'BUY_ASSET' },
  { label: 'Bổ sung vốn lưu động', value: 'ADD_CAPITAL' },
  // { label: 'Khác', value: 'OTHER' },
];

export const KHDNL_SUBDOMAIN = 'bizconnect';

export const DOCUMENT_TITLE = {
  SME: 'SMEConnect - VPBank',
  GENERAL: 'BizConnect - VPBank',
};

export const VIETNAMESE_CHARACTERS =
  'ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂẾưăạảấầẩẫậắằẳẵặẹẻẽềềểếỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹý';

export const FILE_TYPE = {
  ZIP: '.zip',
  PDF: '.pdf',
  WORD: '.doc, .docx',
  EXCEL: '.xls, .xlsx',
  IMAGE: '.jpeg, .png, .jpg',
};

export const USER_ROLE_CODE = {
  TEAM_LEAD: 'TL',
  SALE: 'SALE',
  CHUYEN_GIA_PHE_DUYET: 'GDTT',
  SSO: 'SSO',
  PRODUCT: 'PRODUCT',
};
