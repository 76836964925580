import message from 'helpers/message';
import ApiClient from 'helpers/ApiClient';
import auth from 'helpers/auth';
import configApp from 'config';
import { handleErrorMessage } from '../views/AlreadyExistDeals/helpers/index';

const client = new ApiClient(configApp.API_URL_MODULE_SAVE_MONEY);

// api customer
export const getCustomers = async (params) => {
  try {
    const response = await client.get('/user/customer-search', { params });
    return response;
  } catch (err) {
    message.error(err.error);
    throw err;
  }
};

export const checkCif = async (cif) => {
  try {
    const response = await client.get(`/user/check-cif/${cif}`);
    return response;
  } catch (err) {
    message.error(err.message);
    throw err;
  }
};

export const getCifInfo = async (params) => {
  try {
    const response = await client.get('/user/customer-info/t24', { params });
    return response;
  } catch (err) {
    message.error(handleErrorMessage(err.message));
    throw err;
  }
};

export const getDetailCustomer = async (id) => {
  try {
    const response = await client.get(`/user/get-customer-detail/${id}`);
    return response?.data;
  } catch (err) {
    message.error(err.message);
    throw err;
  }
};

export const createCustomer = async (data) => {
  try {
    const response = await client.post('/user/create-customer', { data });
    return response;
  } catch (err) {
    message.error(err.message);
    throw err;
  }
};

export const updateCustomer = async (data) => {
  try {
    const response = await client.post('/user/update-customer', { data });
    message.success('Cập nhật thành công!');
    return response;
  } catch (err) {
    message.error(err.message);
    throw err;
  }
};

// api form
export const getForms = async (params) => {
  try {
    const response = await client.get('/contract-form/search', { params });
    return response;
  } catch (err) {
    message.error(err.message);
    throw err;
  }
};

export const exportForms = async (params) => {
  const endpoint = `${configApp.API_URL_MODULE_SAVE_MONEY}/contract-form/deposit/form/export?${new URLSearchParams(params)}`;

  try {
    const response = await fetch(endpoint, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${auth.gettoken()}`,
      },
      params,
    });
    return response;
  } catch (err) {
    message.error(err.message);
  }
};

export const getDetailForm = async (id) => {
  try {
    const response = await client.get(`/contract-form/detail/${id}`);
    return response?.data || null;
  } catch (err) {
    message.error(err.message);
    throw err;
  }
};

export const uploadFile = async (file) => {
  const formData = new FormData();
  formData.append('file', file);
  try {
    const { body } = await client.post('/contract-form/upload-file', {
      data: formData,
      includeHeader: { 'Content-Type': 'multipart/form-data;' },
    });
    return body?.data;
  } catch (err) {
    message.error(err.message);
    throw err;
  }
};

export const downloadFile = async (fileUrl) => {
  try {
    const response = await fetch(fileUrl, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${auth.gettoken()}`,
      },
    });
    return response;
  } catch (err) {
    message.error(err.message);
    throw err;
  }
};

export const createForm = async (data) => {
  try {
    const response = await client.post('/contract-form/create', { data });
    return response;
  } catch (err) {
    message.error(err.message);
    throw err;
  }
};

export const updateForm = async (id, data) => {
  try {
    const response = await client.post(`/contract-form/update/${id}`, {
      data,
    });
    message.success('Cập nhật thành công!');
    return response;
  } catch (err) {
    message.error(err.message);
    throw err;
  }
};

// api params
export const getParams = async (params) => {
  try {
    const response = await client.get('/contract-form/settlement/param', { params });
    return response;
  } catch (err) {
    message.error(err.message);
    throw err;
  }
};

export const getCategories = async (categories) => {
  const query = categories.map((item) => 'category=' + item).join('&');
  try {
    const response = await client.get(`/book-deal/category?${query}`);
    return response;
  } catch (err) {
    message.error(handleErrorMessage(err));
    throw err;
  }
};

// api deal
export const getContracts = async (params) => {
  try {
    const response = await client.get('/book-deal/deposit/contract/search', {
      params,
    });
    return response;
  } catch (err) {
    message.error(handleErrorMessage(err));
    throw err;
  }
};

export const getLockContracts = async (params) => {
  try {
    const response = await client.get('/book-deal/account/search', {
      params,
    });
    return response;
  } catch (err) {
    message.error(handleErrorMessage(err));
    throw err;
  }
};

export const exportDeals = async (params) => {
  const endpoint = `${configApp.API_URL_MODULE_SAVE_MONEY}/book-deal/deposit/contract/export?${new URLSearchParams(params)}`;

  try {
    const response = await fetch(endpoint, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${auth.gettoken()}`,
      },
      params,
    });
    return response;
  } catch (err) {
    message.error(err.message);
    throw err;
  }
};
export const exportLockAccount = async (params) => {
  const endpoint = `${configApp.API_URL_MODULE_SAVE_MONEY}/book-deal/account/export?${new URLSearchParams(params)}`;

  try {
    const response = await fetch(endpoint, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${auth.gettoken()}`,
      },
      params,
    });
    return response;
  } catch (err) {
    message.error(err.message);
    throw err;
  }
};

export const approveDeals = async (data) => {
  try {
    const response = await client.post('/book-deal/deposit/contract/manager/approve', { data });
    return response;
  } catch (err) {
    message.error(err.message);
    throw err;
  }
};

export const returnDeals = async (data) => {
  try {
    const response = await client.post('/book-deal/deposit/contract/manager/return', { data });
    return response;
  } catch (err) {
    message.error(err.message);
    throw err;
  }
};

export const generateFile = async (contractId) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await fetch(configApp.API_URL_MODULE_SAVE_MONEY + `/book-deal/contract/download-file/${contractId}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${auth.gettoken()}`,
      },
    });
    return response;
  } catch (err) {
    throw err;
  }
};

export const uploadContractDocuments = async (id, data) => {
  try {
    const response = await client.post(`/book-deal/deposit/contract/document/${id}`, { data });
    return response?.data || [];
  } catch (err) {
    message.error(err.message);
    throw err;
  }
};

export const uploadDealDocuments = async (id, data) => {
  try {
    const response = await client.post(`/book-deal/deposit/contract/document/deal/${id}`, { data });
    return response?.data || [];
  } catch (err) {
    message.error(err.message);
    throw err;
  }
};

export const downloadDocuments = async (fileName) => {
  try {
    const response = await fetch(configApp.API_URL_MODULE_SAVE_MONEY + '/book-deal/download/' + fileName, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${auth.gettoken()}`,
      },
    });
    return response;
  } catch (err) {
    message.error(err.message);
    throw err;
  }
};

export const deleteDocuments = async (params) => {
  try {
    const response = await client.del('/book-deal/contract/document', {
      params,
    });
    return response;
  } catch (err) {
    message.error(err.message);
    throw err;
  }
};

export const exportParams = async (params) => {
  const endpoint = `${configApp.API_URL_MODULE_SAVE_MONEY}/contract-form/settlement/deposit/form/param/export?${new URLSearchParams(params)}`;

  try {
    const response = await fetch(endpoint, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${auth.gettoken()}`,
      },
      params,
    });
    return response;
  } catch (err) {
    message.error(err.message);
    throw err;
  }
};
