import { initDataTemplate } from './constants';
import moment from 'moment';
import React from 'react';

export const isNullOrUndefined = (val) => typeof val === 'undefined' || val === null;

export const initBilData = (applicant) => {
  const initData = { ...applicant };
  initData.headquarterWardId = applicant.headquarterWard?.wardId;
  initData.headquarterDistrictId = applicant.headquarterDistrict?.districtId;
  initData.headquarterProvinceId = applicant.headquarterProvince?.provinceId;
  initData.permanentWardId = applicant.permanentWard?.wardId;
  initData.permanentDistrictId = applicant.permanentDistrict?.districtId;
  initData.permanentProvinceId = applicant.permanentProvince?.provinceId;
  initData.legalPermanentWardId = applicant.legalPermanentWard?.wardId;
  initData.legalPermanentDistrictId = applicant.legalPermanentDistrict?.districtId;
  initData.legalPermanentProvinceId = applicant.legalPermanentProvince?.provinceId;
  initData.legalCurrentWardId = applicant.legalCurrentWard?.wardId;
  initData.legalCurrentDistrictId = applicant.legalCurrentDistrict?.districtId;
  initData.legalCurrentProvinceId = applicant.legalCurrentProvince?.provinceId;
  initData.sameHeadquarterAddress = applicant?.sameHeadquarterAddress === false ? '0' : '1';
  initData.sameCurrentAddress = applicant?.sameCurrentAddress === false ? '0' : '1';
  initData.legalSamePermanentAddress = applicant?.legalSamePermanentAddress === false ? '0' : '1';
  if (applicant?.hasForeignExchangeNeed === 0) {
    initData.hasForeignExchangeNeed = '0';
  }
  if (applicant?.hasForeignExchangeNeed === 1) {
    initData.hasForeignExchangeNeed = '1';
  }
  initData.useOfCapital = applicant?.useOfCapital === false ? '0' : '1';
  initData.okProductException = null;
  if(applicant?.okProductException === false){
    initData.okProductException = '0';
  }
  if(applicant?.okProductException === true){
    initData.okProductException ='1';
  }
  initData.industryId = initData?.industry?.industryId;
  initData.industryTypeId = initData?.industry?.industryType?.identityKey;
  initData.totalSaleProposalCreditLimit = initData.totalSaleProposalCreditLimit || initData.totalCustomerProposalCreditLimit;
  delete initData.headquarterWard;
  delete initData.headquarterDistrict;
  delete initData.headquarterProvince;
  delete initData.permanentWard;
  delete initData.permanentDistrict;
  delete initData.permanentProvince;
  delete initData.legalPermanentWard;
  delete initData.legalPermanentDistrict;
  delete initData.legalPermanentProvince;
  delete initData.legalCurrentWard;
  delete initData.legalCurrentDistrict;
  delete initData.legalCurrentProvince;
  // initData.legalPositionDetail = '';
  Object.keys(initDataTemplate).forEach((key) => {
    if (isNullOrUndefined(initData[key])) {
      initData[key] = initDataTemplate[key];
    }
  });
  return initData;
};

export const segment = [
  { value: 'MICRO1', label: 'Micro 1' },
  { value: 'MICRO2', label: 'Micro 2' },
  { value: 'MICRO3', label: 'Micro 3' },
  { value: 'SMALL', label: 'Small' },
  { value: 'SMALL1', label: 'Small1' },
  { value: 'SMALL2', label: 'Small2' },
  { value: 'MIDDLE', label: 'Middle' },
  { value: 'UPPER', label: 'Upper' },
  { value: 'CIB', label: 'Cib' },
  { value: 'CMB', label: 'Cmb' },
];

export const modifyBody = (data) => {
  try {
    const toReturn = JSON.parse(JSON.stringify(data));
    Object.keys(toReturn).forEach((key) => {
      if (toReturn[key] === '') {
        toReturn[key] = null;
      }
    });
    // delete toReturn.lcTypes;
    // delete toReturn.gnTypes;
    return toReturn;
  } catch (err) {
    return data;
  }
};

export const formatCreatedDateDoc = (date) => {
  return date ? moment(date).format('DD/MM/YYYY HH:mm:ss') : '';
};

export const getApplicantFieldsChanged = (touched = {}, initialValues = {}, values = {}) => {
  const fieldsTouched = Object.keys(touched).filter((field) => touched[field] === true);

  const fieldsChanged = [];
  for(let i = 0; i < fieldsTouched.length; i++) {
    const field = fieldsTouched[i];
    const oldValue = initialValues[field] === null ? '' : initialValues[field];
    const newValue = values[field] === null ? '' : values[field];

    if (typeof newValue === 'object' || Array.isArray(newValue)) {
      fieldsChanged.push(field);
    } else if (String(oldValue) !== String(newValue)) {
      fieldsChanged.push(field);
    }
  }

  return fieldsChanged;
};

export const checkFormError = (errors, setFieldTouched = {}) => {
  let isValid = true;
  if (Object.keys(errors).length > 0) {
    Object.keys(errors).forEach((key) => {
      if (
        (!errors[key].type || errors[key].type !== 'required')
        // && touched[key]
        && key !== 'relatedOrganizationList'
        && key !== 'saleConfirmed'
        && errors[key] !== 'Không được để trống'
      ) {
        setFieldTouched(key, true, false);
        isValid = false;
      }
    });
  }
  return isValid;
};

export const getError = (errors, touched) => {
  const toReturn = {};
  Object.keys(errors).forEach((item) => {
    if (errors[item] && touched[item] || errors[item]) {
      toReturn[item] = errors[item];
    }
  });
  return toReturn;
};

export const getWarningMSA = (approvalHistory) => {
  const listText = [];
  if (approvalHistory?.otherWarning === 'VIOLATED') {
    listText.push('Hồ sơ vi phạm điều kiện sản phẩm, cần kiểm tra lại hồ sơ trước khi phê duyệt');
  }
  if (approvalHistory?.badDebtWarning === 'VIOLATED') {
    listText.push('Hồ sơ vi phạm điều kiện CIC, cần kiểm tra lại hồ sơ trước khi phê duyệt.');
  }
  if (approvalHistory?.warningWarning === 'VIOLATED') {
    listText.push('Hồ sơ vi phạm điều kiện danh sách rủi ro, cần kiểm tra lại hồ sơ trước khi phê duyệt.');
  }
  if (approvalHistory?.otherWarning === 'NO_RESULT') {
    listText.push('Hồ sơ chưa đầy đủ thông tin theo điều kiện sản phẩm, cần kiểm tra lại hồ sơ trước khi phê duyệt');
  }
  if (approvalHistory?.badDebtWarning === 'NO_RESULT') {
    listText.push('Hồ sơ chưa có thông tin kiểm tra điều kiện CIC, cần kiểm tra lại hồ sơ trước khi phê duyệt.');
  }
  if (approvalHistory?.warningWarning === 'NO_RESULT') {
    listText.push('Hồ sơ chưa có thông tin kiểm tra điều kiện danh sách rủi ro, cần kiểm tra lại hồ sơ trước khi phê duyệt.');
  }
  if (listText.length) {
    return listText.map((text) => <li key={text}>{text}</li>);
  }
  return null;
};
