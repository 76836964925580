import SingleDealConstants from './index';

const SingleDealTypes = {
  Account(initValue = {}) {
    return {
      benNumber: '',
      benName: '',
      branch: null,
      otherBranchName: '',
      bank: SingleDealConstants.VP_BANK,
      type: null,
      ...initValue,
    };
  },
};

export default SingleDealTypes;
