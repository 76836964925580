import SingleDealServices from '../../SingleDeal/services/index';
import { ERR_SYS_STATUS_CODE, STATUS_CAN_TRANFER, STATUS_CODE_ERROR_DES, STATUS_CODE_LIST } from '../constants/common';

/**
 *
 * @param {object} object
 * @param {any} value
 * @param {string} keySearch
 * @returns Object
 */
export const getObjByKey = (object, value, keySearch = 'order') => {
  let key = Object.keys(object).find((key) => object[key][`${keySearch}`] === value);
  return key ? object[key] : null;
};

/**
 *
 * @param {array} array
 * @param {any} value
 * @param {string} key
 * @returns Element in Array
 */
export const findElByKey = (array = [], value, key = 'key') => {
  return array && array.length ? array.find((e) => e[key] == value) : null;
};

/**
 *
 * @param {fileArray} files
 * @param {array} accepts
 * @returns boolean
 */
export const validateFileExtension = (files, accepts) => {
  let isValid = true;
  for (let i = 0; i < files.length; i++) {
    const fileExtension = files[i].name.split('.').pop().toString().toLowerCase();
    if (!accepts.includes(fileExtension)) {
      isValid = false;
      break;
    }
  }
  return isValid;
};

/**
 *
 * @param {object} obj
 * @returns Object deleted key has value null
 */
export const removeEmptyKey = (obj) => {
  return Object.entries(obj)
    .filter(([_, v]) => v != null && v != '' && v != undefined)
    .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
};

/**
 *
 * @param {array} array : Contract Info Array
 * @param {any} value : null, ORIGINAL_ACCOUNT, INTEREST_ACCOUNT
 * @param {string} key
 * @returns Contract Info Object in array
 */
export const getAccountInContractInfo = (array, value, key = 'type', customerVnName) => {
  let findAccount = array && array.length ? array.find((item) => item[key] == value) : null;
  return findAccount
    ? {
        ...findAccount,
        type: value,
        bankId: findAccount?.bankId?.toString() ? parseFloat(findAccount?.bankId) : null,
        bank: findAccount?.bankId ? parseFloat(findAccount?.bankId) : null,
        bankName: findAccount?.bankName,
        // benName: customerVnName ? customerVnName : findAccount?.benName,
        benNumber: findAccount?.benNumber,
        branch: findAccount?.branchId,
        branchId: findAccount?.branchId ? parseFloat(findAccount?.branchId) : null,
        citadCode: findAccount?.citadCode,
      }
    : {
        type: value,
        bankId: '',
        // benName: customerVnName ? customerVnName : '',
        benNumber: '',
        branchId: '',
      };
};

/**
 * Convert contractAccountsInfo Obj to contractAccountsInfo array
 * @param {object} contractAccountsInfo
 * @param {string} principalSameInterest
 * @param {string} interestPaymentMethod
 * @returns contractAccountsInfo array
 */
export const convertContractInfoData = (contractAccountsInfo, principalSameInterest, interestPaymentMethod) => {
  let array = contractAccountsInfo ? Object.entries(contractAccountsInfo).map(([name, obj]) => (obj ? { ...obj } : null)) : [];
  let filterArr = [];

  if (principalSameInterest == '0') {
    filterArr = array?.filter((e) => e?.type);
  }
  if (principalSameInterest == '0' && interestPaymentMethod === 'TIEN_GUI_CKH_TRA_TRUOC') {
    filterArr = array?.filter((e) => e?.type === 'ORIGINAL_ACCOUNT');
  }
  if (principalSameInterest == '1') {
    filterArr = array?.filter((e) => !e?.type);
  }

  return filterArr;
};

/**
 *
 * @param {object} values dynamic values
 * @param {object} initialValues
 * @returns boolean
 */
export const checkNotChangedValues = (values, initialValues) => {
  let obj = Object.entries(values).reduce((acc, [key, value]) => {
    const hasChanged = initialValues[key] != value;

    if (hasChanged) {
      acc[key] = value;
    }

    return acc;
  }, {});

  return JSON.stringify(obj) === '{}';
};

/**
 *
 * @param {object} formValue
 * @returns boolean
 */
export const checkWarningAmount = (formValue) => {
  let warning = false;
  /**
   * Trường hợp 1: Sale lựa chọn tất toán và chuyển tiền và mở mới: (Chuyển tiền chắc chắn chuyển 1 phần)
   * 1. Tài khoản gốc và lãi trùng nhau:
   * Số tiền mở mới (newOpenAmount) + Số tiền chuyển (transferAmount) < số tiền rút gốc 1 phần (withdrawalAmount)
   * 2. Nếu tài khoản gốc và lãi khác nhau:
   * Số tiền mở mới + số tiền gốc + Số tiền chuyển lãi< = số tiền rút gốc 1 phần
   */

  if (formValue?.processType == 'CHUYEN_TIEN_VA_MO_MOI' && formValue.transferType == 'MOT_PHAN') {
    // case 1.1
    if (formValue.principalSameInterest == 1) {
      //
      warning = parseFloat(formValue.newAmount) + parseFloat(formValue.amountOrigin) > parseFloat(formValue.amount);
    }
    // case 1.2
    if (formValue.principalSameInterest == 0 || !formValue.principalSameInterest) {
      //
      warning =
        parseFloat(formValue.newAmount) + parseFloat(formValue.amountOrigin || '0') + parseFloat(formValue.amountInterest || '0') >
        parseFloat(formValue.amount);
    }
  }

  /**
   *  Trường hợp 2: Sale lựa chọn tất toán  và mở mới
   *  Số tiền mở mới cho sale nhập >=số tiền gốc
   */
  if (formValue?.processType == 'MO_MOI') {
    warning = parseFloat(formValue.newAmount) > parseFloat(formValue.amount);
  }

  /**
   * Trường hợp 3: Sale lựa chọn tất toán  và chuyển tiền:
   * 1. Nếu chuyển toàn bộ  => k hiển thị trường số tiền
   * 2. Nếu chuyển 1 phần
   * 2.1. Tài khoản gốc và lãi trùng nhau:
   *  Số tiền chuyển (transferAmount) < số tiền rút gốc 1 phần (withdrawalAmount)
   * 2.2. Nếu tài khoản gốc và lãi khác nhau:
   *  số tiền gốc + Số tiền chuyển lãi< = số tiền rút gốc 1 phần
   */

  if ((formValue?.processType == 'CHUYEN_TIEN' || formValue?.actionType == 'CHUYEN_TIEN') && formValue.transferType == 'MOT_PHAN') {
    // case 3.2.1
    if (formValue.principalSameInterest == 1) {
      //
      warning = parseFloat(formValue.amountOrigin) > parseFloat(formValue.amount);
    }
    // case 3.2.2
    if (formValue.principalSameInterest == 0 || !formValue.principalSameInterest) {
      //
      warning = parseFloat(formValue.amountOrigin || '0') + parseFloat(formValue.amountInterest || '0') > parseFloat(formValue.amount);
    }
  }

  return warning;
};

/**
 *
 * @param {*} err
 * @returns message string
 */
export const handleErrorMessage = (err) => {
  let message = '';
  if (STATUS_CODE_LIST.includes(Number(err?.statusCode))) {
    message = STATUS_CODE_ERROR_DES[err?.statusCode];
  } else if (err?.statusCode == 500 && err?.message === 'Access is denied') {
    message = 'Bạn không có quyền sử dụng chức năng này .';
  } else if (ERR_SYS_STATUS_CODE.includes(err?.statusCode) || err?.status == 'INTERNAL_SERVER_ERROR') {
    message = 'Hệ thống đang tạm thời gián đoạn, xin vui lòng thử lại';
  } else {
    message = err?.message;
  }

  return message;
};

/**
 *
 * @param {string} status: deal Status
 * @param {Boolean} canEdit
 * @returns Boolean
 */
export const isViewMode = (status, canEdit) => {
  return (status && !STATUS_CAN_TRANFER.includes(status)) || !canEdit;
};

export const returnLabelFromCate = (cate, key) => {
  return cate.find((e) => e.key === key)?.label;
};

export const compareArray = (arr1, arr2) => {
  if (arr1?.length == 0 || arr2?.length == 0) {
    return;
  }
  return arr1.sort().toString() === arr2.sort().toString();
};

export const checkCurrency = (currency) => {
  const currencyProps = SingleDealServices.getCurrencyProps(currency);
  return currencyProps;
};

export const disableProcessType = (contractAccountsInfo, principalSameInterest, contract) => {
  if (
    (contract?.categoryCode == '21012' && contractAccountsInfo[1].bankId == '0' && contractAccountsInfo[1].type == 'ORIGINAL_ACCOUNT') ||
    (principalSameInterest == '1' && contractAccountsInfo[0].bankId == '0' && contractAccountsInfo[0].type == null)
  ) {
    return false;
  }
  return true;
};

export const checkStatusToHighLightSaveMoney = (listStatus) => {};
