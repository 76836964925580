import {combineReducers} from 'redux';
import {reducer as formReducer} from 'redux-form';
import userReducer from './user';
import settingReducer from './setting';
import odReducer from './odReducers';
import gnhmReducer from './gnhmReducer';
import bilReducer from './bilReducer';
import ccReducer from './ccReducer';
import SaveMoneyReducer from './saveMoneyReducer';
import commonReducer from './commonReducer';

const makeRootReducer = asyncReducers => {
    return combineReducers({
        form: formReducer,
        setting: settingReducer,
        user: userReducer,
        odReducer: odReducer,
        gnhmReducer: gnhmReducer,
        bilReducer: bilReducer,
        ccReducer: ccReducer,
        saveMoneyReducer: SaveMoneyReducer,
        commonReducer: commonReducer,
        ...asyncReducers,
    });
};

export default makeRootReducer;
