import { getCategories } from 'Modules/SaveMoney/services/index';
import { findElByKey } from 'Modules/SaveMoney/views/AlreadyExistDeals/helpers/index';
import { STATUS_OPTIONS } from 'Modules/SaveMoney/views/ManageListForm/constants/common';
import message from 'helpers/message';

const ALL_DATA_CATEGORY = 'ALL_DATA_CATEGORY';
const FORM_TYPE = 'FORM_TYPE';
const SETTLEMENT_WITHDRAW_TYPE = 'SETTLEMENT_WITHDRAW_TYPE';
const EDIT_TYPE = 'EDIT_TYPE';
const PAYMENT_INSTRUCTIONS = 'PAYMENT_INSTRUCTIONS';
const PAYMENT_INSTRUCTIONS_SEARCH = 'PAYMENT_INSTRUCTIONS_SEARCH';
const PARAM_PRODUCT = 'PARAM_PRODUCT';
const PHUONG_THUC_TRA_LAI_SAN_PHAM_TIEN_GUI = 'PHUONG_THUC_TRA_LAI_SAN_PHAM_TIEN_GUI_T24';
const THANH_TOAN_GOC_VA_THOA_THUAN_KEO_DAI = 'THANH_TOAN_GOC_VA_THOA_THUAN_KEO_DAI';
const SEARCH_FILNAL_SETT_FORM = 'SEARCH_FILNAL_SETT_FORM';
const TRANSFER_TYPE = 'TRANSFER_TYPE';
const ACTION_TYPE = 'ACTION_TYPE';
const DETAIL_DEAL = 'DETAIL_DEAL';
const PROCESS_TYPE = 'PROCESS_TYPE';
const DEPOSIT_TERM_T24 = 'DEPOSIT_TERM_T24';
const BOF_STATUS = 'BOF_STATUS';
const ACCOUNT_ACTION_TYPE = 'ACCOUNT_ACTION_TYPE';
const BOOK_ACCOUNT_LOCK_UNLOCK_DISABLED = 'BOOK_ACCOUNT_LOCK_UNLOCK_DISABLED';

const initData = {
  allDataCategory: [],
  fromType: [],
  settlementWithdrawType: [],
  editType: [],
  paymentInstructions: [],
  paymentInstructionsSearch: [],
  paramProduct: [],
  interestPaymentMethod: [],
  rolloverMethod: [],
  t24Settment: null,
  t24Roll: null,
  t24OriginRoll: null,
  transferType: [],
  processType: [],
  actionType: [],
  status: STATUS_OPTIONS,
  searchFinalSettForm: null,
  detailDeal: null,
  bofStatus: null,
  accountActionType: [],
};

export const _getCategoryAll = () => async (dispatch) => {
  const list = [
    FORM_TYPE,
    SETTLEMENT_WITHDRAW_TYPE,
    EDIT_TYPE,
    PAYMENT_INSTRUCTIONS,
    PARAM_PRODUCT,
    PHUONG_THUC_TRA_LAI_SAN_PHAM_TIEN_GUI,
    THANH_TOAN_GOC_VA_THOA_THUAN_KEO_DAI,
    TRANSFER_TYPE,
    ACTION_TYPE,
    PROCESS_TYPE,
    DEPOSIT_TERM_T24,
    BOF_STATUS,
    ACCOUNT_ACTION_TYPE,
    BOOK_ACCOUNT_LOCK_UNLOCK_DISABLED,
  ];
  return getCategories(list).then((res) => {
    dispatch({ type: 'ALL_DATA_CATEGORY', payload: res.data });

    if (res.data !== null) {
      let processCateData = res.data?.map((e) => ({ ...e, value: e.key }));
      sortData(processCateData, list, dispatch);
      dispatch({ type: 'ALL_DATA_CATEGORY', payload: res.data });
    } else {
      message.error(res?.message);
    }
  });
};

export const setSearchFinalSettForm = (data) => {
  return { type: SEARCH_FILNAL_SETT_FORM, payload: data };
};

export const saveDetailDeal = (data) => {
  return { type: DETAIL_DEAL, payload: data };
};

const sortData = (data, listKey, dispatch) => {
  listKey.map((key) => {
    const filterData = data.filter((fn) => fn.category === key);

    if (key === PAYMENT_INSTRUCTIONS) {
      const searchData = [...filterData];
      searchData.unshift({ value: ' ', label: 'Tất cả' });
      dispatch({ type: PAYMENT_INSTRUCTIONS_SEARCH, payload: searchData });
    }
    dispatch({ type: key, payload: filterData });
  });
};

const SaveMoneyReducer = (state = initData, { type, payload }) => {
  switch (type) {
    case ALL_DATA_CATEGORY:
      return {
        ...state,
        allDataCategory: payload,
      };
    case FORM_TYPE:
      return {
        ...state,
        fromType: payload,
      };
    case SETTLEMENT_WITHDRAW_TYPE:
      return {
        ...state,
        settlementWithdrawType: payload,
      };
    case EDIT_TYPE:
      return {
        ...state,
        editType: payload,
      };
    case PAYMENT_INSTRUCTIONS:
      return {
        ...state,
        paymentInstructions: payload,
      };
    case PAYMENT_INSTRUCTIONS_SEARCH:
      return {
        ...state,
        paymentInstructionsSearch: payload,
      };
    case PARAM_PRODUCT:
      return {
        ...state,
        paramProduct: payload,
      };
    case PHUONG_THUC_TRA_LAI_SAN_PHAM_TIEN_GUI:
      return {
        ...state,
        interestPaymentMethod: payload,
      };
    case THANH_TOAN_GOC_VA_THOA_THUAN_KEO_DAI:
      return {
        ...state,
        rolloverMethod: payload,
      };
    case DEPOSIT_TERM_T24:
      return {
        ...state,
        t24OriginRoll: findElByKey(payload, 'Tai tuc goc'),
        t24Roll: findElByKey(payload, 'Tai tuc'),
        t24Settment: findElByKey(payload, 'Tat toan'),
      };
    case TRANSFER_TYPE:
      return {
        ...state,
        transferType: payload,
      };
    case PROCESS_TYPE:
      return {
        ...state,
        processType: payload,
      };
    case ACTION_TYPE:
      return {
        ...state,
        actionType: payload,
      };
    case SEARCH_FILNAL_SETT_FORM:
      return {
        ...state,
        searchFinalSettForm: payload,
      };
    case DETAIL_DEAL:
      return {
        ...state,
        detailDeal: payload,
      };
    case BOF_STATUS:
      return {
        ...state,
        bofStatus: payload[0],
      };
    case ACCOUNT_ACTION_TYPE:
      return {
        ...state,
        accountActionType: payload,
      };
    default:
      return state;
  }
};

export default SaveMoneyReducer;
