import { getAllProvinces, getExpiredInfo } from '../services';

const PROVINCE_OPTIONS = 'PROVINCE_OPTIONS';
const SET_STATUS = 'SET_STATUS';
const INITIALIZE_DATA = 'INITIALIZE_DATA';
const SELECT_EDIT_TAB = 'SELECT_EDIT_TAB';
const SET_APPLICANT_GNHM = 'SET_APPLICANT_GNHM';
const SET_TYPE_SUBMIT = 'SET_TYPE_SUBMIT';
const SHOW_PURPOSE_DETAIL = 'SHOW_PURPOSE_DETAIL';
const HIDE_PURPOSE_DETAIL = 'HIDE_PURPOSE_DETAIL';
const SET_LIST_ACCOUNT = 'SET_LIST_ACCOUNT';
const SET_EXPIRED_INFO = 'SET_EXPIRED_INFO';

const initStatue = {
  provinceOptions: [],
  status: '',
  initializeData: {},
  selectedEditTab: '1',
  listAccount:[],
  typeSubmit: 'approve',
  purposeForm: {
    disbursementPurpose: null,
    disbursementMethod: [],
    beneficiaryList: [],
  },
  expiredInfo: {},
};
export const _getAllProvinces = () => (dispatch) => {
  return getAllProvinces().then((data) => {
    if (!data.errorCgnhme) {
      data.sort((a, b) => a.description.localeCompare(b.description));
      dispatch({ type: PROVINCE_OPTIONS, payload: data });
    }
    return data;
  });
};

export const getAsyncExpiredInfo = (data) => (dispatch) => {
  return getExpiredInfo(data).then((response) => {
  });
};

export const setListAccount = (payload)=>{
  return { type: SET_LIST_ACCOUNT, payload: payload };
};
export const changeSelectedEditTab = (tab) => {
  return { type: SELECT_EDIT_TAB, payload: tab };
};
export const setStatusGNHM = (data) => {
  return { type: SET_STATUS, payload: data };
};
export const initializeData = (data) => {
  return { type: INITIALIZE_DATA, payload: data };
};
export const setTypeSubmit = (data) => {
  return { type: SET_TYPE_SUBMIT, payload: data };
};
export const setExpiredInfo = (data) => ({
  type: SET_EXPIRED_INFO,
  payload: data,
});
export const showPurposeDetail = ({ name, beneficiaryList }) => {
  let disbursementMethod = [];

  if (beneficiaryList) {
    beneficiaryList.forEach((ben) => {
      disbursementMethod.push(ben.disbursementMethod);
    });
  }

  return {
    type: SHOW_PURPOSE_DETAIL,
    payload: {
      name,
      disbursementMethod: [...new Set(disbursementMethod)],
      beneficiaryList,
    },
  };
};
export const hidePurposeDetail = (data) => {
  return { type: HIDE_PURPOSE_DETAIL, payload: data };
};
export const setApplicantDocsGnhm = (data,listDocs) => {
  if (!listDocs) {
    return { type: SET_APPLICANT_GNHM, payload: data };
  }
  const applicant = { ...data };
  applicant.applicantDocumentList = listDocs;
  return { type: SET_APPLICANT_GNHM, payload: applicant };
};

export const setApplicantGnhm = (data) => {
  if (!data) {
    return { type: SET_APPLICANT_GNHM, payload: data };
  }
  const applicant = { ...data };
  applicant.headquarterWardId = applicant.headquarterWard?.wardId;
  applicant.headquarterDistrictId = applicant.headquarterDistrict?.districtId;
  applicant.headquarterProvinceId = applicant.headquarterProvince?.provinceId;
  applicant.permanentWardId = applicant.permanentWard?.wardId;
  applicant.permanentDistrictId = applicant.permanentDistrict?.districtId;
  applicant.permanentProvinceId = applicant.permanentProvince?.provinceId;
  applicant.legalPermanentWardId = applicant.legalPermanentWard?.wardId;
  applicant.legalPermanentDistrictId = applicant.legalPermanentDistrict?.districtId;
  applicant.legalPermanentProvinceId = applicant.legalPermanentProvince?.provinceId;
  applicant.legalCurrentWardId = applicant.legalCurrentWard?.wardId;
  applicant.legalCurrentDistrictId = applicant.legalCurrentDistrict?.districtId;
  applicant.legalCurrentProvinceId = applicant.legalCurrentProvince?.provinceId;
  applicant.sameHeadquarterAddress = applicant?.sameHeadquarterAddress === false ? '0' : '1';
  applicant.sameCurrentAddress = applicant?.sameCurrentAddress === false ? '0' : '1';
  applicant.legalSamePermanentAddress = applicant?.legalSamePermanentAddress === false ? '0' : '1';
  Object.keys(applicant).forEach((key) => {
    if (applicant[key] === null) {
      delete applicant[key];
    }
  });
  return { type: SET_APPLICANT_GNHM, payload: applicant };
};
const gnhmReducer = (state = initStatue, { type, payload }) => {
  switch (type) {
    case SELECT_EDIT_TAB:
      return {
        ...state,
        selectedEditTab: payload,
      };
    case PROVINCE_OPTIONS:
      return {
        ...state,
        provinceOptions: payload,
      };
    case SET_STATUS:
      return {
        ...state,
        status: payload,
      };
    case INITIALIZE_DATA:
      return {
        ...state,
        initializeData: payload,
      };
    case SET_APPLICANT_GNHM:
      return {
        ...state,
        applicantGnhm: payload,
      };
    case SHOW_PURPOSE_DETAIL:
      return {
        ...state,
        purposeForm: {
          disbursementPurpose: payload.name,
          disbursementMethod: payload.disbursementMethod,
          beneficiaryList: payload.beneficiaryList,
        },
        isPurposeShowed: true,
      };
    case HIDE_PURPOSE_DETAIL:
      return {
        ...state,
        purposeForm: {
          disbursementPurpose: null,
          disbursementMethod: [],
          beneficiaryList: [],
        },
        isPurposeShowed: false,
      };
    case SET_LIST_ACCOUNT:
      return {
        ...state,
        listAccount: payload,
      };
    case SET_EXPIRED_INFO:
      return {
        ...state,
        expiredInfo: payload,
      };
    default:
      return state;
  }
};
export default gnhmReducer;
